import React from "react";
import SuperAdminBarChart from "../../../charts/SuperAdminBarChart";
import { Group, Paper, Title, Text } from "@mantine/core";

const SuperAdminWeeklyStatus = () => {
  return (
    <Paper
      withBorder
      p="md"
      shadow="md"
      className=" bg-white flex-1 hidden md:block "
    >
      <Title
        mb={4}
        className="text-xl font-bold font-poppins text-black capitalize "
      >
        weekly status
      </Title>
      <Group spacing={5} mb={16}>
        <Text className="text-black font-medium font-poppins text-sm capitalize">
          from
        </Text>
        <Text className="font-normal text-black font-poppins text-sm capitalize">
          1 Aug
        </Text>
        <Text className="font-normal text-black font-poppins text-sm capitalize">
          30 Aug
        </Text>
      </Group>
      <SuperAdminBarChart />
    </Paper>
  );
};

export default SuperAdminWeeklyStatus;
