import { Select } from "@mantine/core";
import useFetchBranches from "../../hooks/useFetchBranches";

export function DepartmentLocation({ form }) {
  const { branchLocations } = useFetchBranches();

  return (
    <Select
      placeholder="Branch location"
      label="Branch"
      description="Always select branch first to filter necessary details"
      size="lg"
      searchable
      nothingFound="no branches"
      clearable
      className="w-full"
      data={branchLocations && branchLocations}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },
          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
        description: {
          fontSize: 12,
          color: "red",
        },
      })}
      {...form.getInputProps("deptLocationID")}
    />
  );
}
