import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createDept, editDepartment } from "../../../services/requests";
import { Box, Group, Button, TextInput, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import {
  cancelEdit,
  failedNotify,
  successNotify,
} from "../../../services/functions";
import { DepartmentLocation } from "../../../components/singleSelect/DepartmentLocation";
import { mutate } from "swr";
import { useContext } from "react";
import { UserTypeContext } from "../../../App";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { useCurrentUser } from "../../../hooks/useCurrentUser";
import { TempValueContext } from "../../../context/TempValueContext";

const CreateDepartment = () => {
  const userType = useContext(UserTypeContext).userType;
  const { tempValue: teamEditInfo, setTempValue } =
    useContext(TempValueContext);
  const { user } = useCurrentUser();
  const branchID = user?.user_type !== "super_admin" ? user?.branch?.id : "";
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const form = useForm({
    initialValues: {
      deptName: "",
      description: "",
      deptLocationID: branchID,
    },

    validate: {
      deptName: isNotEmpty("Compulsory field"),
      description: isNotEmpty("Compulsory field"),
      deptLocationID: isNotEmpty("Compulsory field"),
    },
  });

  useEffect(() => {
    if (teamEditInfo.value) {
      form.setValues({
        deptName: teamEditInfo.label,
        description: teamEditInfo.description,
        deptLocationID: teamEditInfo.value,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamEditInfo]);

  const handleSubmit = async (values, e) => {
    e.preventDefault();
    setLoader(true);
    const details = {
      deptId: teamEditInfo.id ?? "",
      name: values.deptName,
      description: values.description,
      branch_id: values.deptLocationID,
    };

    if (teamEditInfo.value) {
      const updateRes = await editDepartment(details);
      if (updateRes.response) {
        const { errors } = updateRes.response.data;
        failedNotify("Edit status", errors.name[0]);
      }
      if (updateRes.status) {
        successNotify("Edit status", updateRes.message);
        setTempValue("");
        form.reset();
        mutate("api/all-dept-emps");
        setTimeout(() => navigate("/team"), 1500);
      }
    } else {
      const response = await createDept(details);
      if (response.response) {
        const { errors } = response.response.data;
        failedNotify("Create status", errors.name[0]);
      }
      if (response.status) {
        successNotify("Create status", response.message);
        form.reset();
        mutate("api/all-dept-emps");
        setTimeout(() => navigate("/team"), 1500);
      }
    }
    setLoader(false);
  };

  return (
    <div className="w-full h-fit 3xl:max-w-[1222px]">
      <div className="mb-5 w-full pb-8">
        <Box className="w-full mb-5">
          <LocalBreadcrumb
            header="Create department"
            prev="team"
            current="create department"
            link="team"
          />
        </Box>

        <div className="bg-white mt-4 w-full ">
          <form
            onSubmit={form.onSubmit((values, e) => handleSubmit(values, e))}
            className="w-full max-w-md"
          >
            <Box className="w-full">
              <TextInput
                placeholder="Enter department name"
                label="Department Name"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    fontWeight: 500,
                  },
                  input: {
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("deptName")}
              />

              <Textarea
                label="Description"
                size="lg"
                mb={16}
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 500,
                  },
                  input: {
                    borderWidth: 1,
                    "&:focus-within": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("description")}
              />
              {userType === "super_admin" && <DepartmentLocation form={form} />}
              <Group position="right">
                {teamEditInfo.value && (
                  <Button
                    onClick={() => cancelEdit(setTempValue, navigate, "/team")}
                    type="button"
                    variant="white"
                    className="bg-red-700 text-white text-sm font-bold capitalize  "
                    size="md"
                    radius={4}
                    mt={24}
                  >
                    cancel
                  </Button>
                )}
                <Button
                  type="submit"
                  variant="white"
                  className="bg-luchiPrimary text-white text-sm font-bold capitalize  "
                  size="md"
                  radius={4}
                  mt={24}
                  loading={loader}
                  loaderPosition="right"
                >
                  create
                </Button>
              </Group>
            </Box>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateDepartment;
