import React from "react";
import { Box, Paper, Text, Title, Loader } from "@mantine/core";
import { BiChevronRight } from "react-icons/bi";
import useFetchBranches from "../../../../hooks/useFetchBranches";

const SuperAdminAttendance = () => {
  const { branchLocations, isLoading, error } = useFetchBranches();
  return (
    <Paper withBorder p="md" shadow="md" className="max-w-sm w-full bg-white ">
      <Title className="text-xl font-bold text-black ">
        Time and Attendance
      </Title>
      <Text className="font-semibold text-base mt-1 text-black">Branches</Text>
      <Box mt={20}>
        {isLoading && (
          <Box className="w-full h-24 flex items-center justify-center">
            <Loader color="cyan" />
          </Box>
        )}
        {error && (
          <Box className="w-full h-24 flex items-center justify-center">
            <Loader color="red" variant="dots" />
          </Box>
        )}
        {branchLocations?.length === 0 ? (
          <Box className="w-full h-40 flex items-center justify-center">
            <Text className="text-base text-black uppercase">
              no branch yet!
            </Text>
          </Box>
        ) : (
          branchLocations.map((item) => {
            return (
              <Box
                color="dark"
                size="md"
                key={item.value}
                className="w-full p-2 rounded-md border-black border-[1px] capitalize text-sm font-poppins mb-4 text-black flex items-center justify-between relative"
              >
                {item.label}
                <Box className="absolute top-3 right-2">
                  <BiChevronRight size="1rem" />
                </Box>
              </Box>
            );
          })
        )}
      </Box>
    </Paper>
  );
};

export default SuperAdminAttendance;
