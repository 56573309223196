import { Box, Progress, Text } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { reportData } from "../../data/data";

const PAGE_SIZES = [10, 20, 50];

export default function ReportActivity() {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = reportData.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(reportData.slice(from, to));
  }, [page, pageSize]);

  return (
    <>
      <Box className="h-[350px]  ">
        <DataTable
          withBorder
          striped
          withColumnBorders
          textSelectionDisabled
          records={records}
          fontSize={13}
          minHeight={150}
          noRecordsText="No records to show"
          columns={[
            {
              accessor: "name",
              title: "Team Member",
              width: 150,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ firstName, lastName, position }) => (
                <>
                  <Text>{`${firstName} ${lastName} `}</Text>
                  <Text fz={12}>{position}</Text>
                </>
              ),
            },
            // { accessor: "position", width: 100, ellipsis: true },
            {
              accessor: "task",
              width: 150,
              ellipsis: true,
            },
            { accessor: "duration", width: 100, ellipsis: true },
            {
              accessor: "level",
              title: "Activity Level",
              width: 100,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ level }) => (
                <Progress
                  size="lg"
                  radius="xl"
                  color={`${
                    level < 50 ? "red" : level < 80 ? "yellow" : "green"
                  }`}
                  value={level}
                  striped
                  animate
                  label={`${level}%`}
                />
              ),
            },
            {
              accessor: "start",
              title: "Start Time",
              width: 70,
              ellipsis: true,
            },
            {
              accessor: "end",
              title: "End Time",
              width: 70,
              ellipsis: true,
            },

            {
              accessor: "break",
              width: 50,
              cellsStyle: {
                textTransform: "capitalize",
              },
            },
          ]}
          totalRecords={reportData.length}
          paginationColor="cyan"
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
        />
      </Box>
    </>
  );
}
