import DashboardTop from "../../components/DashboardTop/DashboardTop";
import SuperAdminOverviewCard from "../../components/Users/SuperAdmin/dashboard/SuperAdminOverviewCard";
import SuperAdminWeeklyStatus from "../../components/Users/SuperAdmin/dashboard/SuperAdminWeeklyStatus";
import SuperAdminAttendance from "../../components/Users/SuperAdmin/dashboard/SuperAdminAttendance";
import SuperAdminMonthlyLog from "../../components/Users/SuperAdmin/dashboard/SuperAdminMonthlyLog";
import { Box, Loader } from "@mantine/core";
import { useCurrentUser } from "../../hooks/useCurrentUser";

export default function SuperAdminDashboard() {
  const { loading } = useCurrentUser();
  return loading ? (
    <Box className="flex justify-center items-center h-full">
      <Loader color="cyan" />
    </Box>
  ) : (
    <Box className="w-full h-full max-w-7xl  ">
      <DashboardTop />
      <Box
        mb={20}
        className="flex flex-col lg:flex-row md:space-y-6 lg:space-y-0 lg:space-x-[24px] xl:justify-between "
      >
        <SuperAdminOverviewCard />
        <SuperAdminWeeklyStatus />
      </Box>
      <Box className="flex flex-col md:space-y-6 lg:space-y-0 lg:space-x-[24px] xl:justify-between lg:flex-row ">
        <SuperAdminAttendance />
        <SuperAdminMonthlyLog />
      </Box>
    </Box>
  );
}
