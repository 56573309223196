import { Link } from "react-router-dom";
import UtilityHeader from "../../../components/headers/UtilityHeader";
import AttendanceMenuModal from "../../../components/modal/AttendanceMenuModal";
import { getAttendanceByNumberFetcher } from "../../../services/requests";
import useSWR from "swr";
import { getMonthYear, monthYearQuery } from "../../../services/functions";
import { useEffect, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { BiErrorAlt } from "react-icons/bi";

export default function Attendances() {
  const { data, isLoading, error } = useSWR(20, getAttendanceByNumberFetcher);
  const [displayAttendance, setDisplayAttendance] = useState([]);

  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (data) {
      data.filter((item) => {
        if (!set.has(getMonthYear(item.signin_at))) {
          set.add(getMonthYear(item.signin_at));
          tempArray.push(item);
        }
        return tempArray;
      });
      setDisplayAttendance(tempArray);
    }
  }, [data]);
  return (
    <main className="relative w-full max-w-7xl ">
      <UtilityHeader
        title={"attendance"}
        subtitle="Time & Attendance"
        backLink="/utility-management/time-attendance"
      />
      <div className=" h-full overflow-x-auto w-full max-w-3xl">
        {error && (
          <div className="w-full h-full flex items-center justify-center space-x-2">
            <BiErrorAlt className="w-6 h-6 text-red-800" />
            <p className=" text-black text-sm">Error! refresh to try again</p>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full flex items-center justify-center space-x-2 overflow-hidden">
            <FaSpinner className="animate-spin w-6 h-6 text-black" />
            <p className="capitalize text-black text-base">loading</p>
          </div>
        )}

        {!isLoading &&
          !error &&
          data &&
          displayAttendance.map((item, index) => (
            <div
              key={index}
              className="flex bg-white rounded-[10px] py-3 px-6 mb-5 "
            >
              <Link
                to={`/utility-management/time-attendance/attendances/${monthYearQuery(
                  item.signin_at
                )}`}
                className="flex-1 cursor-pointer "
              >
                <span className="font-medium text-base capitalize text-black/[0.85] space-x-1">
                  <span>{getMonthYear(item.signin_at)}</span>
                </span>
                <p className="font-normal text-sm text-black/60">
                  This contains the time and attendance records for the month.
                </p>
              </Link>
              <div className=" ">
                <AttendanceMenuModal />
              </div>
            </div>
          ))}
      </div>
    </main>
  );
}
