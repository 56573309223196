import { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Group, ActionIcon, Modal, Menu, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { deleteDeptById, permDeleteDeptById } from "../../services/requests";
import {
  failedNotify,
  handleEdit,
  successNotify,
} from "../../services/functions";
import { mutate } from "swr";
import { BsThreeDotsVertical, BsEye } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { TempValueContext } from "../../context/TempValueContext";
import { useMemo } from "react";

export default function ActionMenu({ data }) {
  const [opened, { open, close }] = useDisclosure(false);
  const navigate = useNavigate();
  const [btnLoader, setBtnLoader] = useState(false);
  const setTempValue = useContext(TempValueContext).setTempValue;

  const filteredData = useMemo(
    () => ({
      id: data.id,
      label: data.name,
      value: data.branch_id,
      description: data.description,
    }),
    [data]
  );

  async function handleDelete() {
    setBtnLoader(true);
    const res = await deleteDeptById(data.id ? data.id : data.value);
    if (res.status) {
      const permRes = await permDeleteDeptById(data.id ? data.id : data.value);
      if (permRes.status) {
        close();
        successNotify("Delete Status", "Department deleted successfully");
        mutate("api/all-dept-emps");
      } else {
        failedNotify("Delete Status", "Delete operation failed!");
      }
    } else {
      failedNotify("Delete Status", "Delete operation failed!");
    }
    setBtnLoader(false);
  }
  return (
    <Menu position="left-end" shadow="md" width={120}>
      <Menu.Target>
        <ActionIcon
          aria-label="action button"
          className="bg-transparent hover:bg-transparent"
        >
          <BsThreeDotsVertical size="1.125rem" color="black" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Link to={`/team/${data.id ? data.id : data.value}`}>
          <Menu.Item icon={<BsEye size={14} />}>view</Menu.Item>
        </Link>
        <Menu.Item
          onClick={() =>
            handleEdit(
              setTempValue,
              navigate,
              "/team/create-department",
              filteredData
            )
          }
          icon={<BiEditAlt size={14} />}
        >
          edit
        </Menu.Item>
        <Menu.Item onClick={open} icon={<AiOutlineDelete size={14} />}>
          delete
        </Menu.Item>
      </Menu.Dropdown>
      <DeleteConfirmationModal
        opened={opened}
        close={close}
        handleDelete={handleDelete}
        entity="department?"
        btnLoader={btnLoader}
      />
    </Menu>
  );
}

export function DeleteConfirmationModal({
  opened,
  close,
  handleDelete,
  entity,
  btnLoader,
}) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        className="uppercase"
        title={`delete ${entity}`}
        centered
      >
        <Text className="font-normal text-center normal-case">
          {`Are you sure you want to delete this ${entity}`}
        </Text>
        <Text className="text-sm text-yellow-600 text-center normal-case">
          This infomation cannot be recovered.
        </Text>

        <Group position="center" className="mt-5 w-full  ">
          <Button
            variant="outline"
            type="button"
            className="bg-transparent capitalize font-medium text-luchiPrimary "
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="white"
            onClick={handleDelete}
            type="button"
            loading={btnLoader}
            loaderPosition="right"
            className="bg-red-800 capitalize font-medium text-white "
          >
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
}
