import useSWR from "swr";
import {
  getAllEventsFetcher,
  getEventsByDateFetcher,
  getEventsByTypeFetcher,
} from "../services/requests";

export function useFetchEvents() {
  const { data, isLoading, error } = useSWR(
    "api/all-events",
    getAllEventsFetcher
  );

  return {
    data,
    isLoading,
    error,
  };
}

export function useFetchEventsByType(type) {
  const { data, isLoading, error } = useSWR(type, getEventsByTypeFetcher);

  return {
    data,
    isLoading,
    error,
  };
}

export function useFetchEventsByDate(date) {
  const { data, isLoading, error } = useSWR(date, getEventsByDateFetcher);

  return {
    data,
    isLoading,
    error,
  };
}
