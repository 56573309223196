import React from "react";
import { Link } from "react-router-dom";
import { userProfileBoard } from "../../../assets/data/userProfileBoard";
import { Avatar, Box, ActionIcon, Group, Paper, Text } from "@mantine/core";
import { BiChevronRight, BiLogOut } from "react-icons/bi";
import { useDisclosure } from "@mantine/hooks";
import LogoutModal from "../../../components/modal/LogoutModal";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

const UserProfile = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const { user } = useCurrentUser();

  return (
    <main className="w-full max-w-7xl  ">
      <Box className="flex items-center ">
        <Avatar
          src={user?.avatar_url}
          alt={`${user?.first_name}'s image`}
          size={128}
          mr={32}
        />
        <Box className="w-full">
          <Group
            spacing={4}
            className=" flex w-full items-center text-2xl font-normal text-black capitalize"
          >
            <Text className="font-poppins">{user?.first_name}</Text>
            <Text className="font-poppins">{user?.last_name}</Text>
          </Group>

          <Text className="text-black font-bold capitalize ">
            {user?.user_type === "super_admin" && "super admin"}
          </Text>
        </Box>
      </Box>
      <Box mt={30}>
        {userProfileBoard.map((item, index) => (
          <Link key={index} to={item.path}>
            <Paper
              withBorder
              className="capitalize w-full max-w-[800px] my-[12px] flex justify-between items-center p-3 "
            >
              <Text className="font-medium "> {item.title}</Text>
              <ActionIcon variant="transparent">
                <BiChevronRight size="xl" />
              </ActionIcon>
            </Paper>
          </Link>
        ))}
        <Paper
          withBorder
          onClick={open}
          className="capitalize w-full max-w-[800px] my-[12px] flex justify-between items-center p-3 cursor-pointer "
        >
          <Text className="font-medium"> logout</Text>
          <ActionIcon variant="transparent">
            <BiLogOut size="xl" color="red" />
          </ActionIcon>
        </Paper>
      </Box>
      <LogoutModal
        opened={opened}
        close={close}
        title="Are you sure you want to log out?"
        subtitle="All your unsaved data will be lost. We won't be able to notify you!"
        btnText="logout"
        type="single"
      />
    </main>
  );
};

export default UserProfile;
