import React from "react";
import { ActionIcon, Menu } from "@mantine/core";
import { BiDotsVertical } from "react-icons/bi";
import { AiFillMail, AiFillSave, AiFillDelete } from "react-icons/ai";

function MenuActions({ openDelModal }) {
  return (
    <Menu shadow="md" width={200} position="left-start">
      <Menu.Target>
        <ActionIcon variant="outline" color="cyan">
          <BiDotsVertical size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>Actions</Menu.Label>
        <Menu.Item icon={<AiFillMail size={14} />}>Mail</Menu.Item>
        <Menu.Item icon={<AiFillSave size={14} />}>Save to device</Menu.Item>
        <Menu.Item onClick={openDelModal} icon={<AiFillDelete size={14} />}>
          Delete
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

const EventsMenuActions = React.memo(MenuActions);
export default EventsMenuActions;
