import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import EmployeeDetailsModal from "../../../components/modal/EmployeeDetailsModal";
import { FaAngleLeft } from "react-icons/fa";
import { getAttendanceByDateFetcher } from "../../../services/requests";
import { getAttendanceTime } from "../../../services/functions";

export default function AttendanceByDay() {
  const { date, day } = useParams();
  const { data } = useSWR(day, getAttendanceByDateFetcher);
  const [openEmployeeDetails, setOpenEmployeeDetails] = useState(false);
  const [attendance, setAttendance] = useState("");
  const openModal = () => setOpenEmployeeDetails(true);

  function handleDisplay(item) {
    openModal();
    setAttendance(item);
  }

  return (
    <main className="relative w-full max-w-7xl  ">
      <div className=" mt-5 mb-5 w-full ">
        <div className="w-full flex justify-between items-center ">
          <h1 className="capitalize font-bold text-black text-2xl leading-7 w-80  ">
            attendance
          </h1>
          {/* <div className="flex justify-end">use branchLocation component for filtering if needed</div> */}
        </div>
        <div className="flex space-x-2 items-center ">
          <div className=" text-black">
            <p className="flex items-center font-semibold text-lg capitalize ">
              {`${day.split("-")[2]}, `} {date.split("-").join(" ")}
            </p>
          </div>
          <Link
            to={`/utility-management/time-attendance/attendances/${date}`}
            className="self-end btn btn-active btn-link hover:bg-transparent border-none text-[#07939BCC] text-xs capitalize"
          >
            <FaAngleLeft className=" w-5 h-5 " />
          </Link>
        </div>
      </div>
      <div className="bg-white w-full max-w-[1158px] rounded-[20px] mb-24 py-3 px-5 ">
        <p className="mb-5 text-lg font-semibold text-black/[0.85] capitalize">
          Front Door
        </p>
        <div className="overflow-x-auto w-full ">
          <table className="table w-full">
            <thead>
              <tr>
                <th>Sign in</th>
                <th>Employee name</th>
                <th>Credential</th>
                <th>Time at work(hrs)</th>
                <th>Sign out</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item) => (
                  <tr key={item.id} className=" ">
                    <td>{getAttendanceTime(item.signin_at)}</td>
                    <td className="capitalize">{item.employee.name}</td>
                    <td>{item.credential}</td>
                    <td>{item.time_at_work}</td>
                    <td>{getAttendanceTime(item.signout_at)}</td>
                    <th>
                      <button
                        onClick={() => handleDisplay(item)}
                        className="capitalize btn btn-ghost btn-xs text-black hover:bg-[#37A7AE] hover:text-white"
                      >
                        view
                      </button>
                    </th>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <EmployeeDetailsModal
        open={openEmployeeDetails}
        setOpen={setOpenEmployeeDetails}
        attendance={attendance}
      />
    </main>
  );
}
