import { useState } from "react";
import HeaderBar from "../../../components/headers/HeaderBar";
import { Box, Paper, Title, Group, Text, Loader } from "@mantine/core";
import EventsMenuActions from "../../../components/menu/EventsMenuActions";
import { useDisclosure } from "@mantine/hooks";
import { EventLogDelete } from "./monthly-logs";
import DataEvent from "../../../components/tables/DataEvent";
import { useParams } from "react-router-dom";
import { useFetchEventsByDate } from "../../../hooks/useFetchEvents";
import { useEffect } from "react";
import BackButton from "../../../components/BackButton";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function DataEventDayLogs() {
  const { user } = useCurrentUser();
  const [opened, { open, close }] = useDisclosure(false);
  const [value, setValue] = useState({});
  const { day } = useParams();
  const { data, isLoading, error } = useFetchEventsByDate(day && day);
  // const [branchID, setBranchID] = useState(
  //   user?.user_type !== "super_admin" ? user?.branch?.id : ""
  // );
  // const [branch, setBranch] = useState({
  //   branchLocation: branchID,
  // });

  useEffect(() => {
    if (data) {
      const result = {};
      data &&
        data.forEach((item) => {
          const deviceName = item.device.name;
          if (!result[deviceName]) {
            result[deviceName] = [];
          }
          if (item.type.toLowerCase() !== "video")
            result[deviceName].push(item);
        });
      setValue(result);
    }
  }, [data]);

  return (
    <div className="w-full max-w-7xl ">
      <div className="w-full z-10">
        <HeaderBar
          title="Data Events"
          data={[]}
          setData={{}}
          user={user}
          show={false}
        />
      </div>
      <Box>
        <BackButton />
      </Box>
      <Paper
        p="sm"
        radius={8}
        className="w-full mt-10 bg-transparent max-w-[1096px] "
      >
        {isLoading && (
          <Box className="w-full h-full flex justify-center ">
            <Loader variant="oval" color="cyan" />
          </Box>
        )}
        {error && (
          <Box className="w-full h-full flex justify-center ">
            <Loader variant="dots" color="red" />
            <Text ml={2}>Error!</Text>
          </Box>
        )}
        {value?.length === 0 ? (
          <Box className="w-full h-full items-center flex justify-center ">
            <Text className="text-sm uppercase ">no video events yet!</Text>
          </Box>
        ) : (
          Object.keys(value).map((deviceName) => (
            <Box key={deviceName} mb={20}>
              <Group position="apart" mb={26}>
                <Title className="text-lg capitalize font-poppins font-semibold">
                  {deviceName}
                </Title>
                <Box className="flex items-center space-x-6">
                  <EventsMenuActions openDelModal={open} />
                </Box>
              </Group>

              <DataEvent data={value[deviceName]} isLoading={isLoading} />
            </Box>
          ))
        )}
      </Paper>
      <EventLogDelete opened={opened} close={close} />
    </div>
  );
}
