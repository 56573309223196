import { useState } from "react";
import { createBranch, updateBranch } from "../../../services/requests";
import { Box, Button, Group, TextInput } from "@mantine/core";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import CredentialsSwitch from "../../../components/CredentialsSwitch";
import {
  cancelEdit,
  cautionNotify,
  failedNotify,
  successNotify,
} from "../../../services/functions";
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { TempValueContext } from "../../../context/TempValueContext";

export default function CreateBranch() {
  const [loader, setLoader] = useState(false);
  const { tempValue: branchInfo, setTempValue } = useContext(TempValueContext);
  const navigate = useNavigate();
  const { trigger } = useSWRMutation("/api/updateUser", updateBranch);
  const form = useForm({
    initialValues: {
      branchName: "",
      branchPhone: "",
      branchLocation: "",
      branchEmail: "",
      credentials: {
        FINGERPRINT: false,
        RFID_CARD: false,
        MOBILE_APP: false,
        PIN: false,
      },
    },

    validate: {
      branchName: isNotEmpty("Compulsory field"),
      branchPhone: isNotEmpty("Compulsory field"),
      branchLocation: isNotEmpty("Compulsory field"),
      branchEmail: isEmail("Compulsory field"),
    },
  });

  useEffect(() => {
    if (branchInfo.id) {
      form.setValues({
        branchName: branchInfo.name,
        branchPhone: branchInfo.phone_number,
        branchEmail: branchInfo.email,
        branchLocation: branchInfo.address,
        credentials: { ...branchInfo.credentials },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchInfo]);

  const handleCreateBranch = async (values, e) => {
    e.preventDefault();
    setLoader(true);
    const details = {
      id: branchInfo.id ? branchInfo.id : "",
      name: values.branchName,
      email: values.branchEmail,
      phone_number: values.branchPhone,
      address: values.branchLocation,
      credentials: values.credentials,
    };

    if (branchInfo.id) {
      try {
        const updateRes = await trigger(details);
        successNotify("Update status", updateRes.message);
        setTempValue("");
        form.reset();
        mutate("api/all-branches");
        setTimeout(() => navigate("/organization-setup"), 2000);
      } catch (error) {
        console.error(error.response);
      }
    } else {
      const response = await createBranch(details);
      if (response.response) {
        if (response.response.status === 400)
          cautionNotify("Branch Creation", "Branch name already exist.");
      } else if (response.status) {
        successNotify("Branch Creation", response.message);
        form.reset();
      } else {
        failedNotify("Branch Creation", "Branch creation failed, try again.");
      }
    }
    setLoader(false);
  };

  return (
    <main className="w-full pb-8  ">
      <div className="max-w-lg w-full bg-white ">
        <Box>
          <LocalBreadcrumb
            prev="branches"
            current="create branch"
            link="organization-setup"
            header="create branch"
          />
        </Box>

        <Box mt={20}>
          <form
            onSubmit={form.onSubmit((values, e) =>
              handleCreateBranch(values, e)
            )}
            className="w-full max-w-lg"
          >
            <TextInput
              placeholder="Enter branch name eg: Luchismart_Abuja"
              withAsterisk
              label="Branch Name"
              size="lg"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("branchName")}
            />

            <Box
              my={16}
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 w-full"
            >
              <TextInput
                className="w-full"
                placeholder="Eg: +2347060326781"
                withAsterisk
                label="Branch Phone"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    fontWeight: 500,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("branchPhone")}
              />
              <TextInput
                className="w-full"
                placeholder="Enter branch location (eg: Abuja)"
                withAsterisk
                label="Branch Location"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    fontWeight: 500,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("branchLocation")}
              />
            </Box>

            <TextInput
              className="w-full"
              placeholder="Enter branch email"
              withAsterisk
              label="Branch Email"
              size="lg"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("branchEmail")}
            />

            <Box my={16}>
              <CredentialsSwitch form={form} />
            </Box>

            <Group mt={50} position="right" className="">
              {branchInfo && branchInfo.id && (
                <Button
                  onClick={() =>
                    cancelEdit(setTempValue, navigate, "/organization-setup")
                  }
                  type="button"
                  variant="white"
                  className="bg-red-700 text-white text-sm font-bold capitalize  "
                  size="md"
                  radius={4}
                >
                  cancel
                </Button>
              )}
              <Button
                type="submit"
                variant="white"
                className="bg-luchiPrimary text-white text-sm font-medium font-poppins capitalize  "
                size="md"
                radius={4}
                loading={loader}
                loaderPosition="right"
              >
                create branch
              </Button>
            </Group>
          </form>
        </Box>
      </div>
    </main>
  );
}
