import { useState } from "react";
import { paginate } from "../paginate";

export default function useCustomPaginate(data, pagesize) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(pagesize);
  const paginatedData = paginate(data && data, currentPage, pageSize);
  const totalPages = Math.ceil(data && data.length / pageSize);

  return {
    currentPage,
    setCurrentPage,
    paginatedData,
    totalPages,
  };
}
