import {
  useFetchBranchDetails,
  useFetchEmployeesData,
} from "../../../hooks/team/hooks";
import { useState, useEffect } from "react";
import EmployeesTable from "../../../components/tables/EmployeesTable";
import { Box } from "@mantine/core";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function EmployeesPage() {
  const { user } = useCurrentUser();
  const branchID = user?.user_type !== "super_admin" ? user?.branch?.id : "";
  const { employees, employeeLoading, employeeError } = useFetchEmployeesData();
  const { teamsInfo, isLoading, error } = useFetchBranchDetails(branchID);
  const [teamDetail, setTeamDetail] = useState({
    dataError: {},
    allEmps: [],
    loading: false,
  });

  useEffect(() => {
    if (user?.access_type === "mid_level_admin") {
      setTeamDetail((teamDetail) => ({
        ...teamDetail,
        dataError: error,
        allEmps: teamsInfo && teamsInfo.members,
        loading: isLoading,
      }));
    } else if (user?.user_type === "super_admin") {
      setTeamDetail((teamDetail) => ({
        ...teamDetail,
        dataError: employeeError,
        allEmps: employees && employees,
        loading: employeeLoading,
      }));
    }
  }, [
    teamsInfo,
    error,
    employeeError,
    employees,
    user,
    isLoading,
    employeeLoading,
  ]);

  return (
    <div className="w-full 3xl:max-w-7xl  ">
      <Box className="w-full mb-5">
        <LocalBreadcrumb
          header="employee"
          prev="team"
          current="employee table"
          link="team"
        />
      </Box>
      <div className="overflow-x-auto bg-white w-full">
        <div className="w-full">
          <EmployeesTable
            employeesData={teamDetail.allEmps}
            loading={teamDetail.loading}
          />
        </div>
      </div>
    </div>
  );
}
