import { useFetchEmployeesData } from "../../../hooks/team/hooks";
import { useState, useEffect } from "react";
import EmployeesTable from "../../../components/tables/EmployeesTable";
import { Box } from "@mantine/core";
import { useParams } from "react-router-dom";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";

export default function DepartmentEmployeesPage() {
  const param = useParams();
  const [deptEmps, setDeptEmps] = useState([]);
  const { employees, employeeLoading } = useFetchEmployeesData();

  useEffect(() => {
    if (employees) {
      const deptEmployees = employees.filter(
        (employees) => employees.department.id === param.deptId
      );
      setDeptEmps(deptEmployees);
    }
  }, [employees, param.deptId]);

  return (
    <Box className="w-full 3xl:max-w-7xl ">
      <>
        <Box className="w-full mb-5">
          <LocalBreadcrumb
            header="employees"
            prev="team"
            current="employee table"
            link="team"
          />
        </Box>
        <Box className="overflow-x-auto bg-white w-full">
          <Box className="w-full">
            <EmployeesTable
              employeesData={deptEmps && deptEmps}
              loading={employeeLoading}
            />
          </Box>
        </Box>
      </>
    </Box>
  );
}
