import { Link, useLocation } from "react-router-dom";
import {
  attendanceClockin,
  getAttendanceByNumberFetcher,
} from "../../../services/requests";
import useSWR from "swr";
import {
  cautionNotify,
  failedNotify,
  getMonthYear,
  successNotify,
} from "../../../services/functions";
import { useEffect, useRef, useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import HeaderBar from "../../../components/headers/HeaderBar";
import {
  Box,
  Loader,
  Paper,
  Text,
  Title,
  Button,
  Group,
  Modal,
  Select,
  ScrollArea,
} from "@mantine/core";
import Clock from "react-clock";
import "react-clock/dist/Clock.css";
import { useDisclosure } from "@mantine/hooks";

export default function TimeAttendance() {
  const { pathname } = useLocation();
  const { data, isLoading, error } = useSWR(6, getAttendanceByNumberFetcher);
  const [displayAttendance, setDisplayAttendance] = useState([]);

  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (data) {
      data.filter((item) => {
        if (!set.has(getMonthYear(item.signin_at))) {
          set.add(getMonthYear(item.signin_at));
          tempArray.push(item);
        }
        return tempArray;
      });
      setDisplayAttendance(tempArray);
    }
  }, [data]);

  return (
    <main className="relative w-full max-w-7xl ">
      <Box className="w-full flex">
        <HeaderBar
          title="time & attendance"
          data={{}}
          setData={{}}
          user={{}}
          show={false}
        />
        <AttendanceClock />
      </Box>
      {/* attendance section */}
      <Paper my={20} p="sm" withBorder className="h-fit w-full max-w-4xl">
        <Title
          order={1}
          className="text-lg text-black/[0.85] font-semibold font-poppins capitalize mb-6 "
        >
          attendance
        </Title>
        {error && (
          <Box className="w-full h-full flex items-center justify-center space-x-2">
            <BiErrorAlt className="w-6 h-6 text-red-800" />
            <p className=" text-black text-sm">Error! refresh to try again</p>
          </Box>
        )}
        {isLoading && (
          <Box className="w-full h-full flex items-center justify-center space-x-2">
            <Loader variant="oval" color="cyan" size="sm" my={10} />
          </Box>
        )}
        {data && data.length !== 0 ? (
          <>
            {data &&
              displayAttendance.map((item) => (
                <Paper
                  p="sm"
                  key={item.id}
                  className="mb-2 max-w-2xl bg-luchiPrimary text-white"
                >
                  <Text className="font-medium text-base capitalize  ">
                    {getMonthYear(item.signin_at)}
                  </Text>
                  <Text className="font-normal text-sm ">
                    This contains the time and attendance records for the month.
                  </Text>
                </Paper>
              ))}
            <Link
              to={`${pathname}/attendances`}
              className="w-full flex items-center justify-end mt-4"
            >
              <Button
                variant="white"
                className="text-[#37A7AE] font-medium text-xs font-poppins capitalize "
              >
                see more
              </Button>
            </Link>
          </>
        ) : (
          <>
            <Group spacing={5} position="center" className="w-full">
              <MdOutlineHourglassEmpty size={24} />
              <Text tt="uppercase" fz={14}>
                no attendance yet!
              </Text>
            </Group>
          </>
        )}
      </Paper>

      {/* leave section */}
      <Paper withBorder p="sm" className="h-fit w-full max-w-4xl ">
        <Title
          order={2}
          className="text-lg text-black/[0.85] font-semibold capitalize mb-6 "
        >
          leave
        </Title>
        {[1, 2, 3].map((item, index) => (
          <Paper
            p="sm"
            key={index}
            className="mb-2 max-w-2xl bg-yellow-700 text-white"
          >
            <Text className="font-medium text-base capitalize  ">
              Grace Johnson
            </Text>
            <Text component="span" className="font-normal text-sm ">
              Sick Leave
            </Text>
            <Group className="font-normal text-sm " spacing={5}>
              <Text component="span">Leave from:</Text>
              <Text component="span">2nd Aug</Text>
              <Text component="span">-</Text>
              <Text component="span">10th Aug</Text>
            </Group>
          </Paper>
        ))}
        <Link
          to={`${pathname}/leave`}
          className="w-full flex items-center justify-end mt-4"
        >
          <Button
            variant="white"
            className="text-yellow-700 font-medium text-xs font-poppins capitalize "
          >
            see more
          </Button>
        </Link>
      </Paper>

      {/* overtime section */}
      <Paper mt={20} withBorder p="sm" className="h-fit w-full max-w-4xl ">
        <Title
          order={2}
          className=" text-lg text-black/[0.85] font-semibold capitalize mb-6 "
        >
          overtime
        </Title>
        {[1, 2, 3].map((item, index) => (
          <Paper
            p="sm"
            key={index}
            className="mb-2 max-w-2xl bg-luchiPrimary text-white"
          >
            <Text className="font-medium text-base capitalize  ">
              Kal Norman
            </Text>
            <Text className="font-normal text-sm ">Operations department</Text>
            <Box>
              <Text component="span" className="font-normal text-sm ">
                2 hrs overtime on
              </Text>
              <Text component="span" className="font-normal text-sm ml-1 ">
                2nd Aug
              </Text>
            </Box>
          </Paper>
        ))}
        <Link
          to={`${pathname}/overtime`}
          className="w-full flex items-center justify-end mt-4"
        >
          <Button
            variant="white"
            className="text-[#37A7AE] font-medium text-xs font-poppins capitalize "
          >
            see more
          </Button>
        </Link>
      </Paper>
    </main>
  );
}

function AttendanceClock() {
  const [value, setValue] = useState(new Date());
  const [opened, { open, close }] = useDisclosure(false);
  const [loader, setLoader] = useState(false);
  const [attendance, setAttendance] = useState(false);
  const credRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => setValue(new Date()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function attendanceSignin() {
    setLoader(true);
    if (credRef.current.value !== "") {
      const res = await attendanceClockin(credRef.current.value.toUpperCase());
      if (res.status) {
        setAttendance(true);
        successNotify("Notification", "Attendance marked successfully");
        close();
      } else {
        failedNotify("Notification", "Attendance failed");
      }
    } else {
      cautionNotify("notification", "Credential cannot be empty!");
    }
    setLoader(false);
  }
  return (
    <>
      {attendance && (
        <Button
          title="attendance signout"
          variant="white"
          className="bg-red-800 text-white capitalize text-xs mr-2"
        >
          signout
        </Button>
      )}
      <div className="w-fit cursor-pointer" onClick={open}>
        <Clock value={value} size={100} />
      </div>
      <AttendanceSigninModal
        opened={opened}
        close={close}
        method={attendanceSignin}
        loader={loader}
        credRef={credRef}
      />
    </>
  );
}

function AttendanceSigninModal({ opened, close, method, loader, credRef }) {
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        withCloseButton={false}
        title="Attendance Signin"
      >
        <ScrollArea className="h-[200px]">
          <Select
            maxDropdownHeight={120}
            label="Credential"
            placeholder="Pick credential"
            size="lg"
            className="w-full"
            data={["Fingerprint", "Mobile_App", "PIN", "RFID_Card"]}
            styles={(theme) => ({
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
              label: {
                paddingBottom: 4,
                fontSize: 14,
              },
              item: {
                fontSize: 14,
                paddingTop: 8,
                paddingBottom: 8,
                "&[data-selected]": {
                  "&, &:hover": {
                    backgroundColor: theme.colors.luchiPrimary[0],
                    color: theme.white,
                  },
                },
                "&[data-hovered]": {},
              },
            })}
            ref={credRef}
          />
          <Box mt={70} className="w-full flex justify-center space-x-4">
            <Button
              fullWidth
              className="bg-red-700 text-white font-medium capitalize"
              type="button"
              onClick={close}
              variant="white"
            >
              cancel
            </Button>
            <Button
              loading={loader}
              loaderPosition="right"
              fullWidth
              color="yellow"
              onClick={method}
              variant="white"
              type="button"
              className="font-medium bg-luchiPrimary text-white capitalize"
            >
              sign
            </Button>
          </Box>
        </ScrollArea>
      </Modal>
    </>
  );
}
