import React from "react";
import { MdOutlineDashboard, MdOutlineEvent } from "react-icons/md";
import { AiOutlineTeam, AiOutlineUser } from "react-icons/ai";
import { RiOrganizationChart } from "react-icons/ri";
import { GiProcessor, GiJoin } from "react-icons/gi";
import { GoReport } from "react-icons/go";
import { BiAccessibility, BiHelpCircle } from "react-icons/bi";

export const dashboardMenuItems = [
  {
    id: 1,
    label: "Dashboard",
    icon: <MdOutlineDashboard size="1rem" stroke={1.5} />,
    link: "/dashboard",
  },
  {
    label: "User",
    initiallyOpened: true,
    icon: <AiOutlineUser size="1rem" stroke={1.5} />,
    links: [
      {
        id: 2,
        label: "Administrators",
        link: "/user",
      },
      {
        id: 21,
        label: "Standard Users",
        link: "/user/standard-users",
      },
    ],
  },
  {
    id: 3,
    label: "Team",
    icon: <AiOutlineTeam size="1rem" stroke={1.5} />,
    link: "/team",
  },
  {
    label: "Utility Manager",
    icon: <GiProcessor size="1rem" stroke={1.5} />,
    links: [
      {
        id: 4,
        label: "Overview",
        link: "/utility-management",
      },
      {
        id: 5,
        label: "Time & Attendance",
        link: "/utility-management/time-attendance",
      },
      {
        id: 7,
        label: "Productivity Management",
        link: "/utility-management/productivity-management",
      },
    ],
  },
  {
    label: "Organization Setup",
    icon: <RiOrganizationChart size="1rem" stroke={1.5} />,
    initiallyOpened: false,
    links: [
      {
        id: 10,
        label: "Branches",
        link: "/organization-setup",
      },
      {
        id: 11,
        label: "Credentials",
        link: "/organization-setup/credentials",
      },
      // {
      //   id: 12,
      //   label: "Billings",
      //   link: "/organization-setup/billings",
      // },
      {
        id: 13,
        label: "Hardwares",
        link: "/organization-setup/hardwares",
      },
      {
        id: 14,
        label: "Permissions",
        link: "/organization-setup/permissions",
      },
      // {
      //   id: 15,
      //   label: "Payment History",
      //   link: "/organization-setup/payment-history",
      // },
      {
        id: 16,
        label: "Organogram",
        link: "/organization-setup/organogram",
      },
      {
        id: 17,
        label: "Settings",
        link: "/organization-setup/settings",
      },
    ],
  },
  {
    label: "Advanced Access",
    icon: <BiAccessibility size="1rem" stroke={1.5} />,
    links: [
      {
        id: 6,
        label: "Capacity Management",
        link: "/advanced-access/capacity",
      },
      {
        id: 8,
        label: "Occupancy Tracking",
        link: "/advanced-access/occupancy",
      },
      {
        id: 22,
        label: "Intercom",
        link: "/advanced-access/intercom",
      },
    ],
  },
  {
    id: 18,
    label: "Events History",
    icon: <MdOutlineEvent size="1rem" stroke={1.5} />,
    link: "/event",
  },
  // {
  //   id: 19,
  //   label: "Integration",
  //   icon: <GiJoin size="1rem" stroke={1.5} />,
  //   link: "#",
  // },
  {
    id: 20,
    label: "Reports",
    icon: <GoReport size="1rem" stroke={1.5} />,
    link: "/reports",
  },
  {
    id: 9,
    label: "Help & Support",
    icon: <BiHelpCircle size="1rem" stroke={1.5} />,
    link: "/help",
  },
];

export const midLevelMenuItems = [
  {
    id: 1,
    label: "Dashboard",
    icon: <MdOutlineDashboard size="1rem" stroke={1.5} />,
    link: "/dashboard",
  },
  {
    label: "User",
    initiallyOpened: true,
    icon: <AiOutlineUser size="1rem" stroke={1.5} />,
    links: [
      {
        id: 2,
        label: "Administrators",
        link: "/user",
      },
      {
        id: 21,
        label: "Standard Users",
        link: "/user/standard-users",
      },
    ],
  },
  {
    id: 3,
    label: "Team",
    icon: <AiOutlineTeam size="1rem" stroke={1.5} />,
    link: "/team",
  },
  {
    label: "Utility Manager",
    icon: <GiProcessor size="1rem" stroke={1.5} />,
    links: [
      {
        id: 4,
        label: "Overview",
        link: "/utility-management",
      },
      {
        id: 5,
        label: "Time & Attendance",
        link: "/utility-management/time-attendance",
      },
      {
        id: 7,
        label: "Productivity Management",
        link: "/utility-management/productivity-management",
      },
    ],
  },
  {
    label: "Advanced Access",
    icon: <BiAccessibility size="1rem" stroke={1.5} />,
    links: [
      {
        id: 6,
        label: "Capacity Management",
        link: "/advanced-access/capacity",
      },
      {
        id: 8,
        label: "Occupancy Tracking",
        link: "/advanced-access/occupancy",
      },
      {
        id: 22,
        label: "Intercom",
        link: "/advanced-access/intercom",
      },
    ],
  },
  {
    id: 18,
    label: "Events History",
    icon: <MdOutlineEvent size="1rem" stroke={1.5} />,
    link: "/event",
  },
  // {
  //   id: 19,
  //   label: "Integration",
  //   icon: <GiJoin size="1rem" stroke={1.5} />,
  //   link: "#",
  // },
  {
    id: 20,
    label: "Reports",
    icon: <GoReport size="1rem" stroke={1.5} />,
    link: "/reports",
  },
  {
    id: 9,
    label: "Help & Support",
    icon: <BiHelpCircle size="1rem" stroke={1.5} />,
    link: "/help",
  },
];

export const standardDashboardMenu = [
  {
    id: 1,
    label: "Dashboard",
    icon: <MdOutlineDashboard size="1rem" stroke={1.5} />,
    link: "/dashboard",
  },
  {
    label: "User",
    initiallyOpened: true,
    icon: <AiOutlineUser size="1rem" stroke={1.5} />,
    links: [
      {
        id: 2,
        label: "Administrators",
        link: "/user",
      },
      {
        id: 21,
        label: "Standard Users",
        link: "/user/standard-users",
      },
    ],
  },
  {
    id: 3,
    label: "Team",
    icon: <AiOutlineTeam size="1rem" stroke={1.5} />,
    link: "/team",
  },
  {
    label: "Advanced Access",
    icon: <BiAccessibility size="1rem" stroke={1.5} />,
    links: [
      {
        id: 6,
        label: "Capacity Management",
        link: "/advanced-access/capacity",
      },
      {
        id: 8,
        label: "Occupancy Tracking",
        link: "/advanced-access/occupancy",
      },
      {
        id: 22,
        label: "Intercom",
        link: "/advanced-access/intercom",
      },
    ],
  },
  {
    id: 18,
    label: "Events History",
    icon: <MdOutlineEvent size="1rem" stroke={1.5} />,
    link: "/event",
  },
];

export const employeeDashboardMenu = [
  {
    id: 1,
    label: "Dashboard",
    icon: <MdOutlineDashboard size="1rem" stroke={1.5} />,
    link: "/dashboard",
  },

  {
    label: "Advanced Access",
    icon: <BiAccessibility size="1rem" stroke={1.5} />,
    links: [
      {
        id: 6,
        label: "Capacity Management",
        link: "/advanced-access/capacity",
      },
      {
        id: 8,
        label: "Occupancy Tracking",
        link: "/advanced-access/occupancy",
      },
      {
        id: 22,
        label: "Intercom",
        link: "/advanced-access/intercom",
      },
    ],
  },
];

export const rolePermissions = [
  {
    id: 1,
    type: "Access all branch",
  },
  {
    id: 2,
    type: "Access billing",
  },
  {
    id: 3,
    type: "Add details",
  },
  {
    id: 4,
    type: "Add device",
  },
  {
    id: 5,
    type: "Add admin",
  },
  {
    id: 6,
    type: "edit details",
  },
  {
    id: 7,
    type: "delete details",
  },
  {
    id: 8,
    type: "configure settings",
  },
  {
    id: 9,
    type: "order smart lock",
  },
  {
    id: 10,
    type: "configure integration ",
  },
];
