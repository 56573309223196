import { Box, Divider, Group, Paper, Text, Title } from "@mantine/core";
import { AiOutlineStar } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function Reports() {
  return (
    <main className="w-full max-w-7xl">
      <Box className="w-full">
        <Title className="capitalize text-xl font-bold font-poppins text-black mb-4">
          general
        </Title>
        <Box className="w-full max-w-[661px] ">
          <Paper withBorder py="md" className="w-full">
            <Link to="/reports/activity">
              <Box px="md" className="w-full  ">
                <Group mb={7} position="apart">
                  <Text className="capitalize text-base font-semibold text-black">
                    time & attendance
                  </Text>
                  <AiOutlineStar size={20} />
                </Group>
                <Text className="text-sm text-fadedBlack-0 font-poppins font-normal">
                  See team members time worked, actitvity levels and amounts
                  earned per project or todo
                </Text>
              </Box>
            </Link>
            <Divider my={16} />
            <Link to="/reports/daily-total">
              <Box px="md" className="w-full  ">
                <Group mb={7} position="apart">
                  <Text className="capitalize text-base font-semibold text-black">
                    daily totals (weekly)
                  </Text>
                  <AiOutlineStar size={20} />
                </Group>
                <Text className="text-sm text-fadedBlack-0 font-poppins font-normal">
                  See team members time worked, actitvity levels and amounts
                  earned per project or todo
                </Text>
              </Box>
            </Link>
            <Divider my={16} />
            <Box px="md" className="w-full  ">
              <Group mb={7} position="apart">
                <Text className="capitalize text-base font-semibold text-black">
                  work session
                </Text>
                <AiOutlineStar size={20} />
              </Group>
              <Text className="text-sm text-fadedBlack-0 font-poppins font-normal">
                See team members time worked, actitvity levels and amounts
                earned per project or todo
              </Text>
            </Box>
            <Divider my={16} />
            <Link to="/reports/app-urls">
              <Box px="md" className="w-full  ">
                <Group mb={7} position="apart">
                  <Text className="capitalize text-base font-semibold text-black">
                    APP & URI
                  </Text>
                  <AiOutlineStar size={20} />
                </Group>
                <Text className="text-sm text-fadedBlack-0 font-poppins font-normal">
                  See team members time worked, actitvity levels and amounts
                  earned per project or todo
                </Text>
              </Box>
            </Link>
          </Paper>
        </Box>
      </Box>
    </main>
  );
}
