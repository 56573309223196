import { Paper, Text, Title, SimpleGrid, Group, Box } from "@mantine/core";
import React from "react";

const StandardUserOverview = () => {
  return (
    <Paper
      withBorder
      p="md"
      shadow="md"
      className=" bg-white max-w-[400px] w-full "
    >
      <Box className="mb-16">
        <Title mb={4} className="text-lg font-bold font-poppins text-black ">
          Overview
        </Title>
        <Group spacing={5} className="text-base">
          <Text className="text-black font-medium font-poppins text-sm">
            Branch:
          </Text>
          <Text className="font-normal text-black font-poppins text-sm">
            Abuja
          </Text>
        </Group>
      </Box>
      <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: "62rem", cols: 3, spacing: "md" },
          { maxWidth: "48rem", cols: 2, spacing: "sm" },
          { maxWidth: "36rem", cols: 1, spacing: "sm" },
        ]}
      >
        {[
          "departments",
          "employees",
          "admins",
          "credentials",
          "users",
          "devices",
        ].map((item, index) => (
          <Box key={index}>
            <Text className="text-base text-black font-semibold text-left ">
              4
            </Text>
            <Text className="text-sm text-black font-normal capitalize font-poppins ">
              {item}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    </Paper>
  );
};

export default StandardUserOverview;
