import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import { Fragment, useState } from "react";
import GlobalLockdown from "./GlobalLockdown";
import DeleteAttendanceModal from "./modal/DeleteAttendanceModal";
import { Menu, Transition } from "@headlessui/react";
import { BiDotsVerticalRounded } from "react-icons/bi";

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function OccupancyTab() {
  return (
    <div className="w-full px-2 sm:px-0 ">
      <Tab.Group>
        <Tab.List className="flex space-x-[62px] rounded-[5px] py-6 px-[57px] bg-white w-full">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full shadow rounded-[5px] py-[10px] bg-[#F7F8F9] text-lg capitalize font-semibold leading-6 outline-none ",
                "",
                selected
                  ? " text-black/[0.85] border-2 rounded-[5px] border-[#37A7AE] "
                  : "text-black/30  "
              )
            }
          >
            intrusion detection
          </Tab>
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full shadow rounded-[5px] py-[10px] bg-[#F7F8F9] text-lg capitalize font-semibold leading-6 outline-none ",
                "",
                selected
                  ? " text-black/[0.85] border-2 rounded-[5px] border-[#37A7AE] "
                  : "text-black/30  "
              )
            }
          >
            global lockdown
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-5">
          <Tab.Panel className={classNames("", "")}>
            {[1, 2, 3].map((item, index) => (
              <div
                key={index}
                className="relative w-full max-w-[823px] bg-white rounded-md p-4 mb-3"
              >
                <Link to={""}>
                  <p className="capitalize text-black text-xl font-bold mb-2.5">
                    september, 2022
                  </p>
                  <p className="text-sm text-black font-normal">
                    This contains the event logs for the month.
                  </p>
                </Link>
                <div className="absolute top-3 right-3">
                  <ActionModal />
                </div>
              </div>
            ))}
          </Tab.Panel>
          <Tab.Panel className={classNames("", "")}>
            <GlobalLockdown />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

function ActionModal() {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => setIsOpen(true);

  return (
    <div className="text-right z-10">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="btn-ghost hover:bg-transparent">
            <BiDotsVerticalRounded className="w-6 h-6 text-black" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-20 absolute right-0 mt-2 w-40 origin-top-right divide-y -translate-y-full divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-0.5 ">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-[#07939BCC] text-white" : "text-black/[0.85]"
                    } group flex w-full items-center rounded-md px-2 py-0.5 text-xs font-medium`}
                  >
                    Mail
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-[#07939BCC] text-white" : "text-black/[0.85]"
                    } group flex w-full items-center rounded-md px-2 py-0.5 text-xs font-medium`}
                  >
                    Save to device
                  </button>
                )}
              </Menu.Item>
            </div>

            <div className="px-1 py-1">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={openModal}
                    className={`${
                      active ? "bg-[#07939BCC] text-white" : "text-black/[0.85]"
                    } group flex w-full items-center rounded-md px-2 py-0.5 text-xs font-medium`}
                  >
                    Delete
                  </button>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <DeleteAttendanceModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </div>
  );
}
