import {
  Group,
  Avatar,
  Text,
  Accordion,
  Box,
  Title,
  Divider,
} from "@mantine/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";
import ReportActivity from "../../components/tables/ReportActivity";

export default function ActivityReport() {
  return (
    <main className="w-full">
      <Box className="w-full">
        <Link to="/reports">
          <Group spacing={4} mb={10}>
            <AiOutlineArrowLeft size={16} />
            <Text className="capitalize font-poppins text-lg">report</Text>
          </Group>
        </Link>
        <Title className="font-medium text-2xl font-poppins text-black mb-5 capitalize">
          time & activity
        </Title>
        <Box mb={29}></Box>
        <Box>
          <AccordionBox />
        </Box>
      </Box>
    </main>
  );
}

const charactersList = [
  {
    id: "bender",
    image: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
    label: "operations department",
    description: "27",
  },

  {
    id: "carol",
    image: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "human resources",
    description: "100",
  },

  {
    id: "homer",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: "information technology",
    description: "30",
  },
];

function AccordionLabel({ label, image, description }) {
  return (
    <Group noWrap spacing={50}>
      <div>
        <Text tt="capitalize">{label}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description > 1
            ? `${description} employees`
            : `${description} employee`}
        </Text>
      </div>
      <Avatar.Group spacing="sm">
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar color="cyan" radius="xl" w={42} h={42}>
          +5
        </Avatar>
      </Avatar.Group>
    </Group>
  );
}

function AccordionBox() {
  const items = charactersList.map((item, index) => (
    <Accordion.Item mb={20} value={item.id} key={item.label} maw={1090}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Box>
          <Text
            size="sm"
            color="dimmed"
            className="font-poppins text-sm capitalize text-black"
          >
            team task
          </Text>
          <Text
            size="sm"
            color="dimmed"
            className="font-poppins text-xs capitalize"
          >
            book branding system
          </Text>
        </Box>
        <Divider my={10} />
        <ReportActivity />
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Accordion chevronPosition="right" variant="contained">
      {items}
    </Accordion>
  );
}
