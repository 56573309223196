import { Box, Loader, Pagination, Text } from "@mantine/core";
import { useState, useEffect } from "react";
import BranchLocation from "../../../components/singleSelect/BranchLocation";
import useFetchPermissions, {
  useFetchPermissionsByBranchId,
} from "../../../hooks/useFetchPermissions";
import { paginate } from "../../../paginate";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function StandardUsers() {
  const { user } = useCurrentUser();
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const { permissions, error } = useFetchPermissions();
  const { branchPerms, fail } = useFetchPermissionsByBranchId(branchID);
  const [displayPerms, setDisplayPerms] = useState({
    data: [],
    dataError: {},
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(6);
  const standardUser = paginate(
    displayPerms && displayPerms.data,
    currentPage,
    pageSize
  );
  const [teamDetail, setTeamDetail] = useState({
    branchLocation: branchID,
  });

  useEffect(() => {
    // updates branchID
    if (teamDetail.branchLocation) {
      setBranchID(teamDetail.branchLocation);
    }
  }, [teamDetail.branchLocation]);

  useEffect(() => {
    // checks if a branchID exist, fetches users with permission in that branch
    if (branchID) {
      setDisplayPerms((displayPerms) => ({
        ...displayPerms,
        data: branchPerms && branchPerms.userRole,
        dataError: fail,
      }));
    } else {
      setDisplayPerms((displayPerms) => ({
        ...displayPerms,
        data: permissions && permissions.userRole,
        dataError: error,
      }));
    }
  }, [branchID, branchPerms, error, fail, permissions]);

  const totalPages = Math.ceil(
    (displayPerms && displayPerms.data && displayPerms.data.length) / pageSize
  );
  return (
    <Box className="w-full 3xl:max-w-7xl h-full  ">
      <Box className="relative h-full ">
        <Box className="flex w-full items-center justify-between mb-8 ">
          <Text className="normal-case text-xl font-medium text-black ">
            Standard Users
          </Text>
          {user?.user_type === "super_admin" && (
            <Box className="w-60">
              <BranchLocation details={teamDetail} setMethod={setTeamDetail} />
            </Box>
          )}
        </Box>
        {/* {displayPerms.dataError && (
          <Box className="w-full h-40 flex items-center justify-center space-x-3">
            <Loader variant="dots" color="red" size="lg" />
            <Text className="text-base text-black">oops!, try again.</Text>
          </Box>
        )} */}
        {!displayPerms.data && !displayPerms.dataError && (
          <Box className="w-full h-40 flex justify-center items-center">
            <Loader variant="oval" color="cyan" size="lg" />
          </Box>
        )}
        {displayPerms.data && displayPerms.data.length === 0 && (
          <Box className="w-full h-40 flex items-center justify-center">
            <Text className="text-base text-black uppercase">
              no standard users yet!
            </Text>
          </Box>
        )}
        {standardUser &&
          standardUser.map((permission) => (
            <div
              key={permission.id}
              className="border border-black w-full h-14 max-w-2xl bg-white text-black flex justify-start items-center rounded-md px-6 mb-4 "
            >
              <div className="bg-red-800 rounded-full flex items-center justify-center w-10 h-10 mr-8">
                {permission.avatar}
              </div>
              <div className="flex-1 text-left">
                <p className="font-medium text-base capitalize ">
                  {permission.first_name} {permission.last_name}
                </p>
                <p className="capitalize font-normal text-sm ">
                  {permission.role && "Standard User"}
                </p>
              </div>
            </div>
          ))}
        <Box className=" my-10 "></Box>
        <Box className="w-full absolute bottom-10 ">
          <Pagination
            color="cyan"
            position="right"
            page={currentPage}
            onChange={setCurrentPage}
            total={totalPages ? totalPages : 0}
            size="lg"
          />
        </Box>
      </Box>
    </Box>
  );
}
