import { Box, Button, Select } from "@mantine/core";
import UtilityHeader from "../../components/headers/UtilityHeader";
import { useRef } from "react";
import IntercomTable from "../../components/tables/IntercomTable";

export default function Intercom() {
  const hardwareRef = useRef(null);
  const employeeRef = useRef(null);
  return (
    <main className="w-full h-full">
      <Box className="w-full h-full">
        <Box mb={30}>
          <UtilityHeader
            title={"assign intercom"}
            subtitle={""}
            backLink={""}
          />
        </Box>
        <Box className="w-full mb-10">
          <Box className="w-full flex flex-col space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0 mb-4">
            <Select
              ref={employeeRef}
              className="w-full max-w-[300px]"
              placeholder="Select employee"
              label="Employee"
              size="lg"
              searchable
              nothingFound="no employees"
              clearable
              data={[]}
              styles={(theme) => ({
                item: {
                  fontSize: 14,
                  paddingTop: 8,
                  paddingBottom: 8,
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: theme.colors.luchiPrimary[0],
                      color: theme.white,
                    },
                  },
                  fontFamily: theme.fontFamily.poppins[0],
                  "&[data-hovered]": {},
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                },
              })}
            />
            <Select
              ref={hardwareRef}
              className="w-full max-w-[300px]"
              placeholder="Select hardware"
              label="Hardware"
              size="lg"
              searchable
              nothingFound="no hardwares"
              clearable
              data={[]}
              styles={(theme) => ({
                item: {
                  fontSize: 14,
                  paddingTop: 8,
                  paddingBottom: 8,
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: theme.colors.luchiPrimary[0],
                      color: theme.white,
                    },
                  },
                  fontFamily: theme.fontFamily.poppins[0],
                  "&[data-hovered]": {},
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                },
              })}
              // {...form.getInputProps("deptID")}
            />
          </Box>
          <Button
            variant="white"
            className="font-medium capitalize bg-luchiPrimary text-white w-full max-w-[300px]"
          >
            assign
          </Button>
        </Box>

        <Box className="w-full">
          <IntercomTable permissionsData={[]} setDetails={{}} details={[]} />
        </Box>
      </Box>
    </main>
  );
}
