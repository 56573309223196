import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  Title,
  Text,
  SimpleGrid,
  Group,
  Loader,
  ActionIcon,
} from "@mantine/core";
import useFetchBranches from "../../../../hooks/useFetchBranches";
import useSWRMutation from "swr/mutation";
import { getBranchDetailsTrigger } from "../../../../services/requests";
import { Link } from "react-router-dom";

const SuperAdminOverviewCard = () => {
  const [display, setDisplay] = useState([]);
  const { branches } = useFetchBranches();
  const { trigger, error } = useSWRMutation(
    "/api/branch-info",
    getBranchDetailsTrigger
  );

  useEffect(() => {
    const updateDisplay = async () => {
      const newDisplay = [];

      for (let index = 0; index < 4 && index < branches?.length; index++) {
        const branch = branches[index];
        const res = await trigger(branch);
        const newRes = {
          id: branch.id,
          branchName: branch.name,
          departments: res.departments,
          employees: res.employees,
          admins: res.admins,
          standard_users: res.standard_users,
          credentials: res.credentials,
        };
        newDisplay.push(newRes);
      }

      setDisplay(newDisplay);
    };
    updateDisplay();
  }, [branches, trigger]);

  return (
    <Paper withBorder p="md" shadow="md" className="w-full max-w-sm bg-white  ">
      <Group position="apart" className="mb-4">
        <Title mb={4} className="text-xl font-bold font-poppins text-black ">
          Overview
        </Title>
        <Link to="/organization-setup">
          <ActionIcon variant="transparent" className="w-fit text-yellow-600">
            see more
          </ActionIcon>
        </Link>
      </Group>
      {error && (
        <Box className="w-full h-24 flex items-center justify-center">
          <Loader color="red" variant="dots" />
        </Box>
      )}
      {display?.length === 0 && (
        <Box className="w-full h-40 flex items-center justify-center">
          <Text className="text-base text-black uppercase">
            no records yet!
          </Text>
        </Box>
      )}
      <SimpleGrid
        cols={2}
        spacing="lg"
        breakpoints={[
          { maxWidth: "62rem", cols: 2, spacing: "md" },
          { maxWidth: "48rem", cols: 2, spacing: "sm" },
          { maxWidth: "36rem", cols: 2, spacing: "sm" },
        ]}
      >
        {display?.map((item) => (
          <Box key={item.id} className="w-full ">
            <Text className="text-base text-black font-semibold text-left capitalize ">
              {item.branchName}
            </Text>
            <Box className="w-full h-full ">
              <Group spacing={6}>
                <Text className="text-sm text-black font-normal capitalize font-poppins ">
                  depts:
                </Text>
                <Text className="font-display text-sm ">
                  {item.departments}
                </Text>
              </Group>
              <Group spacing={6}>
                <Text className="text-sm text-black font-normal capitalize font-poppins ">
                  emplys:
                </Text>
                <Text className="font-display text-sm ">{item.employees}</Text>
              </Group>
              <Group spacing={6}>
                <Text className="text-sm text-black font-normal capitalize font-poppins ">
                  admins:
                </Text>
                <Text className="font-display text-sm ">{item.admins}</Text>
              </Group>
              <Group spacing={6}>
                <Text className="text-sm text-black font-normal capitalize font-poppins ">
                  std users:
                </Text>
                <Text className="font-display text-sm ">
                  {item.standard_users}
                </Text>
              </Group>
              <Group spacing={6}>
                <Text className="text-sm text-black font-normal capitalize font-poppins ">
                  creds:
                </Text>
                <Text className="font-display text-sm ">
                  {item.credentials}
                </Text>
              </Group>
            </Box>
          </Box>
        ))}
      </SimpleGrid>
    </Paper>
  );
};

export default SuperAdminOverviewCard;
