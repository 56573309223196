import React, { useState } from "react";
import {
  HoverCard,
  Box,
  Text,
  Button,
  SimpleGrid,
  Loader,
  Group,
  UnstyledButton,
  ActionIcon,
  Pagination,
} from "@mantine/core";
import HeaderBar from "../../../components/headers/HeaderBar";
import { archivePosition, delPosition } from "../../../services/requests";
import {
  failedNotify,
  handleEdit,
  successNotify,
} from "../../../services/functions";
import useFetchPositions from "../../../hooks/useFetchPositions";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { DeleteConfirmationModal } from "../../../components/modal/ActionMenu";
import { useDisclosure } from "@mantine/hooks";
import { mutate } from "swr";
import { useContext } from "react";
import useCustomPaginate from "../../../hooks/useCustomPagination";
import { TempValueContext } from "../../../context/TempValueContext";
import CreatePosition from "../../../components/modal/CreatePosition";

export default function Organogram() {
  const [allPositions, isLoading, error] = useFetchPositions();
  const [opened, { open, close }] = useDisclosure(false);
  const [openedCreate, { open: openCreate, close: closeCreate }] =
    useDisclosure(false);
  const [delLoader, setDelLoader] = useState(false);
  const [positionId, setPositionId] = useState("");
  const { setTempValue } = useContext(TempValueContext);

  const { currentPage, setCurrentPage, paginatedData, totalPages } =
    useCustomPaginate(allPositions && allPositions, 20);

  async function handleDelete() {
    setDelLoader(true);
    const archiveRes = await archivePosition(positionId);
    if (archiveRes.status) {
      const delRes = await delPosition(positionId);
      if (delRes.status) {
        successNotify("Delete status", delRes.message);
        mutate("api/all-positions");
        close();
      } else {
        failedNotify("delete status", delRes.response.data.message);
      }
    } else failedNotify("delete status", archiveRes.response.data.message);
    setDelLoader(false);
  }

  return (
    <div className="w-full max-w-7xl relative h-full pb-16 md:pb-0">
      <Box className="w-full mb-4 flex items-center ">
        <HeaderBar
          title="organogram"
          data={[]}
          setData={{}}
          user={{}}
          show={false}
        />
      </Box>
      <Box my={30} className="w-full">
        <Button
          onClick={openCreate}
          variant="white"
          w={280}
          className="bg-yellow-700 capitalize font-medium text-white"
        >
          add position
        </Button>
      </Box>
      <Box className="w-full ">
        {error && (
          <Box className="w-full h-96 flex items-center justify-center space-x-3">
            <Loader variant="dots" color="red" size="lg" />
            <Text className="text-base text-black">oops!, try again.</Text>
          </Box>
        )}
        {isLoading && (
          <Box className="w-full h-96 flex justify-center items-center">
            <Loader variant="oval" color="cyan" size="lg" />
          </Box>
        )}
        {paginatedData.length === 0 && (
          <Box className="w-full h-96 flex items-center justify-center">
            <Text className="text-base text-black uppercase">
              no positions yet!
            </Text>
          </Box>
        )}
        {paginatedData && paginatedData.length !== 0 && !error && (
          <SimpleGrid
            cols={4}
            spacing="sm"
            breakpoints={[
              { maxWidth: "62rem", cols: 3, spacing: "sm" },
              { maxWidth: "48rem", cols: 2, spacing: "sm" },
              { maxWidth: "36rem", cols: 1, spacing: "sm" },
            ]}
          >
            {paginatedData &&
              paginatedData.map((position, index) => (
                <HoverCard
                  withinPortal={true}
                  key={index}
                  width={120}
                  shadow="md"
                  offset={-45}
                  height={50}
                >
                  <HoverCard.Target>
                    <UnstyledButton
                      key={index}
                      radius={5}
                      h={56}
                      p="xs"
                      className={`${
                        index % 2 !== 0
                          ? " bg-yellow-700 "
                          : " bg-luchiPrimary "
                      } text-white font-medium `}
                    >
                      <Group>
                        <div>
                          <Text
                            truncate
                            className="text-sm capitalize font-medium "
                          >
                            {position.label}
                          </Text>
                          <Text
                            lineClamp={2}
                            className="text-xs normal-case font-normal"
                          >
                            {position.description}
                          </Text>
                        </div>
                      </Group>
                    </UnstyledButton>
                  </HoverCard.Target>
                  <HoverCard.Dropdown className="bg-white/80 p-0 h-8">
                    <Group position="center">
                      <ActionIcon
                        onClick={() => {
                          handleEdit(setTempValue, "", "", position);
                          openCreate();
                        }}
                        variant="transparent"
                      >
                        <AiFillEdit size={20} color="green" />
                      </ActionIcon>
                      <ActionIcon
                        onClick={() => {
                          open();
                          setPositionId(position.value);
                        }}
                        variant="transparent"
                      >
                        <AiFillDelete size={20} color="red" />
                      </ActionIcon>
                    </Group>
                  </HoverCard.Dropdown>
                </HoverCard>
              ))}
          </SimpleGrid>
        )}
      </Box>
      <DeleteConfirmationModal
        opened={opened}
        close={close}
        handleDelete={handleDelete}
        entity="position?"
        btnLoader={delLoader}
      />
      <CreatePosition open={openedCreate} close={closeCreate} />
      <Box className="w-full absolute bottom-2 ">
        <Pagination
          color="cyan"
          position="right"
          value={currentPage}
          onChange={setCurrentPage}
          total={totalPages ? totalPages : 0}
          size="lg"
        />
      </Box>
    </div>
  );
}
