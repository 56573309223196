import { Link } from "react-router-dom";
import { FaAngleLeft } from "react-icons/fa";
import { Title } from "@mantine/core";

export default function UtilityHeader({ title, subtitle = "", backLink }) {
  return (
    <div className="w-full ">
      <div className="w-full flex justify-between items-center ">
        <Title className="capitalize font-bold font-poppins text-black text-2xl leading-7  ">
          {title}
        </Title>
      </div>
      {subtitle && (
        <div className="flex space-x-2 items-center ">
          <div className=" text-black">
            <p className="flex items-center ">
              {subtitle} <FaAngleLeft className=" w-6 h-6 " />
            </p>
          </div>
          <Link
            to={backLink}
            className="self-end btn btn-active btn-link hover:bg-transparent border-none text-[#07939BCC] text-xs capitalize"
          >
            back
          </Link>
        </div>
      )}
    </div>
  );
}
