import { Select } from "@mantine/core";

export default function RoleSelectBox({ form }) {
  return (
    <Select
      label="Role"
      placeholder="Pick role"
      allowDeselect
      size="lg"
      nothingFound="No details"
      data={["Mid-level Admin", "Standard User"]}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },

          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
      {...form.getInputProps("role")}
    />
  );
}
