import { useState } from "react";
import { createPassword } from "../services/requests";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useForm } from "@mantine/form";
import {
  Box,
  Button,
  Paper,
  PasswordInput,
  Popover,
  Progress,
  Text,
  Title,
} from "@mantine/core";
import {
  failedNotify,
  getStrength,
  requirements,
  successNotify,
} from "../services/functions";
import { BsCheck } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import { useEffect } from "react";
import Cookies from "js-cookie";

export default function ChangePassword() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [queryParameters] = useSearchParams();
  const [popoverOpened, setPopoverOpened] = useState(false);
  const token = Cookies.get("userToken");

  // Redirect authenticated users
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  });
  const form = useForm({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },

    validate: {
      newPassword: (value) => {
        const newCheck = checks
          .map((check) => check.props.meets)
          .every(Boolean);
        return newCheck ? null : "Invalid password";
      },
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords did not match" : null,
    },
  });

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.getInputProps("newPassword").value)}
    />
  ));
  const strength = getStrength(form.getInputProps("newPassword").value);
  const color = strength === 100 ? "teal" : strength > 50 ? "yellow" : "red";

  async function updatePassword(values, event) {
    event.preventDefault();
    setLoader(true);
    const details = {
      password: values.newPassword,
      password_confirmation: values.confirmPassword,
    };

    const res = await createPassword(
      details,
      queryParameters.get("employee"),
      queryParameters.get("expires"),
      queryParameters.get("signature")
    );
    if (res.status) {
      successNotify("Notification", res.message);
      form.reset();
      setTimeout(() => navigate("/signin", { replace: true }), 1500);
    } else {
      failedNotify("Notification", "Password change was unsuccessful");
    }
    setLoader(false);
  }
  return (
    <main className="w-full bg-white h-screen flex items-center justify-center">
      <Paper
        withBorder
        radius={8}
        p={0}
        className="w-full max-w-md shadow-lg p-6 h-96  "
      >
        <Title className="text-2xl font-semibold capitalize text-luchiPrimary">
          reset password
        </Title>
        <form
          onSubmit={form.onSubmit((values, e) => updatePassword(values, e))}
          className="w-full mt-4 "
        >
          <Popover
            opened={popoverOpened}
            position="bottom"
            width="target"
            transition="pop"
          >
            <Popover.Target>
              <div
                onFocusCapture={() => setPopoverOpened(true)}
                onBlurCapture={() => setPopoverOpened(false)}
              >
                <PasswordInput
                  withAsterisk
                  label="New Password"
                  size="lg"
                  radius={4}
                  styles={(theme) => ({
                    label: {
                      paddingBottom: 4,
                      fontSize: 16,
                      marginTop: 16,
                      fontWeight: 400,
                    },
                    input: {
                      fontSize: 14,
                      borderWidth: 1,
                      "&:focus": {
                        borderColor: theme.colors.luchiPrimary[0],
                      },
                    },
                  })}
                  {...form.getInputProps("newPassword")}
                />
              </div>
            </Popover.Target>
            <Popover.Dropdown>
              <Progress
                color={color}
                value={strength}
                size={5}
                style={{ marginBottom: 10 }}
              />
              <PasswordRequirement
                label="Includes at least 8 characters"
                meets={form.getInputProps("newPassword").value.length > 7}
              />
              {checks}
            </Popover.Dropdown>
          </Popover>

          <PasswordInput
            withAsterisk
            label="Confirm Password"
            size="lg"
            radius={4}
            styles={(theme) => ({
              label: {
                paddingBottom: 4,
                fontSize: 16,
                marginTop: 16,
                fontWeight: 400,
              },
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
            })}
            {...form.getInputProps("confirmPassword")}
          />

          <Button
            mt={16}
            type="submit"
            variant="white"
            fullWidth
            loading={loader}
            loaderPosition="right"
            className="bg-luchiPrimary capitalize font-medium text-white"
          >
            submit
          </Button>
        </form>
      </Paper>
    </main>
  );
}

function PasswordRequirement({ meets, label }) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <BsCheck size={14} /> : <MdOutlineCancel size={14} />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}
