import useSWR from "swr";
import {
  getBranchDetailsFetcher,
  getBrannchesFetcher,
} from "../services/requests";
import { useMemo } from "react";

export default function useFetchBranches() {
  const {
    data: branches,
    isLoading,
    error,
  } = useSWR("api/all-branches", getBrannchesFetcher);

  const branchLocations = useMemo(() => {
    const selectData = branches?.map((item) => {
      const val = {
        value: item.id,
        label: item.address,
      };
      return val;
    });
    if (selectData && selectData.length) return selectData;
    else return [];
  }, [branches]);

  return { branchLocations, branches, isLoading, error };
}

export function useFetchBranchDetails(id) {
  const { data, isLoading, error } = useSWR(
    id ? id : null,
    getBranchDetailsFetcher
  );
  return { data, isLoading, error };
}
