import { ActionIcon, Box, Title } from "@mantine/core";
import BranchLocation from "../singleSelect/BranchLocation";
import { BiUndo } from "react-icons/bi";

export default function HeaderBar({ title, setData, user, show }) {
  return (
    <Box className="w-full ">
      <Box className="w-full flex justify-between items-center space-x-4 ">
        <Title className="capitalize font-bold text-black text-2xl  ">
          {title}
        </Title>
        {user?.user_type === "super_admin" && show && (
          <Box className="w-60 flex items-center space-x-2">
            <ActionIcon
              title="clear"
              variant="transparent"
              className="hover:text-luchiPrimary"
              onClick={() => {
                setData((details) => ({
                  ...details,
                  branchLocation: "",
                }));
              }}
            >
              <BiUndo />
            </ActionIcon>
            <BranchLocation setMethod={setData} />
          </Box>
        )}
      </Box>
    </Box>
  );
}
