import { useState, useEffect } from "react";
import HeaderBar from "../../../components/headers/HeaderBar";
import {
  Box,
  Paper,
  Text,
  Title,
  SimpleGrid,
  Group,
  Checkbox,
  Loader,
  AspectRatio,
} from "@mantine/core";
import EventsMenuActions from "../../../components/menu/EventsMenuActions";
import { useDisclosure } from "@mantine/hooks";
import { EventLogDelete } from "./monthly-logs";
import ViewEvent from "../../../components/modal/ViewEvent";
import BackButton from "../../../components/BackButton";
import { useParams } from "react-router-dom";
import { useFetchEventsByDate } from "../../../hooks/useFetchEvents";
import { AiFillEye } from "react-icons/ai";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function VideoEventDayLogs() {
  const { user } = useCurrentUser();
  const [opened, { open, close }] = useDisclosure(false);
  const [openView, { open: view, close: closeView }] = useDisclosure(false);
  const [value, setValue] = useState({});
  const { day } = useParams();
  const { data, isLoading, error } = useFetchEventsByDate(day && day);
  const [eventDetails, setEventDetails] = useState([]);
  // const [branchID, setBranchID] = useState(
  //   user.user_type !== "super_admin" ? user.branch.id : ""
  // );
  // const [branch, setBranch] = useState({
  //   branchLocation: branchID,
  // });

  useEffect(() => {
    if (data) {
      const result = {};
      data &&
        data.forEach((item) => {
          const deviceName = item.device.name;
          if (!result[deviceName]) {
            result[deviceName] = [];
          }
          if (item.type.toLowerCase() !== "data") result[deviceName].push(item);
        });
      setValue(result);
    }
  }, [data]);

  return (
    <div className="w-full max-w-7xl ">
      <div className="w-full z-10">
        <HeaderBar
          title="Video Events"
          data={[]}
          setData={{}}
          user={user}
          show={false}
        />
        {/* <Box>
          <Text className="text-sm">26th, September 2023</Text>
        </Box> */}
      </div>
      <Box>
        <BackButton />
      </Box>

      {isLoading && (
        <Box className="w-full h-96 flex justify-center items-center  ">
          <Loader variant="oval" color="cyan" />
        </Box>
      )}
      {error && (
        <Box className="w-full h-96 flex justify-center items-center ">
          <Loader variant="dots" color="red" />
          <Text ml={2}>Error!</Text>
        </Box>
      )}
      {value?.length === 0 ? (
        <Box className="w-full h-96 items-center flex justify-center ">
          <Text className="text-sm uppercase ">no video events yet!</Text>
        </Box>
      ) : (
        Object.keys(value).map((deviceName, index) => (
          <Paper
            key={deviceName + index}
            p="sm"
            radius={8}
            className="w-full mt-4 bg-offWhite max-w-[1096px] "
          >
            <Group position="apart" mb={26}>
              <Title className="text-lg capitalize font-poppins font-semibold">
                {deviceName}
              </Title>
              <Box className="flex items-center space-x-6">
                <Checkbox color="cyan" label="Select All" />
                <EventsMenuActions openDelModal={open} />
              </Box>
            </Group>
            <SimpleGrid
              cols={4}
              spacing="lg"
              breakpoints={[
                { maxWidth: "62rem", cols: 3, spacing: "md" },
                { maxWidth: "48rem", cols: 2, spacing: "sm" },
                { maxWidth: "36rem", cols: 1, spacing: "sm" },
              ]}
            >
              {value &&
                value[deviceName].map((item) => (
                  <Box
                    key={item.id}
                    className="relative cursor-pointer w-full max-w-[224px]"
                  >
                    <Box className="h-full max-h-[185px] w-full relative">
                      <AspectRatio ratio={16 / 9}>
                        <iframe
                          src="https://www.youtube.com/embed/Dorf8i6lCuk"
                          title={`${item.event_name} video`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                        />
                      </AspectRatio>
                    </Box>
                    <Box
                      onClick={() => {
                        view();
                        setEventDetails(value[deviceName]);
                      }}
                      className="w-fit h-fit absolute top-1.5 bg-luchiPrimary right-2 rounded-md"
                    >
                      <AiFillEye
                        size={24}
                        className="text-white"
                        title="view details"
                      />
                    </Box>
                    {/* <Checkbox.Group value={value} onChange={setValue}> */}
                    <Checkbox
                      onClick={(e) => e.stopPropagation()}
                      color="cyan"
                      className="absolute top-2 left-2"
                    />
                    {/* </Checkbox.Group> */}
                  </Box>
                ))}
            </SimpleGrid>
          </Paper>
        ))
      )}
      <EventLogDelete opened={opened} close={close} />
      <ViewEvent opened={openView} close={closeView} eventData={eventDetails} />
    </div>
  );
}
