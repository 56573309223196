import useSWR from "swr";
import { getAllPositionsFetcher } from "../services/requests";
import { useEffect, useState } from "react";

export default function useFetchPositions() {
  const [allPositions, setAllPositions] = useState([]);

  const { data, isLoading, error } = useSWR(
    "api/all-positions",
    getAllPositionsFetcher
  );

  useEffect(() => {
    if (data && data.length) {
      const positions = data.map((datum) => {
        const info = {
          value: datum.id,
          label: datum.name,
          description: datum.description,
          organizationid: datum.organization_id,
          branchid: datum.branch_id,
        };
        return info;
      });
      setAllPositions([...positions]);
    }
    return;
  }, [data]);

  return [allPositions, isLoading, error];
}
