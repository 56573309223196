import { Chart } from "react-google-charts";

export const data = [
  ["Week", "Fingerprint", "RFID Card", "Mobile App", "PIN"],
  ["1", 1000, 400, 200, 300],
  ["8", 1170, 460, 250, 600],
  ["16", 660, 1120, 300, 200],
  ["24", 1030, 540, 350, 280],
];

export const options = {
  bar: { groupWidth: "90%" },
  legend: { position: "none" },
};

export default function StandardUserBarChart() {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="300px"
      data={data}
      options={options}
    />
  );
}
