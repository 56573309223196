import { Box, Group, Text, RingProgress } from "@mantine/core";
import UtilityHeader from "../../components/headers/UtilityHeader";
import { useFetchCapacity } from "../../hooks/team/hooks";

export default function Capacity() {
  const { capacityUsers, currentCapacity } = useFetchCapacity();

  const radialValue = currentCapacity?.percentage;
  return (
    <main className="relative w-full max-w-7xl ">
      <UtilityHeader title={"capacity management"} />
      <Box className="w-full max-w-[977px] mt-5">
        <Group position="center" mb={15}>
          <Text className=" text-xl leading-9 text-center text-black font-semibold px-5 capitalize  ">
            users currently present
          </Text>
        </Group>
        <Box className="flex justify-between items-center">
          <Box>
            <RingProgress
              size={300}
              thickness={22}
              sections={[{ value: radialValue, color: "cyan" }]}
              label={
                <Text color="cyan" weight={700} align="center" size="xl">
                  {radialValue + "%"}
                </Text>
              }
            />
            <Text className="text-2xl font-semibold text-black text-center">
              Current Occupancy
            </Text>
          </Box>
          <Box className=" ">
            <Text className="text-black text-center font-semibold text-[50px]  ">
              {capacityUsers?.count}
            </Text>
            <Text className="text-[26px] font-semibold text-black  ">
              {capacityUsers?.count !== 1 ? " Employees " : " Employee "}
            </Text>
          </Box>
        </Box>
      </Box>
    </main>
  );
}
