export const scheduleAlarmDetails = [
  {
    id: 1,
    title: "category",
    options: ["break time", "closing time", "holidays", "outdoor activities"],
  },
  {
    id: 2,
    title: "department",
    options: [
      "operations department",
      "marketing department",
      "human resources department",
      "technology department",
    ],
  },
  {
    id: 3,
    title: "days",
    options: [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ],
  },
];

export const helpSupportDetails = [
  {
    id: 1,
    title: "add device",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 2,
    title: "payments",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 3,
    title: "plans",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 4,
    title: "organogram",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 5,
    title: "integrations",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 6,
    title: "reports",
    content: ["transfer", "pending", "error"],
  },
  {
    id: 7,
    title: "device support",
    content: ["transfer", "pending", "error"],
  },
];

export const reportData = [
  {
    id: 1,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    duration: "3hrs 30mins",
    level: 96,
    start: "08:30 AM",
    end: "08:31 AM",
    break: "1hr",
  },
  {
    id: 2,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    duration: "3hrs 30mins",
    level: 76,
    start: "08:30 AM",
    end: "08:31 AM",
    break: "1hr",
  },
  {
    id: 3,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    duration: "3hrs 30mins",
    level: 80,
    start: "08:30 AM",
    end: "08:31 AM",
    break: "1hr",
  },
  {
    id: 4,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    duration: "3hrs 30mins",
    level: 45,
    start: "08:30 AM",
    end: "08:31 AM",
    break: "1hr",
  },
];

export const dailyData = [
  {
    id: 1,
    firstName: "William",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    monday: 50,
    tuesday: 78,
    wednesday: 34,
    thursday: 45,
    friday: 83,
    average: 34,
    time: "1hr, 30mins",
  },
  {
    id: 2,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Graphic Designer",
    task: "Design book cover",
    monday: 50,
    tuesday: 78,
    wednesday: 34,
    thursday: 45,
    friday: 83,
    average: 76,
    time: "1hr, 30mins",
  },
  {
    id: 3,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "Marketer",
    task: "Design book cover",
    monday: 50,
    tuesday: 78,
    wednesday: 34,
    thursday: 45,
    friday: 83,
    average: 32,
    time: "1hr, 30mins",
  },
  {
    id: 4,
    firstName: "Ikenne",
    lastName: "Okon",
    position: "UI/UX",
    task: "Design book cover",
    monday: 50,
    tuesday: 78,
    wednesday: 34,
    thursday: 45,
    friday: 83,
    average: 21,
    time: "1hr, 30mins",
  },
];

export const subscriptionDetails = [
  {
    id: 1,
    type: "basic",
    details: [
      "One branch",
      "Luchismart Mobile App",
      "Video intercom",
      "Access control",
      "Workflow management",
      "Compliance",
      "Maximum of 5 staff",
    ],
    price: 5,
    action: "purchase",
  },
  {
    id: 2,
    type: "standard",
    details: [
      "Multi branch",
      "Luchismart Mobile App",
      "Video intercom",
      "Access control",
      "Workflow management",
      "Compliance",
      "Maximum of 100 staff",
    ],
    price: 10,
    action: "upgrade",
  },
  {
    id: 3,
    type: "enterprise",
    details: [
      "Multi branch",
      "Luchismart Mobile App",
      "Video intercom",
      "Access control",
      "Workflow management",
      "Compliance",
      "Unlimited staff",
      "Time and attendance system",
    ],
    price: 20,
    action: "upgrade",
  },
];
