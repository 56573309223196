import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import { Button } from "@mantine/core";

export default function BackButton() {
  const navigate = useNavigate();
  return (
    <Button
      onClick={() => navigate(-1)}
      variant="white"
      className="bg-transparent text-luchiPrimary p-0 capitalize"
      leftIcon={<BiArrowBack />}
    >
      back
    </Button>
  );
}
