import React from "react";
import { payHistory } from "../../../../src/assets/data/payHistory";
import UtilityHeader from "../../../components/headers/UtilityHeader";
import AttendanceMenuModal from "../../../components/modal/AttendanceMenuModal";

const PaymentHistory = () => {
  return (
    <div className="w-full pr-4 max-w-7xl pb-52  ">
      <div className="w-full mb-20 ">
        <UtilityHeader title={"payment history"} subtitle={""} backLink={""} />
      </div>
      {/* show when no purchase */}
      <div className="text-black w-full text-center mt-40 hidden">
        <p className="font-medium">You haven’t made any purchase</p>
        <p>Once you make a payment, you will see your payement history here.</p>
      </div>
      <div className="bg-white p-6 rounded-md relative ">
        <div className="absolute top-3 right-3">
          <AttendanceMenuModal noDel="yes" />
        </div>

        <div className="overflow-x-auto w-full">
          <table className="table w-full">
            <thead>
              <tr>
                <th>
                  <label>
                    <input
                      type="checkbox"
                      className="checkbox checkbox-accent"
                    />
                  </label>
                </th>
                <th>date</th>
                <th>payment</th>
                <th>mode</th>
                <th>amount</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              {payHistory.map((item) => (
                <tr key={item.id}>
                  <th>
                    <label>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-accent"
                      />
                    </label>
                  </th>
                  <td>
                    <div className="flex items-center space-x-3">
                      {item.date}
                    </div>
                  </td>
                  <td>{item.payment}</td>
                  <td>{item.mode}</td>
                  <th>{item.amount} </th>
                  <th>
                    <div
                      className={`${
                        item.status !== "paid"
                          ? "text-red-800"
                          : "text-luchiPrimary"
                      }`}
                    >
                      {item.status}
                    </div>
                  </th>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
