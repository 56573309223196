import { useState } from "react";
import {
  AppShell,
  Navbar,
  Header,
  Menu,
  MediaQuery,
  Burger,
  useMantineTheme,
  ScrollArea,
  createStyles,
  Box,
  Avatar,
  Image,
  Group,
  NavLink,
  Text,
  Paper,
  Button,
  Indicator,
  ActionIcon,
} from "@mantine/core";
import { AiOutlineSetting } from "react-icons/ai";
import { IoIosClose } from "react-icons/io";
import { BiLogOut } from "react-icons/bi";
import { BsBell } from "react-icons/bs";
import logo from "../../assets/logo.svg";
import {
  dashboardMenuItems,
  employeeDashboardMenu,
  midLevelMenuItems,
  standardDashboardMenu,
} from "../Users/Standarduser/navbar/sideBarData";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useContext } from "react";
import { UserTypeContext } from "../../App";
import { useCurrentUser } from "../../hooks/useCurrentUser";
import { logoutOfApp } from "../../services/functions";

export default function DashboardLayout() {
  const theme = useMantineTheme();
  const [opened, setOpened] = useState(false);
  const [active, setActive] = useState(0);
  const { classes } = useStyles();
  const router = useLocation();
  const { user } = useCurrentUser();
  const typeofUser = useContext(UserTypeContext).userType;
  const dynamicPath = router.pathname.split("/")[2];
  const [navbarItems, setNavbarItems] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    switch (router.pathname) {
      case "/":
        setActive(1);
        break;
      case "/dashboard":
        setActive(1);
        break;
      case "/user":
        setActive(2);
        break;
      case "/user/standard-users":
        setActive(21);
        break;
      case `/user/${dynamicPath}`:
        setActive(2);
        break;
      case "/team":
        setActive(3);
        break;
      case "/utility-management":
        setActive(4);
        break;
      case "/utility-management/time-attendance":
        setActive(5);
        break;
      case "/advanced-access/capacity":
        setActive(6);
        break;
      case "/utility-management/productivity-management":
        setActive(7);
        break;
      case "/advanced-access/occupancy":
        setActive(8);
        break;
      case "/advanced-access/intercom":
        setActive(22);
        break;
      case "/help":
        setActive(9);
        break;
      case "/organization-setup":
        setActive(10);
        break;
      case "/organization-setup/credentials":
        setActive(11);
        break;
      case "/organization-setup/billings":
        setActive(12);
        break;
      case "/organization-setup/hardwares":
        setActive(13);
        break;
      case "/organization-setup/permissions":
        setActive(14);
        break;
      case "/organization-setup/payment-history":
        setActive(15);
        break;
      case "/organization-setup/organogram":
        setActive(16);
        break;
      case "/organization-setup/settings":
        setActive(17);
        break;
      case "/event":
        setActive(18);
        break;
      case `event/monthly-logs/data/:any`:
        setActive(18);
        break;
      case "/integration":
        setActive(19);
        break;
      case "/reports":
        setActive(20);
        break;
      case "/reports/activity":
        setActive(20);
        break;
      case "/reports/daily-totals":
        setActive(20);
        break;
      case "/reports/work-sessions":
        setActive(20);
        break;
      case "/reports/app-uri":
        setActive(20);
        break;

      default:
        break;
    }
  }, [dynamicPath, router.pathname]);

  useEffect(() => {
    // selects which dashboard menu items to display
    switch (typeofUser) {
      case "employee":
        setNavbarItems(employeeDashboardMenu);
        break;
      case "super_admin":
        setNavbarItems(dashboardMenuItems);
        break;
      case "mid_level_admin":
        setNavbarItems(midLevelMenuItems);
        break;
      case "standard_user":
        setNavbarItems(standardDashboardMenu);
        break;

      default:
        break;
    }
  }, [typeofUser]);

  return (
    <AppShell
      layout="alt"
      navbarOffsetBreakpoint="sm"
      styles={{
        main: {
          background: "white",
        },
      }}
      navbar={
        <Navbar
          p="xs"
          hiddenBreakpoint="sm"
          hidden={!opened}
          width={{ sm: 200, lg: 290 }}
          className="bg-white"
        >
          <Navbar.Section className={classes.header}>
            <Group position="apart">
              <Box className="w-[135px]   ">
                <Image src={logo} className="w-full h-full" alt="logo" />
              </Box>
              <IoIosClose
                onClick={() => setOpened((o) => !o)}
                size={24}
                className="cursor-pointer md:hidden"
              />
            </Group>
          </Navbar.Section>

          <Navbar.Section grow component={ScrollArea}>
            {navbarItems.map((item, index) => (
              <NavLink
                component={Link}
                to={item.link}
                key={index}
                label={item.label}
                icon={item.icon}
                childrenOffset={28}
                noWrap
                color="cyan"
                defaultOpened={item.initiallyOpened}
                className={`${
                  item.link && active === item.id ? " text-luchiPrimary " : " "
                } bg-transparent hover:bg-transparent`}
              >
                {item.links &&
                  item.links.map((elem) => (
                    <NavLink
                      noWrap
                      variant="filled"
                      color="cyan"
                      component={Link}
                      to={elem.link}
                      key={elem.id}
                      label={elem.label}
                      className={`${
                        active === elem.id ? " text-luchiPrimary " : " "
                      } bg-transparent hover:bg-transparent hover:text-luchiPrimary`}
                    />
                  ))}
              </NavLink>
            ))}
          </Navbar.Section>

          <Navbar.Section className="pt-3 border-t-2">
            <Paper>
              <Group>
                <Avatar size={40} src={user?.avatar_url} color="blue" />
                <div>
                  {typeofUser === "super_admin" && (
                    <Text className="text-bold text-base text-luchiPrimary">
                      Super Admin
                    </Text>
                  )}
                  {typeofUser === "mid_level_admin" && (
                    <Text className="text-bold text-base text-luchiPrimary">
                      Mid-Level Admin
                    </Text>
                  )}
                  {typeofUser === "standard_user" && (
                    <Text className="text-bold text-base text-luchiPrimary">
                      Standard User
                    </Text>
                  )}
                  {typeofUser === "employee" && (
                    <Text className="text-bold text-base text-luchiPrimary">
                      Employee
                    </Text>
                  )}
                  <Button
                    onClick={logoutOfApp}
                    leftIcon={<BiLogOut size={20} color="red" />}
                    className="font-medium capitalize text-black text-base"
                    variant="white"
                  >
                    logout
                  </Button>
                </div>
              </Group>
            </Paper>
          </Navbar.Section>
        </Navbar>
      }
      header={
        <Header
          height={{ base: 50, md: 50 }}
          p="md"
          bg={theme.colors.luchiPrimary[0]}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={() => setOpened((o) => !o)}
                size="md"
                color="white"
                mr="xl"
              />
            </MediaQuery>
            <Box className="w-full flex justify-end items-center space-x-4">
              <ActionIcon
                variant="transparent"
                className="w-fit"
                onClick={() => {
                  navigate(`/user/${user?.id}/notification`);
                }}
              >
                <Indicator
                  label="20"
                  color="cyan"
                  disabled
                  processing
                  inline
                  offset={5}
                  position="top-end"
                >
                  <BsBell size={24} className="text-white" />
                </Indicator>
              </ActionIcon>
              <MenuAvatar logOut={logoutOfApp} />
            </Box>
          </div>
        </Header>
      }
    >
      <main className="w-full 3xl:flex 3xl:flex-col 3xl:items-center h-full  ">
        <Outlet />
      </main>
    </AppShell>
  );
}

function MenuAvatar({ logOut }) {
  const { user } = useCurrentUser();
  const navigate = useNavigate();
  const items = [
    {
      id: 1,
      title: "settings",
      icon: AiOutlineSetting,
    },
    {
      id: 2,
      title: "logout",
      icon: BiLogOut,
    },
  ];
  return (
    <Menu
      trigger="hover"
      openDelay={100}
      closeDelay={400}
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <Avatar
          src={user?.avatar_url}
          alt="user image"
          radius="xl"
          height={200}
          className="cursor-pointer "
        />
      </Menu.Target>

      <Menu.Dropdown>
        {items.map((item) => (
          <Menu.Item
            onClick={() =>
              item.title === "logout" ? logOut() : navigate(`/user/${user?.id}`)
            }
            key={item.id}
            className="hover:bg-luchiPrimary hover:text-white capitalize font-display"
            icon={<item.icon size={14} />}
          >
            {item.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}

const useStyles = createStyles((theme) => ({
  header: {
    padding: theme.spacing.md,
    paddingTop: 0,
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
  },
}));
