import { Group, Text, Title } from "@mantine/core";
import { Link } from "react-router-dom";

export default function LocalBreadcrumb({ prev, current, link, header }) {
  return (
    <>
      <Title className="capitalize font-medium text-black text-2xl leading-7  ">
        {header}
      </Title>
      <Group mt={3} spacing={2}>
        <Link to={`/${link}`}>
          <Text className="text-sm text-black font-poppins hover:text-luchiPrimary underline">
            {prev}
          </Text>
        </Link>
        <Text> → </Text>
        <Text className="text-sm text-black font-poppins">{current}</Text>
      </Group>
    </>
  );
}
