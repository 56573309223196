import { useState } from "react";
import { searchEmployeeByBranchIDFetcher } from "../services/requests";
import { useEffect } from "react";
import useSWR from "swr";

export default function useSearchEmployeeByBranchId(branchID) {
  const { data } = useSWR(
    branchID && branchID,
    searchEmployeeByBranchIDFetcher
  );
  const [employeesEmails, setEmployeesEmails] = useState([]);
  useEffect(() => {
    if (data && data.length) {
      const emails = data.map((datum) => {
        const info = {
          value: datum.email,
          label: datum.email,
          name: datum.name,
        };
        return info;
      });
      setEmployeesEmails([...emails]);
    }
    return;
  }, [data]);

  return {
    employeesEmails,
    setEmployeesEmails,
  };
}
