import { useState, useEffect } from "react";
import HeaderBar from "../../../components/headers/HeaderBar";
import {
  Box,
  Button,
  Checkbox,
  Group,
  Loader,
  Modal,
  Paper,
  PasswordInput,
  Tabs,
  Text,
  Textarea,
  Title,
} from "@mantine/core";
import { BiChevronRight } from "react-icons/bi";
import EventsMenuActions from "../../../components/menu/EventsMenuActions";
import { useDisclosure } from "@mantine/hooks";
import { isNotEmpty, useForm } from "@mantine/form";
import { Link, useParams } from "react-router-dom";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { useFetchEventsByType } from "../../../hooks/useFetchEvents";
import {
  getDayMonthYear,
  getMonthYearNumber,
} from "../../../services/functions";
import dayjs from "dayjs";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function MonthlyLogs() {
  const { monthYear } = useParams();
  const { user } = useCurrentUser();
  // const [branchID, setBranchID] = useState(
  //   user.user_type !== "super_admin" ? user.branch.id : ""
  // );
  // const [branch, setBranch] = useState({
  //   branchLocation: branchID,
  // });

  return (
    <div className="w-full max-w-7xl ">
      <div className="w-full z-10">
        <HeaderBar
          title={monthYear && monthYear.split("-").join(", ")}
          data={{}}
          setData={{}}
          user={user}
          show={false}
        />
        <LocalBreadcrumb
          prev="event"
          current={monthYear && monthYear.split("-").join(", ")}
          link="event"
          header=""
        />
      </div>
      <div className="w-full mt-10">
        <EventTab />
      </div>
    </div>
  );
}

function EventTab() {
  const [activeTab, setActiveTab] = useState("videoEvents");
  const [value, setValue] = useState([]);
  const { monthYear } = useParams();
  const [opened, { open, close }] = useDisclosure(false);
  const { data, isLoading, error } = useFetchEventsByType("data");
  const {
    data: video,
    isLoading: vLoading,
    error: vError,
  } = useFetchEventsByType("video");
  const [displayData, setDisplayData] = useState([]);
  const [displayVideo, setDisplayVideo] = useState([]);

  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (data) {
      data.forEach((item) => {
        if (
          getMonthYearNumber(item.created_at) ===
          `${new Date(`${monthYear.split("-")[0]} 1, 2022`).getMonth() + 1}-${
            monthYear.split("-")[1]
          }`
        ) {
          if (!set.has(dayjs(item.created_at).format("YYYY-MM-DD"))) {
            set.add(dayjs(item.created_at).format("YYYY-MM-DD"));
            tempArray.push(item);
          }
          return tempArray;
        }
      });
      setDisplayData(tempArray);
    }
  }, [data, monthYear]);

  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (video) {
      video.forEach((item) => {
        if (
          getMonthYearNumber(item.created_at) ===
          `${new Date(`${monthYear.split("-")[0]} 1, 2022`).getMonth() + 1}-${
            monthYear.split("-")[1]
          }`
        ) {
          if (!set.has(dayjs(item.created_at).format("YYYY-MM-DD"))) {
            set.add(dayjs(item.created_at).format("YYYY-MM-DD"));
            tempArray.push(item);
          }
          return tempArray;
        }
      });
      setDisplayVideo(tempArray);
    }
  }, [video, monthYear]);
  return (
    <Tabs
      color="cyan"
      variant="pills"
      radius="xs"
      value={activeTab}
      onTabChange={setActiveTab}
    >
      <Tabs.List grow position="center">
        <Tabs.Tab className="capitalize text-lg" value="videoEvents">
          video event logs
        </Tabs.Tab>
        <Tabs.Tab className="capitalize text-lg" value="dataEvents">
          data logs
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel className="py-4 " value="videoEvents">
        <Box className="w-full">
          <Box className="h-4 w-full mb-8 flex justify-between items-center">
            <Checkbox color="cyan" label="Select All" />
            <EventsMenuActions openDelModal={open} />
          </Box>
          {vLoading && (
            <Box className="w-full h-full flex justify-center ">
              <Loader variant="oval" color="cyan" />
            </Box>
          )}
          {vError && (
            <Box className="w-full h-full flex justify-center ">
              <Loader variant="dots" color="red" />
              <Text ml={2}>Error!</Text>
            </Box>
          )}
          {displayVideo?.length === 0 ? (
            <Box className="w-full h-full items-center flex justify-center ">
              <Text className="text-sm uppercase ">no video events yet!</Text>
            </Box>
          ) : (
            displayVideo.map((item) => (
              <Group key={item.id} mb={16}>
                <Checkbox.Group value={value} onChange={setValue}>
                  <Checkbox color="cyan" />
                </Checkbox.Group>

                <Link
                  to={`/event/monthly-logs/video/${dayjs(
                    item.created_at
                  ).format("YYYY-MM-DD")}`}
                >
                  <Paper withBorder p="sm" className="w-full max-w-2xl">
                    <Group position="apart" spacing={4}>
                      <Box>
                        <Text fw={500}>{getDayMonthYear(item.created_at)}</Text>
                        <Text className="text-sm">
                          This contains the video events of respective devices
                          for the day.
                        </Text>
                      </Box>
                      <BiChevronRight size={20} />
                    </Group>
                  </Paper>
                </Link>
              </Group>
            ))
          )}
        </Box>
        <EventLogDelete opened={opened} close={close} />
      </Tabs.Panel>
      <Tabs.Panel className="py-6 " value="dataEvents">
        <Box className="w-full">
          <Box className="h-4 w-full mb-8 flex justify-end space-x-4 items-center">
            <Checkbox color="cyan" labelPosition="left" label="Select All" />
            <EventsMenuActions openDelModal={open} />
          </Box>
          {isLoading && (
            <Box className="w-full h-full flex justify-center ">
              <Loader variant="oval" color="cyan" />
            </Box>
          )}
          {error && (
            <Box className="w-full h-full flex justify-center ">
              <Loader variant="dots" color="red" />
              <Text ml={2}>Error!</Text>
            </Box>
          )}
          {displayData?.length === 0 ? (
            <Box className="w-full h-full items-center flex justify-center ">
              <Text className="text-sm uppercase ">no data events yet!</Text>
            </Box>
          ) : (
            displayData.map((item) => (
              <Group key={item.id} mb={16}>
                <Checkbox.Group value={value} onChange={setValue}>
                  <Checkbox color="cyan" />
                </Checkbox.Group>

                <Link
                  to={`/event/monthly-logs/data/${dayjs(item.created_at).format(
                    "YYYY-MM-DD"
                  )}`}
                >
                  <Paper withBorder p="sm" className="w-full max-w-2xl">
                    <Group position="apart" spacing={4}>
                      <Box>
                        <Text fw={500}>{getDayMonthYear(item.created_at)}</Text>
                        <Text className="text-sm">
                          This contains the data events of respective devices
                          for the day.
                        </Text>
                      </Box>
                      <BiChevronRight size={20} />
                    </Group>
                  </Paper>
                </Link>
              </Group>
            ))
          )}
        </Box>
        <EventLogDelete opened={opened} close={close} />
      </Tabs.Panel>
    </Tabs>
  );
}

export function EventLogDelete({ opened, close }) {
  const form = useForm({
    initialValues: {
      password: "",
      reason: "",
    },

    validate: {
      password: isNotEmpty("Password field cannot be empty"),
      reason: isNotEmpty("Compulsory field"),
    },
  });

  async function handleDeleteEventLog(values, event) {
    event.preventDefault();
    console.log(values);
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        title="Are you sure you want to delete?"
        centered
      >
        <form
          action=""
          onSubmit={form.onSubmit((values, event) =>
            handleDeleteEventLog(values, event)
          )}
        >
          <Box className="w-full mb-4">
            <Title order={4} className="text-base font-poppins font-normal ">
              To delete, please enter the following details!
            </Title>
          </Box>
          <Box>
            <PasswordInput
              placeholder="Enter your password"
              label="Password"
              size="md"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("password")}
            />
            <Textarea
              mt={16}
              placeholder="Enter your reason"
              label="Reason"
              size="md"
              radius={4}
              styles={(theme) => ({
                label: {
                  paddingBottom: 4,
                  fontSize: 16,
                  fontWeight: 500,
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
              {...form.getInputProps("reason")}
            />
            <Box mt={20} className="w-full flex justify-end">
              <Button
                variant="white"
                type="submit"
                className="bg-luchiPrimary text-white capitalize"
              >
                proceed
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>
    </>
  );
}
