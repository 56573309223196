import { Modal, Text, Box, Button } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserTypeContext } from "../../App";
import { successNotify } from "../../services/functions";

export default function LoginAsEmployeeModal({
  opened,
  close,
  permission,
  data,
}) {
  const navigate = useNavigate();
  const setUserType = useContext(UserTypeContext).setUserType;

  function handleSubLogin() {
    close();
    successNotify("Login Status", "Login successful");
    setUserType(data.user.user_type);
    localStorage.setItem("typeOfUser", data.user.user_type);
    setTimeout(() => navigate("/dashboard", { replace: true }), 1500);
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={true}
        title="Login Alert"
      >
        <Text className="text-center text-base font-medium text-black ">
          {` Your permission as a ${permission} has expired or has been revoked.`}
        </Text>
        <Text className="text-center text-sm text-yellow-600">
          Do you wish to proceed and login as an Employee?
        </Text>
        <Box mt={20} className="w-full flex justify-center space-x-4">
          <Button
            className="bg-red-700 text-white font-medium uppercase"
            type="button"
            onClick={close}
            variant="white"
          >
            no
          </Button>
          <Button
            onClick={handleSubLogin}
            variant="white"
            type="button"
            className="font-medium bg-green-700 text-white uppercase"
          >
            yes
          </Button>
        </Box>
      </Modal>
    </>
  );
}
