import { Modal, Button, TextInput, Box } from "@mantine/core";
import { useContext, useState, useEffect } from "react";
import { failedNotify, successNotify } from "../../services/functions";
import { isNotEmpty, useForm } from "@mantine/form";
import { TempValueContext } from "../../context/TempValueContext";
import { createPosition, editPosition } from "../../services/requests";
import { mutate } from "swr";

export default function CreatePosition({ open, close }) {
  const [loader, setLoader] = useState(false);
  const { tempValue: editPositionDetails, setTempValue } =
    useContext(TempValueContext);

  const form = useForm({
    initialValues: {
      name: "",
      description: "",
    },
    validate: {
      name: isNotEmpty("Compulsory field"),
      description: isNotEmpty("Compulsory field"),
    },
  });

  useEffect(() => {
    if (editPositionDetails?.value) {
      form.setValues({
        name: editPositionDetails.label,
        description: editPositionDetails.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editPositionDetails]);

  async function handleCreatePosition(values, event) {
    event.preventDefault();
    setLoader(true);
    const positionId = editPositionDetails.value;

    if (positionId) {
      const details = {
        name: values.name,
        description: values.description,
        branch_id: "",
      };
      const res = await editPosition(positionId, details);
      if (res.status) {
        successNotify("edit status", res.message);
        mutate("api/all-positions");
        setTempValue("");
        form.reset();
      } else {
        failedNotify("edit status", "An unexpected error has occured, retry.");
      }
    } else {
      const res = await createPosition(values);
      if (res.status) {
        successNotify("create status", res.message);
        mutate("api/all-positions");
        form.reset();
      } else {
        failedNotify("create status", res.response.data.message);
      }
    }
    setLoader(false);
  }
  return (
    <>
      <Modal
        opened={open}
        onClose={() => {}}
        centered
        withCloseButton={false}
        title="Create/Edit Position"
      >
        <form
          onSubmit={form.onSubmit((values, e) =>
            handleCreatePosition(values, e)
          )}
        >
          <TextInput
            className="w-full"
            placeholder="Enter position name"
            withAsterisk
            label="Position Name"
            size="md"
            radius={4}
            styles={(theme) => ({
              label: {
                paddingBottom: 4,
                fontSize: 16,
                fontWeight: 500,
              },
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
            })}
            {...form.getInputProps("name")}
          />
          <TextInput
            mt={16}
            className="w-full"
            placeholder="Enter position description"
            withAsterisk
            label="Position Description"
            size="md"
            radius={4}
            styles={(theme) => ({
              label: {
                paddingBottom: 4,
                fontSize: 16,
                fontWeight: 500,
              },
              input: {
                fontSize: 14,
                borderWidth: 1,
                "&:focus": {
                  borderColor: theme.colors.luchiPrimary[0],
                },
              },
            })}
            {...form.getInputProps("description")}
          />
          <Box mt={16} className="w-full flex space-x-4">
            <Button
              onClick={() => {
                setTempValue("");
                close();
                form.reset();
              }}
              type="button"
              variant="white"
              className="bg-red-700 text-white font-medium capitalize w-full"
            >
              close
            </Button>

            <Button
              type="submit"
              loaderPosition="right"
              loading={loader}
              variant="white"
              className="bg-luchiPrimary text-white font-medium capitalize w-full"
            >
              save
            </Button>
          </Box>
        </form>
      </Modal>
    </>
  );
}
