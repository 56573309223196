import React from "react";
import {
  AspectRatio,
  Modal,
  Paper,
  Box,
  Button,
  Text,
  Stack,
  Group,
} from "@mantine/core";
import { getAttendanceTime } from "../../services/functions";

function EventDetails({ opened, close, eventData }) {
  console.log(eventData);
  return (
    <>
      <Modal
        withCloseButton={false}
        size={"90%"}
        opened={opened}
        onClose={close}
      >
        {eventData &&
          eventData.map((event) => (
            <Box
              key={event.id}
              className="w-full max-w-[987px] mx-auto relative"
            >
              <Paper className="w-full h-[373px] ">
                <AspectRatio ratio={16 / 6}>
                  <iframe
                    src="https://www.youtube.com/embed/Dorf8i6lCuk"
                    title="dsjhsjh"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </AspectRatio>
              </Paper>
              <Stack spacing={3} mt={10} align="flex-start">
                <Group className="">
                  <Text className="font-semibold font-display capitalize text-base">
                    device:
                  </Text>
                  <Text className="font-medium font-display capitalize text-sm">
                    {event.device.name}
                  </Text>
                </Group>
                <Group className="">
                  <Text className="font-semibold font-display capitalize text-base">
                    event:
                  </Text>
                  <Text className="font-medium font-display capitalize text-sm">
                    {event.event_name}
                  </Text>
                </Group>
                <Group>
                  <Text className="font-semibold font-display capitalize text-base">
                    time:
                  </Text>
                  <Text className="font-medium font-display capitalize text-sm">
                    {getAttendanceTime(event.cre)}
                  </Text>
                </Group>
                <Group>
                  <Text className="font-semibold font-display capitalize text-base">
                    credential:
                  </Text>
                  <Text className="font-medium font-display capitalize text-sm">
                    {event.credential}
                  </Text>
                </Group>
                <Group>
                  <Text className="font-semibold font-display capitalize text-base">
                    accessed by:
                  </Text>
                  <Text className="font-medium font-display capitalize text-sm">
                    {`${event.user.first_name} ${event.user.last_name}`}
                  </Text>
                </Group>
              </Stack>
              <Button
                onClick={close}
                variant="white"
                className="text-sm capitalize font-medium text-white bg-luchiPrimary absolute right-0 bottom-6"
              >
                close
              </Button>
            </Box>
          ))}
      </Modal>
    </>
  );
}
const ViewEvent = React.memo(EventDetails);
export default ViewEvent;
