import { createContext, useEffect, useState } from "react";

export const TempValueContext = createContext("");

export default function TempValueProvider({ children }) {
  const [tempValue, setTempValue] = useState(() => {
    const storedTempValue =
      typeof window !== "undefined" && localStorage.getItem("tempValue");
    return storedTempValue ? JSON.parse(storedTempValue) : {};
  });

  useEffect(() => {
    localStorage.setItem("tempValue", JSON.stringify(tempValue));
  }, [tempValue]);

  return (
    <TempValueContext.Provider value={{ tempValue, setTempValue }}>
      {children}
    </TempValueContext.Provider>
  );
}
