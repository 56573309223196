import CredentialToggle from "../../../components/CredentialToggle";
import UtilityHeader from "../../../components/headers/UtilityHeader";
import { rolePermissions } from "../../../components/Users/Standarduser/navbar/sideBarData";

export default function SetRolePermission() {
  return (
    <div className="w-full pr-4 max-w-7xl pb-52  ">
      <div className="w-full mb-10 ">
        <UtilityHeader
          title={"settings"}
          subtitle={"settings"}
          backLink={"/organization-setup/settings"}
        />
      </div>
      <div className=" ">
        <div className="w-full max-w-[1095px] bg-white rounded-[20px] p-6 mb-10 ">
          <h1 className="leading-6 text-black text-2xl font-bold mb-4 capitalize">
            permissions
          </h1>
          <div className="overflow-x-auto">
            <table className="table w-full capitalize">
              <thead>
                <tr>
                  <th></th>
                  <th>admin</th>
                  <th>user</th>
                </tr>
              </thead>
              <tbody>
                {rolePermissions.map((permission) => (
                  <tr key={permission.id}>
                    <td>{permission.type}</td>
                    <td>
                      <CredentialToggle />
                    </td>
                    <td>
                      <CredentialToggle />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="items-center flex w-full justify-end mt-10 ">
            <button
              type="button"
              className="bg-luchiPrimary rounded-md w-[94px] h-10 text-white hover:bg-luchiPrimary/60"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
