import {
  createStyles,
  Image,
  Container,
  Title,
  Text,
  Button,
  SimpleGrid,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

export default function NotFoundPage() {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function handleNavigate() {
    navigate("/");
  }

  return (
    <Container className={`${classes.root} `}>
      <SimpleGrid
        spacing={80}
        cols={2}
        breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
      >
        <Image
          src={"/error.svg"}
          className={classes.mobileImage}
          alt="error image"
          width={150}
          mx="auto"
        />
        <div>
          <Title className={`${classes.title} text-luchiPrimary`}>
            Nothing to see here...
          </Title>
          <Text color="dark" size="lg" className=" font-display">
            An error has occured. This could be as a result of network failure,
            wrong page-url or nonexistence of the current page. Kindly
            crosscheck to confirm the informaion available to you.
          </Text>
          <Button
            onClick={handleNavigate}
            type="button"
            variant="outline"
            size="md"
            mt="xl"
            className={`${classes.control} border-luchiPrimary text-luchiPrimary font-medium font-display`}
          >
            Go back to home page
          </Button>
        </div>
        <Image
          src={"/error.svg"}
          className={classes.desktopImage}
          alt="error image"
        />
      </SimpleGrid>
    </Container>
  );
}

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 0,
  },

  title: {
    fontWeight: 900,
    fontSize: 34,
    marginBottom: theme.spacing.md,
    fontFamily: `${theme.fontFamily}`,

    [theme.fn.smallerThan("sm")]: {
      fontSize: 32,
    },
  },

  control: {
    [theme.fn.smallerThan("sm")]: {
      width: "100%",
    },
  },

  mobileImage: {
    [theme.fn.largerThan("sm")]: {
      display: "none",
    },
  },

  desktopImage: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },
}));
