import React from "react";
import { useParams } from "react-router-dom";
import {
  Avatar,
  Box,
  Divider,
  Group,
  LoadingOverlay,
  Paper,
  Text,
} from "@mantine/core";
import { useEffect } from "react";
import { useState } from "react";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

const ProfileSetting = () => {
  const param = useParams();
  const { user } = useCurrentUser();
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (user && user.id) {
      setOpen(false);
    }
  }, [setOpen, user]);
  return (
    <div className="w-full max-w-7xl h-full  ">
      <>
        <LocalBreadcrumb
          prev="profile board"
          current="profile"
          link={`user/${param.userId}`}
          header="profile"
        />
      </>
      <Box className="w-full flex justify-center items-center mt-14 ">
        <Paper
          p="md"
          withBorder
          shadow="xl"
          className="w-full max-w-sm flex justify-center items-center bg-white flex-col relative "
        >
          <Avatar
            src={user && user.avatar_url}
            alt={`${user && user.first_name}'s image`}
            size={100}
          />
          <Box className="w-full">
            <Divider my={16} />
            <Group position="center" className="w-full">
              <Text className="text-base font-poppins font-semibold capitalize">
                {user && user.first_name} {user && user.last_name}
              </Text>
            </Group>
            <Divider my={16} />
            <Box className="w-full text-center">
              <Text className="text-base font-poppins font-semibold capitalize">
                email
              </Text>
              <Text className="text-sm font-poppins font-normal ">
                {user && user.email}
              </Text>
            </Box>
            <Divider my={16} />
            <Box className="w-full text-center">
              <Text className="text-base font-poppins font-semibold">
                Department
              </Text>
              <Text className="text-sm font-poppins font-normal capitalize">
                {(user && user.department.name) || "unknown department"}
              </Text>
            </Box>
            <Divider my={16} />
            <Box className="w-full text-center">
              <Text className="text-base font-poppins font-semibold capitalize">
                position
              </Text>
              <Text className="text-sm font-poppins font-normal capitalize">
                {(user && user.position.name) || "unknown position"}
              </Text>
            </Box>
          </Box>
          <LoadingOverlay
            color="cyan"
            className="w-full"
            visible={open}
            overlayBlur={20}
          />
        </Paper>
      </Box>
    </div>
  );
};
export default ProfileSetting;
