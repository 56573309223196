import React from "react";
import StandardUserBarChart from "../../../charts/StandardUserBarChart";
import { Paper, Group, Text, Title, Box } from "@mantine/core";

const StandardUserWeeklyStatus = () => {
  return (
    <Paper withBorder shadow="md" p="md" className="flex-1 h-fit bg-white ">
      <div className=" ">
        <Box className="mb-4">
          <Title
            mb={4}
            className="text-lg font-bold capitalize font-poppins text-black "
          >
            Weekly status
          </Title>
          <Group spacing={5} className="text-base">
            <Text className="text-black font-medium font-poppins text-sm">
              from
            </Text>
            <Text className="font-normal text-black font-poppins text-sm">
              1 Aug
            </Text>
            <Text className="font-normal text-black font-poppins text-sm">
              30 Aug
            </Text>
          </Group>
        </Box>
        <Box>
          <StandardUserBarChart />
        </Box>
      </div>
    </Paper>
  );
};

export default StandardUserWeeklyStatus;
