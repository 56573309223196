import { Group, Switch } from "@mantine/core";

export default function CredentialToggle({ value, title, setValue }) {
  console.log(value);
  return (
    <Switch.Group value={value} onChange={setValue}>
      <Group position="apart">
        <Switch value={title} />
        <Switch value={title} />
      </Group>
    </Switch.Group>
  );
}
