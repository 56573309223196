import { Link } from "react-router-dom";
import UtilityHeader from "../../../components/headers/UtilityHeader";
import useFetchPermissions from "../../../hooks/useFetchPermissions";
import { useState } from "react";
import PermissionsTable from "../../../components/tables/PermissionsTable";
import { Box, Button } from "@mantine/core";

export default function Permissions() {
  const { permissions } = useFetchPermissions();
  const [actionDetails, setActionDetails] = useState({
    type: "",
    email: "",
    name: "",
  });
  return (
    <div className="w-full max-w-7xl pb-8 ">
      <div className="w-full mb-4">
        <UtilityHeader title={"permissions"} subtitle={""} backLink={""} />
      </div>
      <div className="w-full ">
        <h3 className="text-black/[85] font-medium text-base">
          Permissions granted by Super administrator
        </h3>
        <p className="w-full font-normal text-sm text-black/60">
          You can grant and revoke access to special permissions. By granting
          access any changes made will be permanent.
        </p>
        <div className="mt-8 ">
          {/* {error && (
            <div className="w-full h-96 flex items-center justify-center">
              <p className="text-base text-luchiPrimary">
                oops! a network error has occured, kindly check and try again.
              </p>
            </div>
          )}
          {isLoading && (
            <div className="w-full h-96 flex justify-center items-center space-x-3 text-luchiPrimary">
              <FaSpinner className="w-6 h-6 animate-spin" />
              <p>Loading...</p>
            </div>
          )} */}
          <Box mb={6} className="flex w-full justify-end ">
            <Link to={"/organization-setup/permissions/grant-permission"}>
              <Button
                variant="white"
                className="text-white bg-luchiPrimary capitalize"
              >
                grant access
              </Button>
            </Link>
          </Box>
          <div className="w-full">
            <PermissionsTable
              permissionsData={permissions && permissions.allRoles}
              setDetails={setActionDetails}
              details={actionDetails}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
