import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  createStyles,
  Text,
  Avatar,
  Group,
  Divider,
  Button,
  Textarea,
} from "@mantine/core";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { notificationDetails } from "../../../assets/data/userProfileBoard";

const Notification = () => {
  const [currentIndex, setCurrentIndex] = useState(-1);
  const param = useParams();
  const { classes } = useStyles();

  return (
    <div className="w-full max-w-7xl h-full     ">
      <>
        <LocalBreadcrumb
          prev="profile board"
          current="notification"
          link={`user/${param.userId}`}
          header="notification"
        />
      </>
      <>
        {!notificationDetails.length && (
          <Box className="w-full text-center flex items-center justify-center h-[calc(100vh-152px)] ">
            <Text className="text-xl font-medium text-luchiPrimary ">
              You will recieve important updates here
            </Text>
          </Box>
        )}
        <Box mt={30} className="w-full">
          {notificationDetails.map((details, index) => (
            <Box key={details.id}>
              <Box className="flex space-x-5">
                <Avatar
                  src={details.imageLink}
                  alt={details.name}
                  className="w-20 h-20 rounded-full"
                />
                <div>
                  <Text size="lg" className="text-medium capitalize">
                    {details.name}
                  </Text>
                  <Text size="xs" color="dimmed">
                    {details.timeSent}
                  </Text>
                  <Text maw={550} className={classes.body} size="sm">
                    {details.message}
                  </Text>

                  <Group mt={10} className="w-full">
                    {currentIndex === index ? (
                      <>
                        <Textarea
                          className="w-full max-w-sm"
                          placeholder="Enter your response"
                          size="md"
                          radius={4}
                          styles={(theme) => ({
                            input: {
                              fontSize: 14,
                              borderWidth: 1,
                              "&:focus": {
                                borderColor: theme.colors.luchiPrimary[0],
                              },
                            },
                          })}
                        />
                        <Button
                          onClick={() => setCurrentIndex(-1)}
                          variant="white"
                          className="bg-luchiPrimary text-white font-medium capitalize"
                        >
                          send
                        </Button>
                      </>
                    ) : (
                      <Button
                        onClick={() => setCurrentIndex(index)}
                        variant="white"
                        className="bg-luchiPrimary text-white font-medium capitalize"
                      >
                        reply
                      </Button>
                    )}
                  </Group>
                </div>
              </Box>

              {index !== notificationDetails.length - 1 && <Divider my={16} />}
            </Box>
          ))}
        </Box>
      </>
    </div>
  );
};

export default Notification;

const useStyles = createStyles((theme) => ({
  body: {
    paddingTop: theme.spacing.sm,
  },
}));
