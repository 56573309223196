export const payHistory = [
  {
    id: 1,
    date: "Sept 19, 2022",
    payment: "Purchase of device",
    mode: "Debit card",
    amount: "USD $50.00",
    status: "paid",
  },
  {
    id: 2,
    date: "Sept 19, 2022",
    payment: "Purchase of device",
    mode: "Debit card",
    amount: "USD $50.00",
    status: "paid",
  },
  {
    id: 3,
    date: "Sept 19, 2022",
    payment: "Purchase of device",
    mode: "Debit card",
    amount: "USD $50.00",
    status: "error",
  },
  {
    id: 4,
    date: "Sept 19, 2022",
    payment: "Purchase of device",
    mode: "Debit card",
    amount: "USD $50.00",
    status: "paid",
  },
];
