import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { grantEmployeeAccess } from "../../../services/requests";
import RoleSelectBox from "../../../components/singleSelect/RoleSelectBox";
import { Button, Group, NumberInput, Text, Box } from "@mantine/core";
import EmployeeEmailSelect from "../../../components/singleSelect/EmployeeEmailSelect";
import { failedNotify, successNotify } from "../../../services/functions";
import { isNotEmpty, useForm, isEmail } from "@mantine/form";
import { DepartmentLocation } from "../../../components/singleSelect/DepartmentLocation";

export default function GrantAccess() {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const form = useForm({
    initialValues: {
      deptLocationID: "",
      email: "",
      duration: 1,
      role: "",
    },
    validate: {
      deptLocationID: isNotEmpty("Compulsory field"),
      email: isEmail("Invalid email"),
      duration: isNotEmpty("Compulsory field"),
      role: isNotEmpty("Compulsory field"),
    },
  });

  async function grantAccess(values, e) {
    e.preventDefault();
    setLoader(true);
    const details = {
      branch_id: values.deptLocationID,
      email: values.email,
      role:
        values.role === "Mid-level Admin" ? "mid_level_admin" : "standard_user",
      days: values.duration,
    };
    const res = await grantEmployeeAccess(details);
    if (res) {
      successNotify("Permission status", "Permission granted successfully");
      setTimeout(() => navigate("/organization-setup/permissions"), 1500);
    } else failedNotify("Permission status", "Permission granting failed");
    setLoader(false);
  }

  return (
    <div className="w-full 3xl:max-w-[1222px] bg-white ">
      <div className="w-full max-w-[500px] ">
        <h1 className="capitalize font-bold text-black text-2xl leading-7  ">
          grant permission
        </h1>
        <Group mt={3} spacing={2}>
          <Link to={"/organization-setup/permissions"}>
            <Text className="text-sm text-black capitalize hover:text-luchiPrimary hover:underline">
              permissions
            </Text>
          </Link>
          <Text>/</Text>
          <Text className="text-sm text-black capitalize">
            grant permission
          </Text>
        </Group>

        <form
          className=" bg-white rounded-xl py-6 max-w-xl "
          onSubmit={form.onSubmit((values, e) => grantAccess(values, e))}
        >
          <Box>
            <DepartmentLocation form={form} />
          </Box>

          <Box my={16}>
            <EmployeeEmailSelect
              branchLocationID={form.getInputProps("deptLocationID").value}
              form={form}
            />
          </Box>

          <RoleSelectBox form={form} />
          <div className="my-2">
            <NumberInput
              placeholder="Permission duration"
              label="Duration"
              size="lg"
              stepHoldDelay={500}
              stepHoldInterval={100}
              min={1}
              styles={(theme) => ({
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
                label: {
                  paddingBottom: 6,
                  fontSize: 16,
                },
              })}
              {...form.getInputProps("duration")}
            />
          </div>

          <div className=" flex justify-end mt-5">
            <Button
              type="submit"
              variant="white"
              loading={loader}
              loaderPosition="right"
              color="yellow"
              className=" text-sm text-white font-semibold bg-[#37A7AE] capitalize"
            >
              grant access
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}
