import { Box } from "@mantine/core";
import DashboardTop from "../../components/DashboardTop/DashboardTop";
import StandardUserAttendance from "../../components/Users/Standarduser/dashboard/StandardUserAttendance";
import StandardUserMonthlyLog from "../../components/Users/Standarduser/dashboard/StandardUserMonthlyLog";
import StandardUserOverview from "../../components/Users/Standarduser/dashboard/StandardUserOverview";
import StandardUserWeeklyStatus from "../../components/Users/Standarduser/dashboard/StandardUserWeeklyStatus";

const StandardUserDashboard = () => {
  return (
    <div className="w-full max-w-7xl ">
      <DashboardTop />
      <Box className="flex space-x-[24px] xl:justify-between ">
        <StandardUserOverview />
        <StandardUserMonthlyLog />
      </Box>
      <Box className="flex mt-6 space-x-[24px] xl:justify-between  ">
        <StandardUserAttendance />
        <StandardUserWeeklyStatus />
      </Box>
    </div>
  );
};
export default StandardUserDashboard;
