import {
  ActionIcon,
  Box,
  Group,
  Tooltip,
  createStyles,
  Badge,
  Modal,
  Button,
  Text,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { BiEditAlt } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteEmployeeById,
  permDeleteEmployeeById,
} from "../../services/requests";
import { mutate } from "swr";
import { useDisclosure } from "@mantine/hooks";
import {
  failedNotify,
  handleEdit,
  successNotify,
} from "../../services/functions";
import { useContext } from "react";
import { TempValueContext } from "../../context/TempValueContext";

const PAGE_SIZES = [10, 20, 50];
export default function EmployeesTable({ employeesData, loading }) {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = employeesData && employeesData.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);
  const { classes } = useStyles();
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);
  const [employeeToDel, setEmployeeToDel] = useState({});
  const url = useLocation().pathname;
  const exactUrl = url.split("/")[2];
  const setTempValue = useContext(TempValueContext).setTempValue;

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    employeesData && setRecords(employeesData.slice(from, to));
  }, [employeesData, page, pageSize]);

  function openDelModal(employee) {
    open();
    setEmployeeToDel(employee);
  }

  return (
    <>
      <Box className="h-[430px]  ">
        <DataTable
          withBorder
          striped
          highlightOnHover
          withColumnBorders
          textSelectionDisabled
          records={records}
          fetching={loading}
          loaderBackgroundBlur={4}
          loaderColor="cyan"
          loaderSize="sm"
          minHeight={150}
          noRecordsText="No records to show"
          columns={[
            {
              accessor: "id",
              title: "#",
              textAlignment: "center",
              width: 40,
              ellipsis: true,
              render: (record) => records.indexOf(record) + 1,
            },
            {
              accessor: "name",
              width: 150,
              ellipsis: true,
            },
            {
              accessor: "position",
              width: 150,
              ellipsis: true,
              render: ({ position }) => position.name,
            },
            { accessor: "email", width: 300, ellipsis: true },

            {
              accessor: "status",
              width: 70,
              cellsStyle: {
                textTransform: "capitalize",
              },
              cellsClassName: ({ status }) =>
                status === "present" ? classes.present : classes.absent,
            },
            {
              accessor: "credentials",
              width: 150,
              render: ({ credentials }) => (
                <Group spacing={1}>
                  {credentials.RFID_CARD && (
                    <Tooltip withArrow label="RFID Card">
                      <Badge color="cyan" variant="filled">
                        RCD
                      </Badge>
                    </Tooltip>
                  )}
                  {credentials.PIN && (
                    <Tooltip withArrow label="PIN">
                      <Badge color="cyan" variant="filled">
                        pin
                      </Badge>
                    </Tooltip>
                  )}
                  {credentials.FINGERPRINT && (
                    <Tooltip withArrow label="Fingerprint">
                      <Badge color="cyan" variant="filled">
                        fp
                      </Badge>
                    </Tooltip>
                  )}
                  {credentials.MOBILE_APP && (
                    <Tooltip withArrow label="Mobile App">
                      <Badge color="cyan" variant="filled">
                        mba
                      </Badge>
                    </Tooltip>
                  )}
                </Group>
              ),
            },
            {
              accessor: "actions",
              width: 100,
              hidden: exactUrl === "employees" ? false : true,
              textAlignment: "center",
              render: (employeesData) => (
                <Group spacing={4} position="center" noWrap>
                  <ActionIcon
                    title="edit"
                    color="lime.8"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEdit(
                        setTempValue,
                        navigate,
                        "/team/add-employee",
                        employeesData
                      );
                    }}
                  >
                    <BiEditAlt size={16} />
                  </ActionIcon>

                  <ActionIcon
                    title="delete"
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      openDelModal(employeesData);
                    }}
                  >
                    <AiOutlineDelete size={16} />
                  </ActionIcon>
                </Group>
              ),
            },
          ]}
          totalRecords={employeesData && employeesData.length}
          paginationColor="cyan"
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
        />
      </Box>
      <EmployeeDeleteModal
        opened={opened}
        close={close}
        employeeToDel={employeeToDel}
      />
    </>
  );
}

const useStyles = createStyles((theme) => ({
  absent: {
    color: "red",
  },
  present: {
    color: "green",
  },
}));

function EmployeeDeleteModal({ opened, close, employeeToDel }) {
  const [btnLoader, setBtnLoader] = useState(false);

  async function handleDelete() {
    setBtnLoader(true);
    const res = await deleteEmployeeById(employeeToDel.id);
    if (res.status) {
      const permRes = await permDeleteEmployeeById(employeeToDel.id);
      if (permRes.status) {
        close();
        successNotify("Delete Status", "Employee deleted successfully");
        mutate("api/all-employees");
      } else {
        failedNotify("Delete Status", "Delete operation failed!");
      }
    } else {
      failedNotify("Delete Status", "Delete operation failed!");
    }
    setBtnLoader(false);
  }
  return (
    <>
      <Modal opened={opened} onClose={close} title="Delete Employee" centered>
        <Text className="font-normal text-center">
          Are you sure you want to delete this employee?
        </Text>
        <Text className="text-sm text-yellow-600 text-center">
          This cannot be recovered.
        </Text>

        <Group position="center" className="mt-5 w-full  ">
          <Button
            variant="white"
            type="button"
            className="inline-flex justify-center bg-black/20 capitalize text-sm font-medium text-black/[0.85]"
            onClick={close}
          >
            Cancel
          </Button>
          <Button
            variant="white"
            onClick={handleDelete}
            type="button"
            loading={btnLoader}
            loaderPosition="right"
            className="inline-flex justify-center bg-[#F55151] capitalize text-sm font-medium text-white "
          >
            Delete
          </Button>
        </Group>
      </Modal>
    </>
  );
}
