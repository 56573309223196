import {
  createStyles,
  Title,
  Container,
  rem,
  Button,
  Group,
} from "@mantine/core";
import { Link } from "react-router-dom";

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.white,
  },

  label: {
    textAlign: "center",
    fontWeight: 700,
    fontSize: 36,
    lineHeight: 1,
    marginBottom: 20,
    color: theme.colors.luchiPrimary[0],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(120),
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: "center",
    fontWeight: 500,
    fontSize: 20,
    color: theme.colors.dark[2],

    [theme.fn.smallerThan("sm")]: {
      fontSize: rem(32),
    },
  },
}));

export default function PermissionDenied() {
  const { classes } = useStyles();

  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.label}>Permission Denied</div>
        <Title className={classes.title}>
          Kindly upgrade your subscription to view this page!...
        </Title>
        <Group position="center" mt={20}>
          <Button
            component={Link}
            to="/help"
            variant="white"
            className="bg-luchiPrimary text-white capitalize"
          >
            upgrade
          </Button>
        </Group>
      </Container>
    </div>
  );
}
