export default function DotsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 12.5C21.3807 12.5 22.5 11.3807 22.5 10C22.5 8.61929 21.3807 7.5 20 7.5C18.6193 7.5 17.5 8.61929 17.5 10C17.5 11.3807 18.6193 12.5 20 12.5Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M20 12.85C21.574 12.85 22.85 11.574 22.85 10C22.85 8.42599 21.574 7.15 20 7.15C18.426 7.15 17.15 8.42599 17.15 10C17.15 11.574 18.426 12.85 20 12.85Z"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="0.7"
      />
      <path
        d="M20 22.5C21.3807 22.5 22.5 21.3807 22.5 20C22.5 18.6193 21.3807 17.5 20 17.5C18.6193 17.5 17.5 18.6193 17.5 20C17.5 21.3807 18.6193 22.5 20 22.5Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M20 22.85C21.574 22.85 22.85 21.574 22.85 20C22.85 18.426 21.574 17.15 20 17.15C18.426 17.15 17.15 18.426 17.15 20C17.15 21.574 18.426 22.85 20 22.85Z"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="0.7"
      />
      <path
        d="M20 32.5C21.3807 32.5 22.5 31.3807 22.5 30C22.5 28.6193 21.3807 27.5 20 27.5C18.6193 27.5 17.5 28.6193 17.5 30C17.5 31.3807 18.6193 32.5 20 32.5Z"
        fill="black"
        fillOpacity="0.85"
      />
      <path
        d="M20 32.85C21.574 32.85 22.85 31.574 22.85 30C22.85 28.426 21.574 27.15 20 27.15C18.426 27.15 17.15 28.426 17.15 30C17.15 31.574 18.426 32.85 20 32.85Z"
        stroke="black"
        strokeOpacity="0.85"
        strokeWidth="0.7"
      />
    </svg>
  );
}
