import {
  ActionIcon,
  Box,
  Group,
  Tooltip,
  Modal,
  Button,
  Text,
  NumberInput,
} from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { FaUndoAlt } from "react-icons/fa";
import { BiEditAlt } from "react-icons/bi";
import { mutate } from "swr";
import { useDisclosure } from "@mantine/hooks";
import { successNotify } from "../../services/functions";
import { revokeEmployeeAccess } from "../../services/requests";
import RoleSelectBox from "../singleSelect/RoleSelectBox";
import { isNotEmpty, useForm } from "@mantine/form";

const PAGE_SIZES = [10, 20, 50];

export default function PermissionsTable({
  permissionsData,
  setDetails,
  details,
}) {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = permissionsData && permissionsData.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);
  const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    permissionsData && setRecords(permissionsData.slice(from, to));
  }, [permissionsData, page, pageSize]);

  function handleRevoke(e, permissionsData) {
    e.stopPropagation();
    open();
    setDetails({
      ...details,
      type: "revoke",
      email: permissionsData.email,
      name: `${permissionsData.first_name} ${permissionsData.last_name}`,
    });
  }

  function handleEdit(e, permissionsData) {
    e.stopPropagation();
    open();
    setDetails({
      ...details,
      type: "edit",
      email: permissionsData.email,
      name: `${permissionsData.first_name} ${permissionsData.last_name}`,
    });
  }
  return (
    <>
      <Box className="h-[400px]  ">
        <DataTable
          withBorder
          striped
          highlightOnHover
          withColumnBorders
          textSelectionDisabled
          records={records}
          minHeight={150}
          noRecordsText="No records to show"
          columns={[
            {
              accessor: "first_name",
              title: "Name",
              width: 150,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ first_name, last_name }) =>
                `${first_name} ${last_name}`,
            },
            {
              accessor: "branch",
              width: 150,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ branch }) => branch.name,
            },
            { accessor: "email", width: 200, ellipsis: true },
            {
              accessor: "role",
              width: 170,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
            },
            { accessor: "duration", width: 70, ellipsis: true },

            {
              accessor: "status",
              width: 100,
              cellsStyle: {
                textTransform: "capitalize",
              },
            },
            {
              accessor: "actions",
              width: 100,
              textAlignment: "center",
              render: (permissionsData) => (
                <Group spacing={4} position="center" noWrap>
                  <Tooltip withArrow color="lime" label="edit">
                    <ActionIcon
                      disabled={
                        permissionsData.status === "revoked" ||
                        permissionsData.status === "expired"
                      }
                      color="lime"
                      onClick={(e) => handleEdit(e, permissionsData)}
                    >
                      <BiEditAlt size={16} />
                    </ActionIcon>
                  </Tooltip>
                  <Tooltip withArrow color="red" label="revoke">
                    <ActionIcon
                      disabled={
                        permissionsData.status === "revoked" ||
                        permissionsData.status === "expired"
                      }
                      color="red"
                      onClick={(e) => handleRevoke(e, permissionsData)}
                    >
                      <FaUndoAlt size={16} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              ),
            },
          ]}
          totalRecords={permissionsData && permissionsData.length}
          paginationColor="cyan"
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
        />
      </Box>
      <PermissionModal opened={opened} close={close} details={details} />
    </>
  );
}

function PermissionModal({ opened, close, details }) {
  const [loader, setLoader] = useState({
    updateLoader: false,
    revokeLoader: false,
  });

  const form = useForm({
    initialValues: {
      duration: 1,
      role: "",
    },
    validate: {
      duration: isNotEmpty("Compulsory field"),
      role: isNotEmpty("Compulsory field"),
    },
  });

  async function editPermission(values, event) {
    event.preventDefault();
    console.log(values);
    setLoader({ ...loader, updateLoader: true });
    console.log("edit initiated");
    setLoader({ ...loader, updateLoader: false });
  }
  async function revokePermission() {
    setLoader({ ...loader, revokeLoader: true });
    const res = await revokeEmployeeAccess(details.email);
    if (res) {
      successNotify("Revoke Status", "Permission revoked!");
      mutate("api/getEmployeeWithPermission");
      close();
    }
    setLoader({ ...loader, revokeLoader: false });
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => {}}
        withCloseButton={false}
        centered
      >
        <Text className="font-normal text-sm">
          {details.type !== "edit"
            ? "You are about to revoke the permissions of"
            : "You are about to edit the permissions of "}
        </Text>
        <Text mb={10} className="text-luchiPrimary font-medium text-xs">
          {details.name}
        </Text>

        <Box className="w-full">
          {details.type !== "edit" ? (
            <div className="mt-2">
              <Text className="text-sm text-black">
                If you proceed, employee will no longer have the given
                permissions.
              </Text>
            </div>
          ) : (
            <>
              <form
                onSubmit={form.onSubmit((values, e) =>
                  editPermission(values, e)
                )}
                className="w-full"
              >
                <Box mb={16}>
                  <RoleSelectBox form={form} />
                  <NumberInput
                    mt={16}
                    placeholder="Permission duration"
                    label="Duration"
                    size="lg"
                    stepHoldDelay={500}
                    stepHoldInterval={100}
                    min={1}
                    styles={(theme) => ({
                      input: {
                        fontSize: 14,
                        borderWidth: 1,
                        "&:focus": {
                          borderColor: theme.colors.luchiPrimary[0],
                        },
                      },
                      label: {
                        paddingBottom: 6,
                        fontSize: 16,
                      },
                    })}
                    {...form.getInputProps("duration")}
                  />
                </Box>
                <Group spacing={10} position="center">
                  <Button
                    variant="white"
                    className="bg-red-800 text-sm font-medium text-white capitalize "
                    onClick={() => close()}
                  >
                    cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="white"
                    loaderPosition="right"
                    color="cyan"
                    loading={loader.updateLoader}
                    className=" bg-green-700 text-sm font-medium text-white capitalize "
                  >
                    update
                  </Button>
                </Group>
              </form>
            </>
          )}
        </Box>

        {details.type !== "edit" && (
          <Group position="center" className="mt-5 w-full  ">
            <Button
              variant="white"
              className="bg-red-800 text-sm font-medium text-white capitalize "
              onClick={() => close()}
            >
              cancel
            </Button>

            <Button
              onClick={revokePermission}
              variant="white"
              loaderPosition="right"
              color="cyan"
              loading={loader.revokeLoader}
              className=" bg-[#F55151] px-4 py-2 text-sm font-medium text-white capitalize "
            >
              revoke
            </Button>
          </Group>
        )}
      </Modal>
    </>
  );
}
