import { ActionIcon, Box, Tooltip } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { FaUndoAlt } from "react-icons/fa";

const PAGE_SIZES = [20, 50];

export default function IntercomTable({
  permissionsData,
  setDetails,
  details,
}) {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = permissionsData && permissionsData.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);
  //   const [opened, { open, close }] = useDisclosure(false);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    permissionsData && setRecords(permissionsData.slice(from, to));
  }, [permissionsData, page, pageSize]);

  //   function handleRevoke(e, permissionsData) {
  //     e.stopPropagation();
  //     // open();
  //     setDetails({
  //       ...details,
  //       type: "revoke",
  //       email: permissionsData.email,
  //       name: `${permissionsData.first_name} ${permissionsData.last_name}`,
  //     });
  //   }

  return (
    <>
      <Box className="h-[400px]  ">
        <DataTable
          withBorder
          striped
          highlightOnHover
          withColumnBorders
          textSelectionDisabled
          records={records}
          minHeight={150}
          noRecordsText="No records to show"
          columns={[
            {
              accessor: "first_name",
              title: "Employee Name",
              width: 150,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ first_name, last_name }) =>
                `${first_name} ${last_name}`,
            },
            {
              accessor: "status",
              width: 100,
              cellsStyle: {
                textTransform: "capitalize",
              },
            },
            {
              accessor: "action",
              title: "Revoke",
              width: 20,
              render: (permissionsData) => (
                <Tooltip withArrow color="red" label="revoke">
                  <ActionIcon
                  // disabled={
                  //   permissionsData.status === "revoked" ||
                  //   permissionsData.status === "expired"
                  // }
                  // color="red"
                  // onClick={(e) => handleRevoke(e, permissionsData)}
                  >
                    <FaUndoAlt size={16} />
                  </ActionIcon>
                </Tooltip>
              ),
            },
          ]}
          totalRecords={permissionsData?.length}
          paginationColor="cyan"
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
        />
      </Box>
    </>
  );
}
