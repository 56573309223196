import axiosInstance from "./axiosInstance";
import axios from "axios";

export async function loginUser({ email, password }) {
  const response = await axios.post(
    "https://api-dev.luchismart.com/api/v1/auth/login",
    {
      email,
      password,
    }
  );
  return response;
}

export async function logout() {
  try {
    const { data } = await axiosInstance.post(`api/v1/auth/logout`);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function createDept(dept) {
  try {
    const { data } = await axiosInstance.post("api/v1/departments", dept);
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function createEmployee(details) {
  try {
    const { data } = await axiosInstance.post("api/v1/employees", details);
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

async function getAllDept() {
  const { data } = await axiosInstance.get(`api/v1/departments`);
  return data.data;
}
export const getAllDeptFetcher = () => getAllDept();

async function getAllPositions() {
  const { data } = await axiosInstance.get(`api/v1/positions`);
  return data.data;
}
export const getAllPositionsFetcher = () => getAllPositions();

async function getOrganizationById(id) {
  const { data } = await axiosInstance.get(`api/v1/admin/organizations/${id}`);
  return data.data;
}
export const getOrganizationFetcherById = (id) => getOrganizationById(id);

async function getBrannches() {
  const { data } = await axiosInstance.get("api/v1/branches");
  return data.data.data;
}
export const getBrannchesFetcher = () => getBrannches();

async function getBranchDetails(id) {
  const { data } = await axiosInstance.get(`api/v1/branches/${id}/stats`);
  return data;
}
export const getBranchDetailsFetcher = (id) => getBranchDetails(id);

export async function getBranchDetailsTrigger(url, details) {
  const { id } = details.arg;
  const { data } = await axiosInstance.get(`api/v1/branches/${id}/stats`);
  return data.data;
}

async function getEmployees() {
  const { data } = await axiosInstance.get("api/v1/employees");
  return data.data;
}
export const getEmployeesFetcher = () => getEmployees();

async function getEmployeeById(id) {
  const { data } = await axiosInstance.get(`api/v1/employees/${id}`);
  return data.data;
}
export const getEmployeeByIdFetcher = (id) => getEmployeeById(id);

async function getBranchDeptEmps(branchId) {
  const value = branchId.split("/")[0].toLowerCase();
  let branchDept;
  let branchEmps;

  if (value === "api") {
    branchDept = `api/v1/departments`;
    branchEmps = `api/v1/employees`;
  } else {
    branchDept = `api/v1/departments?branch_id=${branchId}`;
    branchEmps = `api/v1/employees?branch_id=${branchId}`;
  }

  const [branchdept, branchemps] = await Promise.all([
    axiosInstance.get(branchDept),
    axiosInstance.get(branchEmps),
  ]);

  return {
    teams: branchdept.data.data,
    members: branchemps.data.data,
  };
}
export const getBranchDeptEmpsFetcher = (branchId) =>
  getBranchDeptEmps(branchId);

export const createBranch = async (details) => {
  try {
    const { data } = await axiosInstance.post("api/v1/branches", details);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const deleteEmployeeById = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`api/v1/employees/${id}`);
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const permDeleteEmployeeById = async (id) => {
  try {
    const { data } = await axiosInstance.delete(
      `api/v1/employees/${id}/delete`
    );
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const deleteDeptById = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`api/v1/departments/${id}`);
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const permDeleteDeptById = async (id) => {
  try {
    const { data } = await axiosInstance.delete(
      `api/v1/departments/${id}/delete`
    );
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const editDepartment = async (deptDetails) => {
  try {
    const { data } = await axiosInstance.put(
      `api/v1/departments/${deptDetails.deptId}`,
      deptDetails
    );
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const editEmployee = async (employeeDetails) => {
  try {
    const { data } = await axiosInstance.put(
      `api/v1/employees/${employeeDetails.employeeID}`,
      employeeDetails
    );
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getAttendance = async () => {
  const { data } = await axiosInstance.get("api/v1/attendances");
  return data.data;
};
export const getAttendanceFetcher = () => getAttendance();

const getAttendanceByNumber = async (numberOfItems) => {
  const { data } = await axiosInstance.get(
    `api/v1/attendances?per_page=${numberOfItems}`
  );
  return data.data;
};
export const getAttendanceByNumberFetcher = (numberOfItems) =>
  getAttendanceByNumber(numberOfItems);

const getAttendanceByDate = async (date) => {
  const { data } = await axiosInstance.get(`api/v1/attendances?date=${date}`);
  return data.data;
};
export const getAttendanceByDateFetcher = (date) => getAttendanceByDate(date);

const getEmployeeWithPermission = async () => {
  const adminData = [];
  const userData = [];

  const response = await axiosInstance.get("api/v1/employees/permissions/list");
  const { data } = response;

  data.data.forEach((datum) => {
    if (datum.role !== "mid_level_admin") {
      userData.push(datum);
    } else {
      adminData.push(datum);
    }
  });

  return {
    adminRole: adminData,
    userRole: userData,
    allRoles: data.data,
  };
};
export const getEmployeeWithPermissionFetcher = () =>
  getEmployeeWithPermission();

const getPermissionByBranchId = async (branchID) => {
  const adminData = [];
  const userData = [];

  const response = await axiosInstance.get(
    `api/v1/employees/permissions/list?branch_id=${branchID}`
  );
  const { data } = response;

  data.data.forEach((datum) => {
    if (datum.role !== "mid_level_admin") {
      userData.push(datum);
    } else {
      adminData.push(datum);
    }
  });

  return {
    adminRole: adminData,
    userRole: userData,
    // allRoles: data.data,
  };
};
export const getPermissionByBranchIdFetcher = (branchID) =>
  getPermissionByBranchId(branchID);

const searchEmployeeByBranchID = async (branchID) => {
  const { data } = await axiosInstance.get(
    `api/v1/employees/search?branch_id=${branchID}`
  );
  return data.data;
};
export const searchEmployeeByBranchIDFetcher = (branchID) =>
  searchEmployeeByBranchID(branchID);

export const grantEmployeeAccess = async (employee) => {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/employees/permissions/grant-access`,
      employee
    );
    return data.status;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const revokeEmployeeAccess = async (email) => {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/employees/permissions/revoke-access`,
      {
        email,
      }
    );
    return data.status;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getDevices = async () => {
  const { data } = await axiosInstance.get(`api/v1/devices`);
  return data.data.data;
};
export const getDevicesFetcher = () => getDevices();

const getDevicesByBranchId = async (deviceId) => {
  const { data } = await axiosInstance.get(
    `api/v1/devices?branch_id=${deviceId}`
  );
  return data.data.data;
};
export const getDevicesByBranchIdFetcher = (deviceId) =>
  getDevicesByBranchId(deviceId);

export async function lockDevice(deviceId) {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/devices/${deviceId}/lock`
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function unlockDevice(deviceId) {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/devices/${deviceId}/unlock`
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function updateHardwareDevice(details) {
  try {
    const { data } = await axiosInstance.put(
      `api/v1/devices/${details.deviceId}`,
      details
    );
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export const createPassword = async (details, id, expires, signature) => {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/employees/${id}/onboard?expires=${expires}&signature=${signature}`,
      details
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

const getCurrentUser = async () => {
  const { data } = await axiosInstance.get("api/v1/auth/user");
  return data.data;
};
export const getCurrentUserFetcher = () => getCurrentUser();

const getCapacityUsers = async () => {
  const { data } = await axiosInstance.get(
    "api/v1/places/employees-currently-present"
  );
  return data.data;
};
export const getCapacityUsersFetcher = () => getCapacityUsers();

const getCurrentCapacity = async () => {
  const { data } = await axiosInstance.get("api/v1/places/current-occupancy");
  return data.data;
};
export const getCurrentCapacityFetcher = () => getCurrentCapacity();

export async function refreshToken() {
  try {
    const { data } = await axiosInstance.get("api/v1/auth/refresh");
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
}

export async function updateBranch(url, details) {
  const { id } = details.arg;
  const info = details.arg;
  const { data } = await axiosInstance.put(`api/v1/branches/${id}`, info);
  return data;
}

export const deleteBranchById = async (id) => {
  try {
    const { data } = await axiosInstance.delete(`api/v1/branches/${id}`);
    return { message: data.message, status: data.status };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export async function registerSuperAdmin(
  details,
  organization,
  token,
  expires,
  signature
) {
  const { data } = await axios.post(
    `https://api-dev.luchismart.com/api/v1/admin/organizations/${organization}/users?token=${token}&expires=${expires}&signature=${signature}`,
    details
  );
  return data;
}

export async function createPosition(details) {
  try {
    const { data } = await axiosInstance.post(`api/v1/positions`, details);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function archivePosition(id) {
  try {
    const { data } = await axiosInstance.delete(`api/v1/positions/${id}`);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function delPosition(id) {
  try {
    const { data } = await axiosInstance.delete(
      `api/v1/positions/${id}/delete`
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
export async function editPosition(id, values) {
  try {
    const { data } = await axiosInstance.put(`api/v1/positions/${id}`, values);
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

const getCredentials = async (type) => {
  const mediaType = type.toUpperCase();
  const { data } = await axiosInstance.get(
    `api/v1/organizations/credentials?credential=${mediaType}`
  );
  return data.data;
};
export const getCredentialFetchers = (type) => getCredentials(type);

export async function updateCredential(credDetails) {
  try {
    const { data } = await axiosInstance.post(
      "api/v1/organizations/credentials",
      credDetails
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function attendanceClockin(value) {
  try {
    const { data } = await axiosInstance.post("api/v1/attendances/sign-in", {
      credential: value,
    });
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export async function attendanceClockout(attendanceId) {
  try {
    const { data } = await axiosInstance.patch(
      `v1/attendances/${attendanceId}/sign-out`
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}

const getAllEvents = async () => {
  const { data } = await axiosInstance.get("api/v1/events");
  return data.data;
};
export const getAllEventsFetcher = () => getAllEvents();

const getEventsByType = async (type) => {
  const { data } = await axiosInstance.get(`api/v1/events?type=${type}`);
  return data.data;
};
export const getEventsByTypeFetcher = (type) => getEventsByType(type);

const getEventsByDate = async (date) => {
  const { data } = await axiosInstance.get(`api/v1/events?date=${date}`);
  return data.data;
};
export const getEventsByDateFetcher = (date) => getEventsByDate(date);

export async function configureDevice(details) {
  try {
    const { data } = await axiosInstance.post(
      `api/v1/devices/${details.deviceId}/configure`,
      {
        terminal_sn: details.terminal_sn, // Optional
        cpu_sn: details.cpu_sn, // Optional
        model_name: details.model_name, // Optional
        ip: details.ip_address, // Optional, for control panel devices
        port: details.port_number, // Optional, for control panel devices
      }
    );
    return data;
  } catch (error) {
    console.error(error);
    return error;
  }
}
