import useFetchPositions from "../../hooks/useFetchPositions";
import { Select } from "@mantine/core";

export default function PositionSelect({ form }) {
  const [allPositions] = useFetchPositions();
  return (
    <Select
      placeholder="Pick position"
      label="Employee Position"
      size="lg"
      searchable
      nothingFound="no positions"
      clearable
      className="w-full"
      data={allPositions && allPositions}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },
          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
      {...form.getInputProps("positionID")}
    />
  );
}
