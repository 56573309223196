import { format, getHours, getMinutes, getMonth, getYear } from "date-fns";
import { notifications } from "@mantine/notifications";
import Cookies from "js-cookie";
import { logout } from "./requests";

export function getAttendanceTime(date) {
  const hour = date ? getHours(new Date(date)) : "-";
  const minute = date ? getMinutes(new Date(date)) : "-";

  if (hour > 11) {
    return `${hour} : ${minute} PM`;
  } else if (hour < 11) {
    return `${hour} : ${minute} AM`;
  }
  return `${hour} : ${minute}`;
}

export function getMonthYear(date) {
  const year = getYear(new Date(date));
  const formatedMonth = format(new Date(date), "MMMM");
  return `${formatedMonth}, ${year}`;
}

export function getDayMonthYear(date) {
  const year = getYear(new Date(date));
  const formatedMonth = format(new Date(date), "MMMM");
  const formatedDate = format(new Date(date), "dd");
  return `${formatedDate}, ${formatedMonth} ${year}`;
}

export function getAttendanceDate(date) {
  const result = format(new Date(date), "MM-dd-yyyy");
  return result;
}

export function getMonthYearNumber(date) {
  const year = getYear(new Date(date));
  const month = getMonth(new Date(date)) + 1;
  const result = `${month}-${year}`;
  return result;
}

export function monthYearQuery(date) {
  const year = getYear(new Date(date));
  const formatedMonth = format(new Date(date), "MMMM");
  return `${formatedMonth}-${year}`;
}

export function getDayMonthYearQuery(date) {
  const year = getYear(new Date(date));
  const month = getMonth(new Date(date)) + 1;
  const formatedDate = format(new Date(date), "dd");
  return `${year}-${month}-${formatedDate}`;
}

export function successNotify(title, message) {
  return notifications.show({
    title,
    message,
    color: "cyan",
    styles: (theme) => ({
      title: { color: "black", fontSize: 16, textTransform: "uppercase" },
      description: { color: "black" },
    }),
  });
}

export function failedNotify(title, message) {
  return notifications.show({
    title,
    message,
    color: "red",
    styles: (theme) => ({
      title: { color: "black", fontSize: 16, textTransform: "uppercase" },
      description: { color: "black" },
    }),
  });
}

export function cautionNotify(title, message) {
  return notifications.show({
    title,
    message,
    color: "yellow",
    styles: (theme) => ({
      title: { color: "black", fontSize: 16, textTransform: "uppercase" },
      description: { color: "black" },
    }),
  });
}

export function cancelEdit(setTempValue, navigate, url) {
  setTempValue("");
  navigate(url);
  return;
}

export function handleEdit(setTempValue, navigate, url, data) {
  setTempValue(data);
  navigate && navigate(url);
}

export const requirements = [
  { re: /[0-9]/, label: "Includes number" },
  { re: /[a-z]/, label: "Includes lowercase letter" },
  { re: /[A-Z]/, label: "Includes uppercase letter" },
  { re: /[$&+,:;=?@#|'<>.^*()%!-]/, label: "Includes special symbol" },
];

export function getStrength(password) {
  let multiplier = password.length > 7 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export async function logoutOfApp() {
  window.location.replace("/signin");
  logout();
  Cookies.remove("userToken");
  localStorage.clear();
  return null;
}
