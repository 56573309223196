import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { useParams } from "react-router-dom";
import { getAttendanceTime } from "../../services/functions";

export default function EmployeeDetailsModal({ open, setOpen, attendance }) {
  const closeModal = () => setOpen(false);
  const { date, day } = useParams();

  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-20" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="text-left w-full max-w-md transform overflow-hidden rounded-md bg-white p-4 align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg uppercase font-medium text-black/[0.85]  "
                  >
                    {`${day.split("-")[1]}, `} {date.split("-").join(" ")}
                  </Dialog.Title>
                  <div className="divider my-1"></div>
                  <div className="text-base text-black/70 space-y-[4px]">
                    <p>
                      <span className="font-medium mr-2">Employee Name:</span>
                      <span className="text-sm capitalize">
                        {attendance && attendance.employee.name}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Employee ID:</span>
                      <span className="text-sm">
                        {attendance && attendance.employee.staff_id
                          ? attendance.employee.staff_id
                          : "NIL"}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Email Address:</span>
                      <span className="text-sm">
                        {attendance && attendance.employee.email}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Department:</span>
                      <span className="text-sm capitalize">
                        {attendance && attendance.employee
                          ? attendance.employee.department.name
                          : "NIL"}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Position:</span>
                      <span className="text-sm capitalize">
                        {attendance && attendance.employee
                          ? attendance.employee.position.name
                          : "NIL"}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Credential:</span>
                      <span className="text-sm capitalize">
                        {attendance && attendance.credential}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Signin:</span>
                      <span className="text-sm capitalize">
                        {getAttendanceTime(attendance && attendance.signin_at)}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Signout:</span>
                      <span className="text-sm capitalize">
                        {getAttendanceTime(attendance && attendance.signout_at)}
                      </span>
                    </p>
                    <p>
                      <span className="font-medium mr-2">Time at work:</span>
                      <span className="text-sm capitalize">
                        {attendance && attendance.time_at_work}
                      </span>
                    </p>
                  </div>

                  <div className="mt-5 space-x-[37px] w-full flex justify-end ">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-[#07939BCC] text-white px-4 py-2 text-sm font-medium hover:bg-[#07939BCC]/70 "
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
