import { useParams } from "react-router-dom";
import { emailPreferenceDetails } from "../../../assets/data/userProfileBoard";
import LocalBreadcrumb from "../../../components/breadcrumb/LocalBreadcrumb";
import { Box, Divider, Switch, Text, Title } from "@mantine/core";
import { useState } from "react";

export default function EmailPreferences() {
  const param = useParams();
  const [value, setValue] = useState([]);
  return (
    <div className="w-full max-w-7xl     ">
      <>
        <LocalBreadcrumb
          prev="profile board"
          current="email prefences"
          link={`user/${param.userId}`}
          header="email prefences"
        />
      </>
      <Box mt={30} className="w-full max-w-[851px] ">
        <Switch.Group size="lg" value={value} onChange={setValue}>
          {emailPreferenceDetails.map((item, index) => (
            <Box key={item.id}>
              <Box className="flex-1">
                <Title className="text-lg font-medium text-black  ">
                  {item.heading}
                </Title>
                <div className="flex justify-between items-center">
                  <Text className="font-normal text-sm  text-black/70">
                    {item.description}
                  </Text>
                  <div className="w-[64px]">
                    <Switch
                      onLabel="ON"
                      offLabel="OFF"
                      value={item.value}
                      color="cyan"
                    />
                  </div>
                </div>
              </Box>
              {index !== emailPreferenceDetails.length - 1 && (
                <Divider my={16} />
              )}
            </Box>
          ))}
        </Switch.Group>
      </Box>
    </div>
  );
}
