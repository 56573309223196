import useSWR from "swr";
import {
  getEmployeeWithPermissionFetcher,
  getPermissionByBranchIdFetcher,
} from "../services/requests";

export default function useFetchPermissions() {
  const {
    data: permissions,
    isLoading,
    error,
  } = useSWR("api/getEmployeeWithPermission", getEmployeeWithPermissionFetcher);

  return {
    permissions,
    isLoading,
    error,
  };
}

export function useFetchPermissionsByBranchId(branchID) {
  const {
    data: branchPerms,
    isLoading: loading,
    error: fail,
  } = useSWR(branchID ? branchID : null, getPermissionByBranchIdFetcher);

  return {
    branchPerms,
    loading,
    fail,
  };
}
