import { Modal, Text, Box, Button } from "@mantine/core";
import { logoutOfApp } from "../../services/functions";

export default function LogoutModal({
  opened,
  close,
  title,
  subtitle,
  btnText,
  type,
}) {
  function handleLogout() {
    if (type.toLowerCase() !== "all") {
      logoutOfApp();
    } else {
      console.log("Log out of  all sessions");
    }
  }
  return (
    <>
      <Modal
        opened={opened}
        onClose={close}
        centered
        withCloseButton={true}
        title="Logout user"
      >
        <Text className="text-center text-base font-semibold text-black/[0.85] ">
          {title}
        </Text>
        <Text className="text-center text-xs text-yellow-600">{subtitle}</Text>
        <Box mt={20} className="w-full flex justify-center space-x-4">
          <Button
            className="border-luchiPrimary text-luchiPrimary font-medium capitalize border"
            type="button"
            onClick={close}
            variant="white"
          >
            Cancel
          </Button>
          <Button
            onClick={handleLogout}
            variant="white"
            type="button"
            className="font-medium bg-red-700 text-white capitalize"
          >
            {btnText}
          </Button>
        </Box>
      </Modal>
    </>
  );
}
