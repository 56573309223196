import React from "react";
import { leaderboard } from "../../../../assets/data/leaderboard";
import { Paper, Table, Box, Title, Text, Group } from "@mantine/core";

const StandardUserAttendance = () => {
  return (
    <Paper
      withBorder
      shadow="md"
      p="md"
      className="w-full max-w-[500px] bg-white "
    >
      <Box className="mb-4">
        <Title mb={4} className="text-lg font-bold font-poppins text-black ">
          Time and Attendance
        </Title>
        <Group spacing={5} className="text-base">
          <Text className="text-black font-medium font-poppins text-sm">
            Branch:
          </Text>
          <Text className="font-normal text-black font-poppins text-sm">
            Abuja
          </Text>
        </Group>
      </Box>

      <Box>
        <TimeAttendanceTable />
      </Box>
    </Paper>
  );
};

export default StandardUserAttendance;

function TimeAttendanceTable() {
  const ths = (
    <tr>
      <th>Department</th>
      <th>Employee</th>
      <th>Status</th>
    </tr>
  );

  const rows = leaderboard.map((leader) => (
    <tr key={leader.department}>
      <td>{leader.department}</td>
      <td>{leader.employee}</td>
      <td>
        <Group spacing={1}>
          {leader.status === "Present" ? (
            <Box className="w-2 rounded-full mr-2 h-2 bg-green-700"></Box>
          ) : (
            <Box className="w-2 mr-2 h-2 rounded-full bg-red-900"></Box>
          )}
          {leader.status}
        </Group>
      </td>
    </tr>
  ));

  return (
    <Table striped fontSize="sm">
      <thead>{ths}</thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
