import { Chart } from "react-google-charts";

export const data = [
  ["Reviews", "percentage"],
  ["Operations", 600],
  ["Marketing", 400],
  ["Technology", 400],
  ["Human Resources", 100],
];

export const options = {
  pieHole: 0.4,
  is3D: false,
  legend: { position: "none" },
  titleTextStyle: {
    color: "#686868",
    fontName: "Poppins",
    fontSize: 16,
    bold: false,
    italic: false,
  },

  colors: ["#FDC5C5", "#77FF9D", "#F0D46E", "#699CFF"],
  chartArea: {
    height: "70%",
    width: "94%",
  },
};

export default function ProductivityPieChart() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width={"100%"}
      height={"300px"}
    />
  );
}
