import { useState } from "react";
import { isEmail, isNotEmpty, useForm } from "@mantine/form";
import {
  Box,
  Button,
  Group,
  Image,
  Paper,
  PasswordInput,
  Popover,
  Progress,
  ScrollArea,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { BsCheck } from "react-icons/bs";
import { MdOutlineCancel } from "react-icons/md";
import logo from "../assets/logo.svg";
import {
  cautionNotify,
  failedNotify,
  getStrength,
  requirements,
  successNotify,
} from "../services/functions";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import { registerSuperAdmin } from "../services/requests";

export default function CreateSuperAdmin() {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const token = Cookies.get("userToken");
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [queryParameters] = useSearchParams();

  // Redirect authenticated users
  useEffect(() => {
    if (token) {
      navigate("/dashboard");
    }
  });
  const form = useForm({
    initialValues: {
      oneTimePassword: "",
      newPassword: "",
      confirmPassword: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    },

    validate: {
      newPassword: (value) => {
        const newCheck = checks
          .map((check) => check.props.meets)
          .every(Boolean);
        return newCheck ? null : "Invalid password";
      },
      confirmPassword: (value, values) =>
        value !== values.newPassword ? "Passwords did not match" : null,
      firstName: isNotEmpty("Compulsory field"),
      lastName: isNotEmpty("Compulsory field"),
      oneTimePassword: isNotEmpty("Compulsory field"),
      phone: isNotEmpty("Compulsory field"),
      email: isEmail("Invalid email"),
    },
  });

  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(form.getInputProps("newPassword").value)}
    />
  ));
  const strength = getStrength(form.getInputProps("newPassword").value);
  const color = strength === 100 ? "cyan" : strength > 50 ? "yellow" : "red";

  async function createSuperAdmin(values, event) {
    event.preventDefault();
    setLoader(true);
    const details = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      phone_number: values.phone,
      setup_password: values.oneTimePassword,
      password: values.newPassword,
      password_confirmation: values.confirmPassword,
    };

    try {
      const res = await registerSuperAdmin(
        details,
        queryParameters.get("organization"),
        queryParameters.get("token"),
        queryParameters.get("expires"),
        queryParameters.get("signature")
      );
      if (res.status) {
        successNotify("Notification", res.message);
        form.reset();
        setTimeout(() => navigate("/signin"), 2000);
      } else {
        cautionNotify("Notification", res.message);
      }
    } catch (error) {
      console.error(error);
      failedNotify("Notification", error.response.data.message);
    }

    setLoader(false);
  }
  return (
    <main className=" bg-white flex justify-center md:items-center lg:items-start 2xl:items-center">
      <ScrollArea className="w-full p-4">
        <Paper
          withBorder
          radius={0}
          p={0}
          className="w-full max-w-lg shadow-lg px-6 py-3 mx-auto"
        >
          <Group spacing={10} position="apart" className="">
            <Title className="text-xl font-semibold font-display capitalize text-luchiPrimary">
              create account
            </Title>
            <div className="w-[135px] ">
              <Image
                src={logo}
                alt="Luchismart logo"
                className="w-full h-full"
              />
            </div>
          </Group>
          <form
            onSubmit={form.onSubmit((values, e) => createSuperAdmin(values, e))}
            className="w-full mt-2 "
          >
            <Box className="flex flex-col md:flex-row md:space-x-4">
              <TextInput
                className="w-full"
                withAsterisk
                label="First Name"
                placeholder="John"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("firstName")}
              />
              <TextInput
                className="w-full"
                withAsterisk
                label="Last Name"
                placeholder="Okon"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("lastName")}
              />
            </Box>

            <Box className="flex flex-col md:flex-row md:space-x-4">
              <TextInput
                className="w-full"
                withAsterisk
                label="Email"
                placeholder="johnokon@luchismart.com"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("email")}
              />
              <TextInput
                className="w-full"
                withAsterisk
                label="Phone"
                placeholder="+2347089436754"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("phone")}
              />
            </Box>

            <Box className="w-full">
              <Popover
                opened={popoverOpened}
                position="bottom"
                width="target"
                transition="pop"
              >
                <Popover.Target>
                  <div
                    onFocusCapture={() => setPopoverOpened(true)}
                    onBlurCapture={() => setPopoverOpened(false)}
                  >
                    <PasswordInput
                      withAsterisk
                      label="New Password"
                      size="lg"
                      radius={4}
                      styles={(theme) => ({
                        label: {
                          paddingBottom: 4,
                          fontSize: 16,
                          marginTop: 16,
                          fontWeight: 400,
                        },
                        input: {
                          fontSize: 14,
                          borderWidth: 1,
                          "&:focus": {
                            borderColor: theme.colors.luchiPrimary[0],
                          },
                        },
                      })}
                      {...form.getInputProps("newPassword")}
                    />
                  </div>
                </Popover.Target>
                <Popover.Dropdown>
                  <Progress
                    color={color}
                    value={strength}
                    size={5}
                    style={{ marginBottom: 10 }}
                  />
                  <PasswordRequirement
                    label="Includes at least 8 characters"
                    meets={form.getInputProps("newPassword").value.length > 7}
                  />
                  {checks}
                </Popover.Dropdown>
              </Popover>

              <PasswordInput
                withAsterisk
                label="Confirm Password"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("confirmPassword")}
              />
            </Box>
            <Box>
              <PasswordInput
                withAsterisk
                label="OneTime Default Password"
                size="lg"
                radius={4}
                styles={(theme) => ({
                  label: {
                    paddingBottom: 4,
                    fontSize: 16,
                    marginTop: 16,
                    fontWeight: 400,
                  },
                  input: {
                    fontSize: 14,
                    borderWidth: 1,
                    "&:focus": {
                      borderColor: theme.colors.luchiPrimary[0],
                    },
                  },
                })}
                {...form.getInputProps("oneTimePassword")}
              />
            </Box>

            <Button
              mt={16}
              type="submit"
              variant="white"
              fullWidth
              loading={loader}
              loaderPosition="right"
              className="bg-luchiPrimary capitalize font-medium text-white"
            >
              create
            </Button>
          </form>
        </Paper>
      </ScrollArea>
    </main>
  );
}

function PasswordRequirement({ meets, label }) {
  return (
    <Text
      color={meets ? "teal" : "red"}
      sx={{ display: "flex", alignItems: "center" }}
      mt={7}
      size="sm"
    >
      {meets ? <BsCheck size={14} /> : <MdOutlineCancel size={14} />}{" "}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}
