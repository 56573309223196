import { Box, createStyles } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { getAttendanceTime } from "../../services/functions";

const PAGE_SIZES = [50];

export default function DataEvent({ data, isLoading }) {
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = data && data.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);
  const { classes } = useStyles();

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(data && data.slice(from, to));
  }, [page, pageSize, data]);

  return (
    <>
      <Box className="h-[350px]  ">
        <>
          <DataTable
            classNames={classes}
            withBorder
            withColumnBorders
            records={records}
            textSelectionDisabled
            fetching={isLoading}
            loaderSize="md"
            loaderColor="#37A7AE"
            minHeight={150}
            noRecordsText="No records to show"
            fontSize="sm"
            columns={[
              {
                accessor: "created_at",
                title: "Time",
                width: 150,
                ellipsis: true,
                render: ({ created_at }) => getAttendanceTime(created_at),
              },
              {
                accessor: "event_name",
                title: "Event",
                width: 200,
                ellipsis: true,
                cellsStyle: {
                  textTransform: "capitalize",
                },
              },
              { accessor: "credential", width: 150 },
              {
                accessor: "user.first_name",
                title: "Accessed by",
                width: 300,
                ellipsis: true,
                cellsStyle: {
                  textTransform: "capitalize",
                },
                render: ({ user }) => `${user.first_name} ${user.last_name}`,
              },
            ]}
            selectedRecords={selectedRecords}
            onSelectedRecordsChange={setSelectedRecords}
            totalRecords={data && data.length}
            recordsPerPage={pageSize}
            page={page}
            onPageChange={(p) => setPage(p)}
            // recordsPerPageOptions={PAGE_SIZES}
            onRecordsPerPageChange={setPageSize}
          />
          {/* <Paper my="xl" py="xl" withBorder radius={0}>
            <Center>
              <Button
                uppercase
                color="red"
                disabled={!selectedRecords.length}
                onClick={() =>
                  showNotification({
                    color: "red",
                    message: "Deleting data is dangerous!",
                  })
                }
              >
                {selectedRecords.length
                  ? `Delete ${
                      selectedRecords.length === 1
                        ? "one selected record"
                        : `${selectedRecords.length} selected records`
                    }`
                  : "Select records to delete"}
              </Button>
            </Center>
          </Paper> */}
        </>
      </Box>
    </>
  );
}

const useStyles = createStyles((theme) => ({
  pagination: {
    background: "transparent",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.white
        : theme.colors.luchiPrimary[0],
    'button[data-active="true"]': {
      background: theme.colors.luchiPrimary[0],
    },
    'button[data-active="true"]:not([data-disabled="true"]):hover': {
      background: theme.colors.luchiPrimary[0],
    },
  },
}));
