import React from "react";
import { Group, Text, Title, Box } from "@mantine/core";
import { useCurrentUser } from "../../hooks/useCurrentUser";

const DashboardTop = () => {
  const { user } = useCurrentUser();
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  return (
    <Box className="flex justify-between w-full mb-10 ">
      <Box className="text-black">
        {user?.organization?.name && (
          <Title className="text-2xl font-bold leading-6 capitalize">
            {user?.organization?.name}
          </Title>
        )}
        {user?.organization?.name && (
          <Group
            spacing={5}
            mt={6}
            className="font-normal text-sm leading-6 capitalize "
          >
            <Text> Hello,</Text>
            <Text className="font-semibold text-base mx-1">{`${user?.first_name} ${user?.last_name}`}</Text>
          </Group>
        )}
      </Box>
      {user?.organization?.name && (
        <Box className="text-luchiPrimary">
          <Title className="text-base font-medium ">
            {user?.branch?.location ? user?.branch?.location : "Uknown"}
          </Title>
          <Text className="text-xs font-medium ">{date}</Text>
        </Box>
      )}
    </Box>
  );
};

export default DashboardTop;
