import { Group, Switch, Title } from "@mantine/core";

export default function CredentialsSwitch({ form }) {
  return (
    <>
      <Title mb={7} className="text-base capitalize font-poppins font-medium ">
        Credentials
      </Title>
      <Group>
        <Switch
          label="Fingerprint"
          color="cyan"
          {...form.getInputProps(`credentials.FINGERPRINT`, {
            type: "checkbox",
          })}
        />
        <Switch
          label="RFID Card"
          color="cyan"
          {...form.getInputProps(`credentials.RFID_CARD`, {
            type: "checkbox",
          })}
        />
        <Switch
          label="Mobile App"
          color="cyan"
          {...form.getInputProps(`credentials.MOBILE_APP`, {
            type: "checkbox",
          })}
        />
        <Switch
          label="PIN"
          color="cyan"
          {...form.getInputProps(`credentials.PIN`, {
            type: "checkbox",
          })}
        />
      </Group>
    </>
  );
}
