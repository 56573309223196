import {
  Group,
  Avatar,
  Text,
  Accordion,
  Box,
  Title,
  Divider,
  Paper,
  SimpleGrid,
  Image,
} from "@mantine/core";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Link } from "react-router-dom";

export default function AppUrls() {
  return (
    <main className="w-full">
      <Box className="w-full">
        <Link to="/reports">
          <Group spacing={4} mb={10}>
            <AiOutlineArrowLeft size={16} />
            <Text className="capitalize font-poppins text-lg">report</Text>
          </Group>
        </Link>
        <Title className="font-medium text-2xl font-poppins text-black mb-5 capitalize">
          app & URLS
        </Title>
        <Box mb={29}></Box>
        <Box>
          <AccordionBox />
        </Box>
      </Box>
    </main>
  );
}

const charactersList = [
  {
    id: "bender",
    image: "https://img.icons8.com/clouds/256/000000/futurama-bender.png",
    label: "operations department",
    description: "27",
  },

  {
    id: "carol",
    image: "https://img.icons8.com/clouds/256/000000/futurama-mom.png",
    label: "human resources",
    description: "100",
  },

  {
    id: "homer",
    image: "https://img.icons8.com/clouds/256/000000/homer-simpson.png",
    label: "information technology",
    description: "30",
  },
];

function AccordionLabel({ label, image, description }) {
  return (
    <Group noWrap spacing={50}>
      <div>
        <Text tt="capitalize">{label}</Text>
        <Text size="sm" color="dimmed" weight={400}>
          {description > 1
            ? `${description} employees`
            : `${description} employee`}
        </Text>
      </div>
      <Avatar.Group spacing="sm">
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar src={image} color="cyan" radius="xl" w={42} h={42} />
        <Avatar color="cyan" radius="xl" w={42} h={42}>
          +5
        </Avatar>
      </Avatar.Group>
    </Group>
  );
}

function AccordionBox() {
  const items = charactersList.map((item, index) => (
    <Accordion.Item mb={20} value={item.id} key={item.label} maw={1090}>
      <Accordion.Control>
        <AccordionLabel {...item} />
      </Accordion.Control>
      <Accordion.Panel>
        <Box>
          <Text
            size="sm"
            color="dimmed"
            className="font-poppins text-sm capitalize text-black"
          >
            team task
          </Text>
          <Text
            size="sm"
            color="dimmed"
            className="font-poppins text-xs capitalize"
          >
            book branding system
          </Text>
        </Box>
        <Divider my={10} />
        <Paper bg="transparent">
          <Group mb={14} spacing={2}>
            <Box h={42} w={42}>
              <Avatar color="red" radius="xl" />
            </Box>
            <Box>
              <Text tt="capitalize" fz={14} fw={600}>
                James mark
              </Text>
              <Text tt="capitalize" fz={12}>
                graphics designer
              </Text>
            </Box>
          </Group>
          <SimpleGrid
            cols={5}
            spacing="sm"
            breakpoints={[
              { maxWidth: "62rem", cols: 3, spacing: "sm" },
              { maxWidth: "48rem", cols: 2, spacing: "sm" },
              { maxWidth: "36rem", cols: 1, spacing: "sm" },
            ]}
          >
            {[1, 2, 3, 4, 5].map((item, index) => (
              <Paper
                key={index}
                className="w-full max-w-[164px] overflow-hidden "
              >
                <Box className="w-full h-[84px] overflow-hidden">
                  <Image
                    maw={164}
                    src="/webDev.png"
                    height={84}
                    fit="contain"
                    withPlaceholder
                    alt="user activity snapshoot"
                  />
                </Box>
                <Box p={6}>
                  <Text fz={13}>www.facebook.com</Text>
                  <Text fz={13}>9:10 - 10:30</Text>
                </Box>
              </Paper>
            ))}
          </SimpleGrid>
        </Paper>
      </Accordion.Panel>
    </Accordion.Item>
  ));

  return (
    <Accordion chevronPosition="right" variant="contained">
      {items}
    </Accordion>
  );
}

// function ImageCard() {
//   return (
//     <Card
//       shadow="sm"
//       padding="lg"
//       withBorder
//       radius="xs"
//       px={0}
//       className="w-[164px] bg-green-600 h-[124px]"
//     >
//       <Card.Section></Card.Section>
//       <Group position="apart" mt="md" mb="xs">
//         <Text weight={500}>Norway Fjord Adventures</Text>
//         <Badge color="pink" variant="light">
//           On Sale
//         </Badge>
//       </Group>
//     </Card>
//   );
// }
