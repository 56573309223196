import UtilityHeader from "../../../components/headers/UtilityHeader";
import { BiMessageSquareAdd } from "react-icons/bi";
import {
  BsCreditCardFill,
  BsFillCalendar2MonthFill,
  BsCreditCard2Front,
} from "react-icons/bs";
import { useState, useRef } from "react";
import { FcCheckmark } from "react-icons/fc";
import { FaCcVisa } from "react-icons/fa";

export default function PayPal(params) {
  const [showForm, setShowForm] = useState(false);
  const country = useRef(null);
  const cardNumberRef = useRef(null);
  const cardType = useRef(null);
  const expiryDateRef = useRef(null);
  const cvvRef = useRef(null);
  const zipCodeRef = useRef(null);
  const openModalRef = useRef(null);
  const firstNameRef = useRef(null);
  const lastNameRef = useRef(null);

  function handleShow() {
    setShowForm((showForm) => !showForm);
  }

  function sendCardDetails(event) {
    event.preventDefault();
    openModalRef.current.click();
    if (
      !country.current.value ||
      !cardNumberRef.current.value ||
      !cardType.current.value ||
      !expiryDateRef.current.value ||
      !cvvRef.current.value ||
      !zipCodeRef.current.value ||
      !firstNameRef.current.value ||
      !lastNameRef.current.value
    ) {
      // use toast to alert: all details must be entered
      console.log(country.current.value);
    } else {
      // send information
      openModalRef.current.click();
    }
  }
  return (
    <div className="w-full pr-4 max-w-7xl pb-52  ">
      <div className="w-full mb-8">
        <UtilityHeader
          title={"billing"}
          subtitle={"billings"}
          backLink={"/organization-setup/billings"}
        />
      </div>
      <div className="w-full">
        <p className="font-medium text-xl text-black">
          Payment method:
          <span className="text-base ml-2">Paypal</span>
        </p>
        <div className="mt-3">
          <div
            onClick={handleShow}
            className="flex items-center space-x-1 text-[#37A7AE] mb-5 cursor-pointer w-fit"
          >
            <div>
              <BiMessageSquareAdd className="w-6 h-6 " />
            </div>
            <p className=" font-normal text-xl capitalize">add details</p>
          </div>
          {showForm && (
            <div className="w-full max-w-md bg-white p-6">
              <form onSubmit={sendCardDetails} action="" className="w-full">
                <p className="capitalize text-black font-medium text-lg text-center mb-5">
                  Paypal
                </p>
                <div>
                  <div className="form-control w-full">
                    <label className="label">
                      <span className="label-text-alt text-sm text-black/[85] font-medium">
                        Country
                      </span>
                    </label>
                    <input
                      ref={country}
                      type="text"
                      name="country"
                      placeholder="Enter country"
                      className="input input-bordered w-full border-gray-400 bg-transparent"
                    />
                  </div>

                  <div className="form-control w-full my-2">
                    <label className="label">
                      <span className="label-text-alt text-sm text-black/[85] font-medium">
                        Card type
                      </span>
                    </label>
                    <select
                      ref={cardType}
                      name="cardType"
                      className="select select-bordered border-gray-400 bg-transparent w-full text-black"
                    >
                      <option>Mastercard</option>
                      <option>Visa</option>
                      <option>Verve</option>
                    </select>
                  </div>

                  <div className="form-control w-full my-2">
                    <label className="label">
                      <span className="label-text-alt text-sm text-black/[85] font-medium">
                        Card number
                      </span>
                    </label>
                    <label className="input-group">
                      <span className="border border-gray-400 bg-transparent">
                        <BsCreditCard2Front className="w-6 h-6" />
                        {/* <img
                          src="/mastercard.png"
                          alt="mastercard logo"
                          className="w-9 h-8"
                        /> */}
                      </span>
                      <input
                        ref={cardNumberRef}
                        type="text"
                        name="cardNumber"
                        placeholder="5555 5555 5555 5555"
                        className="input input-bordered border-gray-400 bg-transparent w-full"
                      />
                    </label>
                  </div>

                  <div className="flex space-x-5 ">
                    <div className="form-control w-full ">
                      <label className="label">
                        <span className="label-text-alt text-sm text-black/[85] font-medium">
                          Expiry date
                        </span>
                      </label>
                      <label className="input-group">
                        <span className="border border-gray-400 bg-transparent">
                          <BsFillCalendar2MonthFill className="w-6 h-6" />
                        </span>
                        <input
                          ref={expiryDateRef}
                          type="text"
                          name="expiryDate"
                          placeholder="MM/YY"
                          className="input input-bordered border-gray-400 bg-transparent w-full"
                        />
                      </label>
                    </div>

                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text-alt text-sm text-black/[85] font-medium">
                          Security code
                        </span>
                      </label>
                      <label className="input-group">
                        <span className="border border-gray-400 bg-transparent">
                          <BsCreditCardFill className="w-6 h-6" />
                        </span>
                        <input
                          ref={cvvRef}
                          type="text"
                          name="securityCode"
                          placeholder="CVV"
                          className="input input-bordered border-gray-400 bg-transparent w-full"
                        />
                      </label>
                    </div>
                  </div>

                  <div className="form-control w-full my-2">
                    <label className="label">
                      <span className="label-text-alt text-sm text-black/[85] font-medium">
                        Zip code
                      </span>
                    </label>
                    <input
                      ref={zipCodeRef}
                      type="text"
                      name="zipCode"
                      placeholder="zip code"
                      className="input input-bordered w-full border-gray-400 bg-transparent"
                    />
                    <p className="text-xs text-black font-normal mt-1">
                      This site is protected by reCAPTCHA and the Google Privacy
                      Policy and Terms of Servive apply. I warrant that this
                      location information is correct.
                    </p>
                  </div>

                  <div className="flex space-x-5">
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text-alt text-sm text-black/[85] font-medium">
                          First name
                        </span>
                      </label>
                      <input
                        ref={firstNameRef}
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                        className="input input-bordered w-full border-gray-400 bg-transparent"
                      />
                    </div>
                    <div className="form-control w-full">
                      <label className="label">
                        <span className="label-text-alt text-sm text-black/[85] font-medium">
                          Last name
                        </span>
                      </label>
                      <input
                        ref={lastNameRef}
                        type="text"
                        name="lastNameRef"
                        placeholder="Enter last name"
                        className="input input-bordered w-full border-gray-400 bg-transparent"
                      />
                    </div>
                  </div>

                  <div className="w-full mt-8 flex justify-center">
                    <button
                      type="submit"
                      className="btn capitalize bg-[#37A7AE] border-none text-white hover:bg-[#37A7AE]"
                    >
                      add details
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
        </div>

        <div className="divider my-3"></div>
        <div className="w-full ">
          <div className="w-full flex justify-between space-x-80 ">
            <div className="w-full">
              <p className="text-black font-medium text-lg">Contact email</p>
              <p className="text-black/60 font-normal text-sm">
                Where should the receipt be sent to?
              </p>
            </div>
            <div className="w-full ">
              <div className="form-control">
                <label className="cursor-pointer label space-x-3 ">
                  <input type="checkbox" className="checkbox checkbox-accent" />
                  <span className="label-text text-black font-medium text-sm flex-1">
                    Send to company branch email <br />
                    <span className="text-black/60 font-normal text-sm">
                      Newyork@chevrontech.com
                    </span>
                  </span>
                </label>
              </div>

              <div className="form-control">
                <label className="cursor-pointer label space-x-3 ">
                  <input type="checkbox" className="checkbox checkbox-accent" />
                  <span className="label-text text-black font-medium text-sm flex-1">
                    Send to my company email <br />
                    <span className="text-black/60 font-normal text-sm">
                      Ethanmonroe@chevrontech.com
                    </span>
                  </span>
                </label>
              </div>

              <div className="form-control">
                <label className="cursor-pointer label space-x-3 ">
                  <input type="checkbox" className="checkbox checkbox-accent" />
                  <span className="label-text text-black font-medium text-sm flex-1 ">
                    Send to an alternative email <br />
                    <span className="text-black/60 font-normal text-sm">
                      <input
                        type="email"
                        name="alternateEmail"
                        placeholder="Enter email"
                        className="input input-bordered w-full bg-transparent border-gray-500 h-10 mt-1"
                      />
                    </span>
                  </span>
                </label>
              </div>
            </div>
          </div>

          <div className="divider my-3"></div>

          <div className="w-full flex justify-between space-x-80 ">
            <div className="w-full">
              <p className="text-black font-medium text-lg">Card details</p>
              <p className="text-black/60 font-normal text-sm">
                Select default payment method
              </p>
            </div>
            <div className="w-full max-w-md p-4 rounded-2xl bg-[#07939B0D] flex space-x-7 ">
              <div>
                <FaCcVisa className="text-[#37A7AE] w-8 h-8" />
              </div>
              <div className="flex-1 flex">
                <div className="flex-1">
                  <p className="text-black/[85] font-medium text-lg">
                    Felistus Ogbonna
                  </p>
                  <p className="text-black/70 font-normal text-sm">
                    Expiry date 11/2023
                  </p>
                  <div className="form-control w-36">
                    <label className="cursor-pointer label">
                      <span className="label-text text-black font-medium text-sm ">
                        Set as default
                      </span>
                      <input
                        type="checkbox"
                        className="checkbox checkbox-accent"
                      />
                    </label>
                  </div>
                </div>

                <div className="w-5 h-5 mx-auto rounded-full border-2 border-green-600 flex justify-center items-center mb-4 ">
                  <FcCheckmark className="w-5 h-5  " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <>
        <label ref={openModalRef} htmlFor="my-modal-6" className="btn hidden">
          success modal
        </label>
        <input type="checkbox" id="my-modal-6" className="modal-toggle" />
        <div className="modal modal-bottom sm:modal-middle ">
          <div className="modal-box bg-white text-center">
            <div className="w-[88px] h-[88px] mx-auto rounded-full border-4 border-green-600 flex justify-center items-center mb-4 ">
              <FcCheckmark className="w-16 h-16 " />
            </div>
            <p className="my-2 text-black font-bold">
              You’ve successfully added a payment method
            </p>
            <p className="text-black/60">You can now easily make payments</p>
            <div className="modal-action w-full flex justify-center ">
              <label
                htmlFor="my-modal-6"
                className="btn capitalize bg-green-600 hover:bg-green-600  text-white border-none "
              >
                continue
              </label>
            </div>
          </div>
        </div>
      </>
    </div>
  );
}
