import { Select } from "@mantine/core";

export default function StatusSelect({ form }) {
  return (
    <Select
      placeholder="Status"
      label="Status"
      size="lg"
      className="w-full"
      data={["present", "absent"]}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },
          fontFamily: theme.fontFamily.poppins[0],
          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
      {...form.getInputProps("status")}
    />
  );
}
