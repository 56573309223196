import { AiOutlineDelete } from "react-icons/ai";
import { Box, Group, ActionIcon, Paper, Text, Loader } from "@mantine/core";
import { useState, useEffect } from "react";
import HeaderBar from "../../../components/headers/HeaderBar";
import { Link } from "react-router-dom";
import { useFetchEvents } from "../../../hooks/useFetchEvents";
import { getMonthYear, monthYearQuery } from "../../../services/functions";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function EventHistory() {
  const { data, isLoading, error } = useFetchEvents();
  const [displayEvents, setDisplayEvents] = useState([]);
  const { user } = useCurrentUser();
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const [branch, setBranch] = useState({
    branchLocation: branchID,
  });

  useEffect(() => {
    // updates branchID
    if (branch.branchLocation) {
      setBranchID(branch.branchLocation);
    }
  }, [branch.branchLocation]);

  useEffect(() => {
    const set = new Set();
    const tempArray = [];
    if (data) {
      data.filter((item) => {
        if (!set.has(getMonthYear(item.created_at))) {
          set.add(getMonthYear(item.created_at));
          tempArray.push(item);
        }
        return tempArray;
      });
      setDisplayEvents(tempArray);
    }
  }, [data]);

  return (
    <div className="w-full pr-4 max-w-7xl pb-8  ">
      <div className="w-full mb-4 flex items-center ">
        <HeaderBar
          title="event history"
          data={branch}
          setData={setBranch}
          user={user}
          show={true}
        />
      </div>
      <div className="w-full h-full relative">
        {isLoading && (
          <Box className="w-full h-60 items-center flex justify-center ">
            <Loader variant="oval" color="cyan" />
          </Box>
        )}
        {error && (
          <Box className="w-full h-60 items-center flex justify-center ">
            <Loader variant="dots" color="red" />
            <Text ml={2}>Error!</Text>
          </Box>
        )}
        {displayEvents?.length === 0 ? (
          <Box className="w-full h-60 items-center flex justify-center ">
            <Text className="text-sm uppercase ">no events yet!</Text>
          </Box>
        ) : (
          displayEvents.map((event) => (
            <Link
              key={event.id}
              to={`/event/monthly-logs/${monthYearQuery(event.created_at)}`}
            >
              <Paper
                shadow="md"
                p="sm"
                className="relative w-full max-w-xl bg-luchiPrimary rounded-md mb-2"
              >
                <Group
                  spacing={8}
                  className="flex items-center absolute top-0 right-2 "
                >
                  <ActionIcon
                    title="delete"
                    className="bg-transparent hover:bg-transparent"
                  >
                    <AiOutlineDelete
                      size="1.125rem"
                      className="hover:text-red-800 text-white"
                    />
                  </ActionIcon>
                </Group>
                <Box className="w-full">
                  <Text className="font-medium text-white text-sm uppercase font-display mb-2.5">
                    {getMonthYear(event.created_at)}
                  </Text>
                  <Text
                    truncate
                    maw={400}
                    className="font-normal text-white text-xs normal-case w-full"
                  >
                    This contains the event logs for this month.
                  </Text>
                </Box>
              </Paper>
            </Link>
          ))
        )}
      </div>
    </div>
  );
}
