import DashboardTop from "../../components/DashboardTop/DashboardTop";
import SuperAdminOverviewCard from "../../components/Users/SuperAdmin/dashboard/SuperAdminOverviewCard";
import SuperAdminWeeklyStatus from "../../components/Users/SuperAdmin/dashboard/SuperAdminWeeklyStatus";
import SuperAdminMonthlyLog from "../../components/Users/SuperAdmin/dashboard/SuperAdminMonthlyLog";
import SuperAdminAttendance from "../../components/Users/SuperAdmin/dashboard/SuperAdminAttendance";

export default function MidLevelAdminDashboard() {
  return (
    <div className="w-full max-w-7xl  ">
      <DashboardTop />
      <div className="flex space-x-[24px] xl:justify-between ">
        <SuperAdminOverviewCard />
        <SuperAdminWeeklyStatus />
      </div>
      <div className="flex mt-[18px] mb-[30px] space-x-[24px] xl:justify-between  ">
        <SuperAdminAttendance />
        <SuperAdminMonthlyLog />
      </div>
    </div>
  );
}
