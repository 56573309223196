import { Select } from "@mantine/core";
import useFetchDepartments from "../../hooks/useFetchDepartments";
import { useFetchBranchDetails } from "../../hooks/team/hooks";
import { useEffect, useState } from "react";

export default function DepartmentSelect({ form, branchID }) {
  const [department] = useFetchDepartments();
  const { teamsInfo } = useFetchBranchDetails(branchID);
  const [branchDepts, setbranchDepts] = useState([]);

  useEffect(() => {
    if (teamsInfo && teamsInfo.teams) {
      const dept = teamsInfo.teams.map((datum) => {
        const deptInfo = {
          value: datum.id,
          label: datum.name,
          description: datum.description,
          branchid: datum.branch_id,
        };
        return deptInfo;
      });
      setbranchDepts([...dept]);
    }
    return;
  }, [teamsInfo]);

  return (
    <Select
      className="w-full"
      placeholder="Select department"
      label="Department"
      size="lg"
      searchable
      nothingFound="no departments"
      clearable
      data={branchID ? branchDepts : department}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },
          fontFamily: theme.fontFamily.poppins[0],
          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
      {...form.getInputProps("deptID")}
    />
  );
}
