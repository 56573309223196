import { Box, Text } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import { useEffect, useState } from "react";
import { dailyData } from "../../data/data";

const PAGE_SIZES = [50];

export default function DailyTotal() {
  const [pageSize, setPageSize] = useState(PAGE_SIZES[0]);
  const initialRecords = dailyData.slice(0, pageSize);
  const [records, setRecords] = useState(initialRecords);
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [pageSize]);

  useEffect(() => {
    const from = (page - 1) * pageSize;
    const to = from + pageSize;
    setRecords(dailyData.slice(from, to));
  }, [page, pageSize]);

  return (
    <>
      <Box className="h-[350px]  ">
        <DataTable
          withBorder
          striped
          withColumnBorders
          textSelectionDisabled
          records={records}
          fontSize={13}
          minHeight={150}
          noRecordsText="No records to show"
          columns={[
            {
              accessor: "name",
              title: "Team Member",
              width: 150,
              ellipsis: true,
              cellsStyle: {
                textTransform: "capitalize",
              },
              render: ({ firstName, lastName, position }) => (
                <>
                  <Text>{`${firstName} ${lastName} `}</Text>
                  <Text fz={12}>{position}</Text>
                </>
              ),
            },
            {
              accessor: "task",
              width: 150,
              ellipsis: true,
            },
            {
              accessor: "monday",
              title: "Mon",
              width: 50,
              ellipsis: true,
              render: ({ monday }) => <Text>{monday}%</Text>,
            },
            {
              accessor: "tuesday",
              title: "Tue",
              width: 50,
              ellipsis: true,
              render: ({ tuesday }) => <Text>{tuesday}%</Text>,
            },
            {
              accessor: "wednesday",
              title: "Wed",
              width: 50,
              ellipsis: true,
              render: ({ wednesday }) => <Text>{wednesday}%</Text>,
            },
            {
              accessor: "thursday",
              title: "Thur",
              width: 50,
              ellipsis: true,
              render: ({ thursday }) => <Text>{thursday}%</Text>,
            },
            {
              accessor: "friday",
              title: "Fri",
              width: 50,
              ellipsis: true,
              render: ({ friday }) => <Text>{friday}%</Text>,
            },
            {
              accessor: "average",
              title: "Avg",
              width: 60,
              ellipsis: true,
              render: ({ average }) => <Text>{average}%</Text>,
            },
            {
              accessor: "time",
              title: "Total Work Time",
              width: 100,
              cellsStyle: {
                textTransform: "capitalize",
              },
            },
          ]}
          totalRecords={dailyData.length}
          paginationColor="cyan"
          recordsPerPage={pageSize}
          page={page}
          onPageChange={(p) => setPage(p)}
          //   recordsPerPageOptions={PAGE_SIZES}
          onRecordsPerPageChange={setPageSize}
        />
      </Box>
    </>
  );
}
