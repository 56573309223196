import {
  AiFillUnlock,
  AiFillLock,
  AiFillEdit,
  AiOutlineSetting,
} from "react-icons/ai";
import { useState } from "react";
import {
  useFetchDevices,
  useFetchDevicesByBranchId,
} from "../../../hooks/team/hooks";
import {
  lockDevice,
  unlockDevice,
  updateHardwareDevice,
} from "../../../services/requests";
import { mutate } from "swr";
import { failedNotify, successNotify } from "../../../services/functions";
import {
  Box,
  Group,
  ActionIcon,
  Paper,
  Text,
  Badge,
  Loader,
  Pagination,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import HeaderBar from "../../../components/headers/HeaderBar";
import { useDisclosure } from "@mantine/hooks";
import EditHardwareModal from "../../../components/modal/EditHardwareModal";
import { useEffect } from "react";
import useCustomPaginate from "../../../hooks/useCustomPagination";
import EditControlPanelDevice from "../../../components/modal/EditControlPanelDevice";
import { useCurrentUser } from "../../../hooks/useCurrentUser";

export default function Hardwares() {
  const { user } = useCurrentUser();
  const [branchID, setBranchID] = useState(
    user?.user_type !== "super_admin" ? user?.branch?.id : ""
  );
  const { devices, filteredData, isLoading, error } = useFetchDevices();
  const { branchDevices } = useFetchDevicesByBranchId(branchID);
  const [btnLoader, setBtnLoader] = useState(false);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedPanel, { open: openPanel, close: closePanel }] =
    useDisclosure(false);
  const [dataFilter, setDataFilter] = useState({
    data: [],
    fetching: false,
    failed: {},
  });
  // for filtering devices by branch
  const [teamDevice, setTeamDevice] = useState({
    branchLocation: branchID,
  });
  const { currentPage, setCurrentPage, paginatedData, totalPages } =
    useCustomPaginate(dataFilter && dataFilter.data, 10);
  const form = useForm({
    initialValues: {
      id: "",
      name: "",
      deptLocationID: branchID,
      deptID: "",
      description: "",
    },
    validate: {
      name: isNotEmpty("Compulsory field"),
      deptLocationID: isNotEmpty("Compulsory field"),
      deptID: isNotEmpty("Compulsory field"),
      description: isNotEmpty("Compulsory field"),
    },
  });

  // updates branchID
  const newBranchID = form.getInputProps("deptLocationID").value;
  useEffect(() => {
    if (teamDevice) {
      setBranchID(teamDevice.branchLocation);
    }
  }, [teamDevice]);

  useEffect(() => {
    if (!branchID) {
      setDataFilter((dataFilter) => ({
        ...dataFilter,
        data: devices,
      }));
    } else {
      setDataFilter((dataFilter) => ({
        ...dataFilter,
        data: branchDevices,
      }));
    }
  }, [branchDevices, branchID, devices, error, isLoading]);

  async function updateDevice(values, event) {
    event.preventDefault();
    setBtnLoader(true);
    const details = {
      deviceId: values.id ? values.id : "",
      name: values.name,
      branch_id: values.deptLocationID,
      department_id: values.deptID,
      description: values.description,
    };

    const res = await updateHardwareDevice(details);
    if (res.status) {
      successNotify("Device Edit Status", res.message);
      mutate("api/all-devices");
      close(0);
      form.reset();
    } else {
      failedNotify("Device Edit Status", res.response.data.message);
    }
    setBtnLoader(false);
  }

  async function handleEdit(deviceDetails) {
    open();
    form.setValues({
      id: deviceDetails.id,
      name: deviceDetails.name,
      deptLocationID: deviceDetails.branch_id,
      deptID: deviceDetails.department_id,
      description: deviceDetails.description ? deviceDetails.description : "",
    });
  }
  async function handleDeviceLock(deviceId) {
    const res = await lockDevice(deviceId);
    if (res.status) {
      successNotify("Lock status", res.message);
      mutate("api/all-devices");
    }
  }
  async function handleDeviceUnlock(deviceId) {
    const res = await unlockDevice(deviceId);
    if (res.status) {
      successNotify("Unlock status", res.message);
      mutate("api/all-devices");
    }
  }

  function handleConfigure() {
    openPanel();
  }
  return (
    <div className="w-full max-w-7xl relative h-full ">
      <div className="w-full mb-5 ">
        <HeaderBar
          title="hardwares"
          setData={setTeamDevice}
          user={user}
          show={true}
        />
      </div>
      <div className="w-full relative">
        {error && (
          <div className="w-full h-96 flex items-center justify-center">
            <p className="text-base text-black">oops!, kindly try again.</p>
          </div>
        )}

        {isLoading && (
          <Box className="w-full h-96 flex justify-center items-center">
            <Loader variant="oval" color="cyan" size="lg" />
          </Box>
        )}
        {paginatedData && paginatedData.length === 0 ? (
          <Box className="w-full h-80  flex items-center justify-center">
            <Text className="text-2xl text-black font-medium uppercase">
              no devices yet!
            </Text>
          </Box>
        ) : (
          <>
            {paginatedData &&
              paginatedData.map((device) => (
                <Paper
                  shadow="md"
                  p="sm"
                  key={device.id}
                  className="relative w-full max-w-xl bg-luchiPrimary rounded-md mb-2"
                >
                  <Group
                    spacing={2}
                    className="flex items-center absolute top-1 right-2 "
                  >
                    {device.name.toLowerCase() === "control panel" ? (
                      <ActionIcon
                        title="configure control panel"
                        variant="transparent"
                      >
                        <AiOutlineSetting
                          onClick={handleConfigure}
                          size="2.125rem"
                          color="white"
                        />
                      </ActionIcon>
                    ) : (
                      <>
                        <ActionIcon
                          onClick={() => handleEdit(device)}
                          title="edit"
                          className="bg-transparent hover:bg-transparent"
                        >
                          <AiFillEdit size="1.125rem" color="yellow" />
                        </ActionIcon>

                        {device && device.locked === 1 ? (
                          <ActionIcon title="unlock" variant="transparent">
                            <AiFillUnlock
                              onClick={() => handleDeviceUnlock(device.id)}
                              size="1.125rem"
                              color="white"
                            />
                          </ActionIcon>
                        ) : (
                          <ActionIcon
                            onClick={() => handleDeviceLock(device.id)}
                            title="lock"
                            variant="transparent"
                          >
                            <AiFillLock size="1.125rem" color="white" />
                          </ActionIcon>
                        )}
                      </>
                    )}
                  </Group>
                  <Box className="w-full">
                    <Text className="font-medium text-white text-sm uppercase">
                      {device.name}
                    </Text>
                    <Text
                      truncate
                      maw={400}
                      className="font-normal text-black text-xs normal-case mb-2 italic w-full"
                    >
                      {device.description}
                    </Text>
                    {device.name.toLowerCase() !== "control panel" && (
                      <>
                        {device && device.locked === 0 ? (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillUnlock title="unlock" size="1rem" />
                            }
                          >
                            unlocked
                          </Badge>
                        ) : (
                          <Badge
                            pl={0}
                            size="sm"
                            radius={0}
                            variant="light"
                            className="bg-transparent text-white font-medium"
                            leftSection={
                              <AiFillLock title="lock" size="1rem" />
                            }
                          >
                            locked
                          </Badge>
                        )}
                      </>
                    )}
                  </Box>
                </Paper>
              ))}
          </>
        )}
      </div>

      <EditHardwareModal
        opened={opened}
        close={close}
        loader={btnLoader}
        form={form}
        branchID={newBranchID}
        editMethod={updateDevice}
      />
      <Box className=" mt-14 "></Box>
      <Box className="w-full absolute bottom-0 ">
        <Pagination
          color="cyan"
          position="center"
          page={currentPage}
          onChange={setCurrentPage}
          total={totalPages ? totalPages : 0}
          size="lg"
        />
      </Box>
      <EditControlPanelDevice
        opened={openedPanel}
        close={closePanel}
        devices={filteredData}
      />
    </div>
  );
}
