import { Link, useLocation } from "react-router-dom";
import useSWR from "swr";
import ProductivityPieChart from "../../../components/charts/ProductivityPieChart";
import { getAttendanceTime } from "../../../services/functions";
import { getAttendanceByNumberFetcher } from "../../../services/requests";
import { BsCircleFill } from "react-icons/bs";
import { BiArrowToRight } from "react-icons/bi";
import { MdOutlineHourglassEmpty } from "react-icons/md";
import HeaderBar from "../../../components/headers/HeaderBar";
import {
  Box,
  Paper,
  Table,
  Title,
  Button,
  Group,
  Text,
  Select,
  Loader,
} from "@mantine/core";
import { useState } from "react";

export default function UtilityManagement() {
  const { pathname } = useLocation();
  const { data, isLoading, error } = useSWR(5, getAttendanceByNumberFetcher);
  const [searchValue, onSearchChange] = useState("");

  return (
    <main className="w-full max-w-7xl ">
      <HeaderBar
        title="utility manager"
        data={{}}
        setData={{}}
        user={{}}
        show={false}
      />
      <Box mt={40} className="flex space-x-[26px]">
        <Box className="w-full max-w-[536px] ">
          <Paper mb={26} withBorder p="sm">
            <Title
              mb={15}
              className=" text-lg text-black/[0.85] font-semibold font-poppins capitalize "
            >
              attendance
            </Title>
            {error && (
              <Box className="w-full h-full flex items-center justify-center space-x-2">
                <Loader variant="dots" color="red" size="sm" my={10} />
                <Text className="text-black text-sm">
                  Error! refresh to try again
                </Text>
              </Box>
            )}
            {isLoading && (
              <Box className="w-full h-full flex items-center justify-center space-x-2">
                <Loader variant="oval" color="cyan" size="sm" my={10} />
              </Box>
            )}

            {data && data.length !== 0 ? (
              <>
                <AttendanceTable data={data} />
                <Link
                  to={`${pathname}/time-attendance`}
                  className="w-full flex items-center justify-end mt-4"
                >
                  <Button
                    variant="white"
                    className="text-[#37A7AE] font-medium text-xs font-poppins capitalize "
                  >
                    see more
                  </Button>
                </Link>
              </>
            ) : (
              <>
                <Group spacing={5} position="center" className="w-full">
                  <MdOutlineHourglassEmpty size={24} />
                  <Text tt="uppercase" fz={14}>
                    no attendance yet!
                  </Text>
                </Group>
              </>
            )}
          </Paper>

          <Paper withBorder p="sm">
            <Title
              mb={15}
              ss
              className=" text-lg text-black/[0.85] font-semibold font-poppins capitalize "
            >
              leave & overtime
            </Title>
            {[1, 2].map((item, index) => (
              <Paper mb={16} key={index} className="w-full">
                <Group
                  position="apart"
                  className="w-full flex items-center justify-between py-3 bg-luchiPrimary text-white px-4 rounded-md"
                >
                  <Box className="flex flex-col justify-center h-full capitalize   ">
                    <Text className="text-base font-medium  ">
                      grace johnson
                    </Text>
                    <Text className="font-medium text-sm  ">sick leave</Text>
                  </Box>
                  <BiArrowToRight />
                </Group>
              </Paper>
            ))}
            <Link
              to={`${pathname}/time-attendance`}
              className="w-full flex items-center justify-end mt-4"
            >
              <Button
                variant="white"
                className="text-[#37A7AE] font-medium text-xs font-poppins capitalize "
              >
                see more
              </Button>
            </Link>
          </Paper>
        </Box>

        <Paper withBorder p="md" className="w-full flex-1 h-fit">
          <Group position="apart">
            <Text className="text-lg text-black/[0.85] font-semibold capitalize">
              productivity chart
            </Text>
            <Select
              placeholder="Pick one"
              onSearchChange={onSearchChange}
              searchValue={searchValue}
              nothingFound="No options"
              data={[
                "8th Aug - 12th Aug",
                "8th June - 12th Sep",
                "8th Sep - 12th Sep",
                "8th May - 12th Sep",
              ]}
              styles={(theme) => ({
                item: {
                  fontSize: 14,
                  paddingTop: 8,
                  paddingBottom: 8,
                  "&[data-selected]": {
                    "&, &:hover": {
                      backgroundColor: theme.colors.luchiPrimary[0],
                      color: theme.white,
                    },
                  },
                  "&[data-hovered]": {},
                },
                input: {
                  fontSize: 14,
                  borderWidth: 1,
                  "&:focus": {
                    borderColor: theme.colors.luchiPrimary[0],
                  },
                },
              })}
            />
          </Group>
          <Box className="w-full mb-3">
            <ProductivityPieChart />
          </Box>
          <Box>
            <Box className="flex space-x-[14px] mb-2 ">
              <BsCircleFill className="mt-1 text-[#FB9E9E] w-[16px] h-[16px]" />
              <Box className="flex-1 ">
                <Text className="capitalize font-poppins font-medium text-base text-black/[0.85] mb-0.5">
                  Operations Department
                </Text>
                <Text className="font-poppins font-normal text-sm text-black/[0.85]">
                  Operations department delivered a productivity rate of 28.2%.
                </Text>
              </Box>
            </Box>

            <Box className="flex space-x-[14px] mb-2 ">
              <BsCircleFill className="mt-1 text-[#77FF9D] w-[16px] h-[16px]" />
              <Box className="flex-1 ">
                <Text className="capitalize font-medium font-poppins text-base text-black/[0.85] mb-0.5">
                  Marketing Department
                </Text>
                <Text className="font-normal text-sm font-poppins text-black/[0.85]">
                  Marketting department delivered a productivity rate of 21.8%
                </Text>
              </Box>
            </Box>

            <Box className="flex space-x-[14px] mb-2 ">
              <BsCircleFill className="mt-1 text-[#F0D46E] w-[16px] h-[16px]" />
              <Box className="flex-1 ">
                <Text className="capitalize font-medium font-poppins text-base text-black/[0.85] mb-0.5">
                  Human Resources Department
                </Text>
                <Text className="font-normal text-sm font-poppins text-black/[0.85]">
                  Human Resources department delivered a productivity rate of
                  17.7% .
                </Text>
              </Box>
            </Box>

            <Box className="flex space-x-[14px] mb-2 ">
              <BsCircleFill className="mt-1 text-[#699CFF] w-[16px] h-[16px]" />
              <Box className="flex-1 ">
                <Text className="capitalize font-medium font-poppins text-base text-black/[0.85] mb-0.5">
                  Technology Department
                </Text>
                <Text className="font-normal text-sm font-poppins text-black/[0.85]">
                  Technology department delivered a productivity rate of 32.3% .
                </Text>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </main>
  );
}

function AttendanceTable({ data }) {
  const ths = (
    <tr>
      <th className="capitalize">sign in</th>
      <th className="capitalize">employee name</th>
      <th className="capitalize">sign out</th>
    </tr>
  );

  const rows =
    data &&
    data.map((element) => (
      <tr key={element.id}>
        <td> {getAttendanceTime(element.signin_at)}</td>
        <td>{element.employee ? element.employee.name : ""}</td>
        <td> {getAttendanceTime(element.signout_at)}</td>
      </tr>
    ));

  return (
    <Table striped>
      <thead>{ths}</thead>
      <tbody>{rows}</tbody>
    </Table>
  );
}
