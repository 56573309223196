import { Box, Group, Select, Text } from "@mantine/core";
import { useState } from "react";
import { forwardRef } from "react";
import useSearchEmployeeByBranchId from "../../hooks/useSearchEmployeeByBranchId";

export default function EmployeeEmailSelect({ branchLocationID, form }) {
  const [searchValue, onSearchChange] = useState("");
  const { employeesEmails } = useSearchEmployeeByBranchId(
    branchLocationID && branchLocationID
  );
  const SelectItem = forwardRef(({ label, ...others }, ref) => (
    <div ref={ref} {...others}>
      <Group>
        <Box>
          <Text size="sm">{label}</Text>
        </Box>
      </Group>
    </div>
  ));

  return (
    <Select
      label="Email"
      placeholder="Select email"
      searchable
      allowDeselect
      size="lg"
      clearable
      value={searchValue}
      onChange={onSearchChange}
      nothingFound="no emails"
      data={employeesEmails && employeesEmails}
      styles={(theme) => ({
        item: {
          fontSize: 14,
          paddingTop: 8,
          paddingBottom: 8,
          "&[data-selected]": {
            "&, &:hover": {
              backgroundColor: theme.colors.luchiPrimary[0],
              color: theme.white,
            },
          },

          "&[data-hovered]": {},
        },
        input: {
          fontSize: 14,
          borderWidth: 1,
          "&:focus": {
            borderColor: theme.colors.luchiPrimary[0],
          },
        },
        label: {
          paddingBottom: 4,
          fontSize: 16,
        },
      })}
      itemComponent={SelectItem}
      filter={(value, item) =>
        item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
        item.name.toLowerCase().includes(value.toLowerCase().trim())
      }
      {...form.getInputProps("email")}
    />
  );
}
