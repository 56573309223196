import useSWR from "swr";
import { getCurrentUserFetcher } from "../services/requests";

export function useCurrentUser() {
  const {
    data: user,
    isLoading: loading,
    error: userError,
  } = useSWR("api/current-user", getCurrentUserFetcher);
  return { user, loading, userError };
}
