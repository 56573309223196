import React, { useRef, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";
import { Link } from "react-router-dom";
import UtilityHeader from "../../../components/headers/UtilityHeader";
import { useFetchOrgById } from "../../../hooks/team/hooks";

export default function Settings() {
  const { data } = useFetchOrgById();
  const companyNameRef = useRef(null);
  const companyEmailRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    if (data && data.id) {
      companyNameRef.current.value = data.name;
      companyEmailRef.current.value = data.email;
    }
  }, [data]);
  return (
    <div className="w-[calc(100vw-330px)] pr-4 max-w-7xl pb-6  ">
      <div className="w-full mb-10 ">
        <UtilityHeader title={"settings"} subtitle={""} backLink={""} />
      </div>
      <div className="w-full flex flex-col items-center ">
        <div className="w-full max-w-[706px] mt-10 text-black flex justify-center flex-col items-center ">
          <form className="w-full max-w-md" action="" onSubmit={handleSubmit}>
            <div className="form-control w-full">
              <label className="label">
                <span className="label-text text-black font-medium ">
                  Name of Company
                </span>
              </label>
              <input
                type="text"
                name="companyName"
                placeholder="Company name"
                className="input input-bordered w-full bg-transparent border-black/25"
                ref={companyNameRef}
              />
            </div>

            <div className="form-control w-full my-4">
              <label className="label">
                <span className="label-text text-black font-medium ">
                  Company email address
                </span>
              </label>
              <input
                type="text"
                name="companyEmail"
                placeholder="Company email"
                className="input input-bordered w-full bg-transparent border-black/25"
                ref={companyEmailRef}
              />
            </div>
            <div className="items-center flex w-full justify-end  ">
              <button
                type="submit"
                className="bg-luchiPrimary rounded-md w-[94px] h-10 text-white hover:bg-luchiPrimary/60"
              >
                Save
              </button>
            </div>
          </form>

          <Link
            to={"/organization-setup/settings/set-role-permission"}
            type="button"
            className="w-full max-w-md p-3 border border-black/25 rounded-md mt-10 flex justify-start hover:bg-luchiPrimary text-black hover:text-white "
          >
            <p className="capitalize font-medium flex justify-between items-center w-full">
              <span>permissions</span>
              <span>
                <IoIosArrowForward className="w-4 h-4 " />
              </span>
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}
