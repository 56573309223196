import useSWR from "swr";
import { getAllDeptFetcher } from "../services/requests";
import { useEffect, useState } from "react";
import { useCurrentUser } from "./useCurrentUser";

export default function useFetchDepartments() {
  const { user } = useCurrentUser();

  const [department, setDepartment] = useState([]);
  const {
    data,
    isLoading,
    error: departmentError,
  } = useSWR(
    "api/all-dept",
    user?.user_type === "super_admin" && getAllDeptFetcher
  );

  useEffect(() => {
    if (data && data.length) {
      const dept = data.map((datum) => {
        const deptInfo = {
          value: datum.id,
          label: datum.name,
          description: datum.description,
          branchid: datum.branch_id,
        };
        return deptInfo;
      });
      setDepartment([...dept]);
    }
    return;
  }, [data]);

  return [department, isLoading, departmentError, setDepartment];
}

// for hardware device creation
export function useDeptsNameForHardware() {
  const [hardwareName, setHardwareName] = useState([]);
  const { data } = useSWR("api/deptNames", getAllDeptFetcher);

  useEffect(() => {
    if (data && data.length) {
      const dept = data.map((datum) => {
        const deptInfo = {
          value: datum.name,
          label: datum.name,
        };
        return deptInfo;
      });
      setHardwareName([...dept]);
    }
    return;
  }, [data]);

  return [hardwareName];
}
