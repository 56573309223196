import React from "react";
import MonthlyPieChart from "../../../charts/MonthlyPieChart";
import { Paper, Title, Text, Box } from "@mantine/core";

const StandardUserMonthlyLog = () => {
  return (
    <Paper
      withBorder
      shadow="md"
      p="md"
      className=" bg-white flex-1 h-[325px] "
    >
      <Box className="mb-6">
        <Title className="text-lg font-bold font-poppins text-black ">
          Monthly Log
        </Title>
        <Text className="font-normal text-sm font-poppins text-gray-600">
          Record of employees that use each technology.
        </Text>
      </Box>
      <Box className="">
        <MonthlyPieChart />
      </Box>
    </Paper>
  );
};

export default StandardUserMonthlyLog;
