import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AttendanceMenuModal from "../../../components/modal/AttendanceMenuModal";
import useSWR from "swr";
import { getAttendanceByNumberFetcher } from "../../../services/requests";
import {
  getDayMonthYear,
  getDayMonthYearQuery,
  getMonthYearNumber,
} from "../../../services/functions";
import { FaAngleLeft, FaSpinner } from "react-icons/fa";
import { BiErrorAlt } from "react-icons/bi";

export default function SingleAttendance() {
  const { date } = useParams();
  const { data, isLoading, error } = useSWR(20, getAttendanceByNumberFetcher);
  const [displayAttendance, setDisplayAttendance] = useState([]);

  useEffect(() => {
    if (data) {
      const attendance = data.filter(
        (item) =>
          getMonthYearNumber(item.signin_at) ===
          `${new Date(`${date.split("-")[0]} 1, 2022`).getMonth() + 1}-${
            date.split("-")[1]
          }`
      );
      setDisplayAttendance(attendance);
    }
  }, [data, date]);

  return (
    <main className="relative w-full max-w-7xl ">
      <div className=" mt-5 mb-5 w-full ">
        <div className="w-full flex justify-between items-center ">
          <h1 className="capitalize font-bold text-black text-2xl leading-7  ">
            attendance
          </h1>
          {/* <div className="flex justify-end">use branchLocation component for filtering if needed</div> */}
        </div>
        <div className="flex space-x-2 items-center ">
          <div className=" text-black">
            <p className="flex items-center font-semibold text-lg capitalize ">
              {date.split("-").join(", ")}
            </p>
          </div>
          <Link
            to={"/utility-management/time-attendance/attendances"}
            className="self-end btn btn-active btn-link hover:bg-transparent border-none text-[#07939BCC] text-xs capitalize"
          >
            <FaAngleLeft className=" w-5 h-5 " />
          </Link>
        </div>
      </div>
      <div className=" h-full overflow-x-auto w-full max-w-3xl">
        {error && (
          <div className="w-full h-full flex items-center justify-center space-x-2">
            <BiErrorAlt className="w-6 h-6 text-red-800" />
            <p className=" text-black text-sm">Error! refresh to try again</p>
          </div>
        )}
        {isLoading && (
          <div className="w-full h-full flex items-center justify-center space-x-2 overflow-hidden">
            <FaSpinner className="animate-spin w-6 h-6 text-black" />
            <p className="capitalize text-black text-base">loading</p>
          </div>
        )}
        {!isLoading &&
          !error &&
          displayAttendance &&
          displayAttendance.map((item) => (
            <div
              key={item.id}
              className="flex bg-white rounded-[10px] py-3 px-6 mb-5 "
            >
              <Link
                to={`/utility-management/time-attendance/attendances/${date}/${getDayMonthYearQuery(
                  item.signin_at
                )}`}
                className="flex-1 cursor-pointer "
              >
                <span className="font-medium text-base capitalize text-black/[0.85] space-x-1">
                  <span>{getDayMonthYear(item.signin_at)}</span>
                </span>
                <p className="font-normal text-sm text-black/60">
                  This contains the time and attendance records for the month.
                </p>
              </Link>
              <div className=" ">
                <AttendanceMenuModal />
              </div>
            </div>
          ))}
      </div>
    </main>
  );
}
