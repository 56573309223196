import React from "react";
import MonthlyPieChart from "../../../charts/MonthlyPieChart";
import { Box, Paper, Text, Title } from "@mantine/core";
import BranchLocation from "../../../singleSelect/BranchLocation";

const SuperAdminMonthlyLog = () => {
  return (
    <Paper
      withBorder
      p="md"
      shadow="md"
      className="bg-white flex-1 hidden md:block"
    >
      <Box mb={26} className="flex justify-between items-center">
        <Box>
          <Title className="text-lg font-bold font-poppins text-black ">
            Monthly Log
          </Title>
          <Text className="font-normal text-sm font-poppins text-gray-600">
            Record of employees that use each technology.
          </Text>
        </Box>
        <Box className="w-[200px]">
          <BranchLocation setMethod={{}} />
        </Box>
      </Box>
      <Box className="">
        <MonthlyPieChart />
      </Box>
    </Paper>
  );
};

export default SuperAdminMonthlyLog;
