import { Chart } from "react-google-charts";

export const data = [
  ["", "Fingerprint", "RFID Card", "Mobile App", "PIN"],
  ["New York", 1000, 400, 200, 300],
  ["Abuja", 1170, 460, 250, 600],
  ["Canada", 660, 1120, 300, 200],
  ["Lagos", 1030, 540, 350, 280],
];

export const options = {
  bar: { groupWidth: "90%" },
};

export default function SuperAdminBarChart() {
  return (
    <Chart
      chartType="Bar"
      width="100%"
      height="250px"
      data={data}
      options={options}
    />
  );
}
