import { Chart } from "react-google-charts";

export const data = [
  ["Reviews", "percentage"],
  ["Fingerprint", 600],
  ["RFID Card", 400],
  ["Mobile App", 400],
  ["PIN", 400],
];

export const options = {
  title: "",
  pieHole: 0.4,
  is3D: false,
  // titleTextStyle: {
  //   color: "#686868",
  //   fontName: "Poppins",
  //   fontSize: 16,
  //   bold: false,
  //   italic: false,
  // },

  chartArea: {
    height: "100%",
    width: "100%",
  },
};

export default function MonthlyPieChart() {
  return (
    <Chart
      chartType="PieChart"
      data={data}
      options={options}
      width="100%"
      height="200px"
    />
  );
}
