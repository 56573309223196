import useSWR from "swr";
import {
  getBranchDeptEmpsFetcher,
  getCapacityUsersFetcher,
  getCurrentCapacityFetcher,
  getDevicesByBranchIdFetcher,
  getDevicesFetcher,
  getEmployeeByIdFetcher,
  getEmployeesFetcher,
  getOrganizationFetcherById,
} from "../../services/requests";
import { useMemo } from "react";
import { useCurrentUser } from "../useCurrentUser";

export function useFetchEmployeesData() {
  const {
    data: employees,
    isLoading: employeeLoading,
    error: employeeError,
  } = useSWR("api/all-employees", getEmployeesFetcher);

  return {
    employees,
    employeeLoading,
    employeeError,
  };
}
export function useFetchEmployeeByID(id) {
  const {
    data: employee,
    isLoading: employeeLoading,
    error: employeeError,
  } = useSWR(id, getEmployeeByIdFetcher);
  return {
    employee,
    employeeLoading,
    employeeError,
  };
}

export function useFetchDevices() {
  const {
    data: devices,
    isLoading,
    error,
  } = useSWR("api/all-devices", getDevicesFetcher);

  const filteredData = useMemo(() => {
    const pickedDevices = [];
    devices?.forEach((item) => {
      if (item.name.toLowerCase() !== "control panel") {
        const val = {
          value: item.id,
          label: item.name,
          configured: item.configured,
        };
        pickedDevices.push(val);
      }
    });
    if (pickedDevices?.length) return pickedDevices;
    else return [];
  }, [devices]);

  return {
    devices,
    filteredData,
    isLoading,
    error,
  };
}

export function useFetchDevicesByBranchId(branchID) {
  const {
    data: branchDevices,
    isLoading: loading,
    error: fail,
  } = useSWR(branchID ? branchID : null, getDevicesByBranchIdFetcher);

  return {
    branchDevices,
    loading,
    fail,
  };
}

export function useFetchOrgById() {
  const { user } = useCurrentUser();

  const { data, isLoading, error } = useSWR(
    user ? user?.organization.id : null,
    getOrganizationFetcherById
  );
  return {
    data,
    isLoading,
    error,
  };
}

export function useFetchCapacity() {
  const { data: capacityUsers, error: capacityUsersError } = useSWR(
    "api/capicity-users",
    getCapacityUsersFetcher
  );
  const { data: currentCapacity, error: currentError } = useSWR(
    "api/current-capacity",
    getCurrentCapacityFetcher
  );

  return {
    capacityUsers,
    capacityUsersError,
    currentCapacity,
    currentError,
  };
}

export function useFetchBranchDetails(branchID) {
  const {
    data: teamsInfo,
    isLoading,
    error,
  } = useSWR(branchID ? branchID : null, getBranchDeptEmpsFetcher);
  return {
    teamsInfo,
    isLoading,
    error,
  };
}
